/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
import React, {useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import {AssignationRequestsSettings, CategoryBreadcrumbText, Loader, Media, Modal, Paginator, View } from '@crosslud/components';
import { UserRequestAPI } from '@crosslud/apis/UserRequestAPI';
import StateMutation from '@crosslud/helpers/StateMutation';
import useCustomSearchParams from '@crosslud/hooks/useCustomSearchParams';
import useActiveLink from '@crosslud/hooks/useActiveLink';

const AdminAssignationRequests = () => {
  // SET ACTIVELINK
  useActiveLink('adminLayout', 'requests');

  const [searchParams] = useCustomSearchParams();
  const [activity, setActivity] = useState(true);
  const [userRequests, setUserRequests] = useState();
  const [totalItems, setTotalItems] = useState();

  const [selectedSetting, setSelectedSetting] = useState();
  const [settingsModalVisible, setSettingsModalVisible] = useState();
  
  const fetchProtectUserRequests = async (page = 1) => {
    setActivity(true);

    const response = await UserRequestAPI.getAll([], page > 1 ? page : 1);

    if (response?.status) {
      setTotalItems(response?.totalItems);
      setUserRequests(response?.payload);
    }

    setActivity(false);
  };

  // Load users requests
  useEffect(() => {
    searchParams && fetchProtectUserRequests(searchParams?.get('page') ?? 1);
  }, [searchParams]);

  return (
    <div className='container'>
      <div className='row'>
        <div className='col'>
          <section className='bg-black-1'>
            {activity ? <Loader centerScreen={false} /> :
              <div className="ht-100p">
                <div className='row'>
                  <div className='col-12 mg-b-10'>
                    <div className="br-pagetitle pd-x-15 bg-black-1">
                      <i className="icon ion-ios-search"></i>
                      <div>
                        <h4>Requests</h4>
                        {totalItems && <p className="mg-b-0">{`${totalItems} request(s)`}</p>}
                      </div>
                    </div>
                  </div>
                </div>
                <div className='row'>
                  <div className='col-12'>
                    <div className='bg-black-1 pd-20'>
                      <div className="row">
                        <div className="col-12">
                        {userRequests && userRequests?.length > 0 ? 
                          <>
                            <table className="table table-responsive mg-b-0 tx-12">
                              <thead>
                                <tr className="tx-10">
                                  <th className="wd-5p pd-y-5">&nbsp;</th>
                                  <th className="wd-20p pd-y-5 tx-center">User</th>
                                  <th className="wd-20p pd-y-5">Category</th>
                                  <th className="wd-20p pd-y-5">Assign</th>
                                  <th className="wd-30p pd-y-5 tx-right">Barcode</th>
                                  <th className="pd-y-5 tx-center">Actions</th>
                                </tr>
                              </thead>
                              <tbody>
                                {userRequests.map((userRequest) => {
                                  return (
                                    <tr key={userRequest.id}>
                                      <td className="valign-middle tx-left">
                                        <span className="tx-12"><span className="square-10 bg-danger mg-r-5 rounded-circle"></span></span>
                                      </td>
                                      <td className="pd-l-20">
                                        <div className="tx-center">
                                          <View className="d-flex align-items-center justify-content-center mg-b-5">
                                            <Media mediaUri={userRequest?.user?.avatar?.contentUrl} avatar={true} size={50} />
                                          </View>
                                          <span className="tx-11 d-block">
                                            <Link to={`/administration/assignation/requests/${userRequest?.id}/settings`} className="tx-white">{userRequest?.user?.nickname ? userRequest?.user?.nickname : `${userRequest?.user?.firstname} ${userRequest?.user?.lastname}`}</Link>
                                          </span>
                                        </div>
                                      </td>
                                      <td className='valign-middle'>
                                        <CategoryBreadcrumbText category={userRequest?.category} />
                                      </td>
                                      <td className='valign-middle'>
                                        {userRequest.assignedUsers.map((user, index)=> {
                                          return <span key={user.uuid}>{index > 0 && ", "}{user.firstnameReference}</span>;
                                        })}
                                      </td>
                                      <td className="valign-middle tx-right">
                                        <b>{userRequest?.barcode}</b><br />
                                        <span className="tx-success">{userRequest?.files?.length} file(s)</span>
                                      </td>
                                      <td className="valign-middle tx-center">
                                        <span className='btn tx-gray-600 tx-24' 
                                          onClick={() => {
                                            setSelectedSetting(userRequest?.id);
                                            setSettingsModalVisible(true);
                                          }}>
                                          <i className="icon ion-android-more-horizontal" />
                                        </span>
                                      </td>
                                    </tr>
                                  );
                                })}
                              </tbody>
                            </table>
                            {totalItems && <Paginator totalItems={totalItems}/>}

                            {/* Settings modal */}
                            <Modal 
                              show={settingsModalVisible} 
                              fullscreen={true} 
                              centered={false} 
                              handleClose={() => setSettingsModalVisible(false)}
                              contentClassName={'col col-lg-8 offset-lg-2'}
                              closeButton={true}
                            >
                              <AssignationRequestsSettings id={selectedSetting} onFinish={(payload) => {
                                setSettingsModalVisible(false);
                                StateMutation.update(payload, setUserRequests, 'id');
                              }} />
                            </Modal>
                          </>
                        :
                          <div className="bd bd-white-1 ht-100 d-flex align-items-center justify-content-center">
                            <h6 className="tx-uppercase mg-b-0 tx-roboto tx-normal tx-spacing-2">No requests found</h6>
                          </div>
                        }
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            }
          </section>
        </div>
      </div>
    </div>
  )
}

export default AdminAssignationRequests;