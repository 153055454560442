/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
import React, {useEffect, useState} from 'react';
import {Link, useParams} from 'react-router-dom';
import {loaderStart, loaderEnd} from '@crosslud/redux/Loader/loader-actions';
import { MovableContainer, MovableContext, Loader, ProtectedScreen, CategoryBreadcrumbText } from '@crosslud/components';
import { useDispatch } from 'react-redux';
import { Controller, useForm } from 'react-hook-form';
import useActivity from '@crosslud/hooks/useActivity';
import Alert from '@crosslud/helpers/Alert';
import useActiveLink from '@crosslud/hooks/useActiveLink';
import { CommonAPI } from '@crosslud/apis/CommonAPI';

const AdminFormsFieldsSettings = () => {
  // SET ACTIVELINK
  useActiveLink('adminLayout', 'forms');

  const dispatch = useDispatch();
  const activityHook = useActivity();

  // PARAMS
  const {id} = useParams();

  // FORM
  const {
    control,
    handleSubmit,
    setValue,
  } = useForm();

  const [categoryXContainer, setCategoryXContainer] = useState();

  // ACTION & API
  const fetchCategoryProtectedXContainer = async (x_container_id) => {
    var activityRef = activityHook.start();

    const response = await CommonAPI.getProtectedXContainer(x_container_id);

    if (response?.status) {
      setCategoryXContainer(response?.payload);
    }

    activityHook.stop(activityRef);
  };

  const fetchCategoryXContainersProtectedFields = async (x_container_id) => {
    var activityRef = activityHook.start();

    const response = await CommonAPI.getProtectedXContainersFields(x_container_id);

    if (response?.status) {
      var sortables = [];

      // Transform response to sortable {id, droppable, data}
      response?.payload?.map((xField) => {
        sortables.push({
          id: parseInt(xField.id), 
          droppable: xField.scope, 
          data: xField
        })
      });

      setValue('formFields', sortables);
    }

    activityHook.stop(activityRef);
  };

  // Save settings
  const onSubmit = async (formData) => {
    dispatch(loaderStart());

    var data = [];

    formData.formFields.map((item, index) => {
      data.push({
        id: item.data.id,
        label: item.data.label,
        syncField: item.data.syncField,
        scope: item.droppable,
        position: index
      });
    });

    const response = await CommonAPI.bachUpdateProtectedContainerXFields(data);

    if (response?.status) {
      Alert.notify(
        'Well done! ',
        'Vos modifications ont été enregistrées.',
        'success',
      );
    }

    dispatch(loaderEnd());
  }

  // Load categories
  useEffect(() => {
    fetchCategoryProtectedXContainer(id);
    fetchCategoryXContainersProtectedFields(id);
  }, []);

  return (
    <div className='container'>
      <div className='row'>
        <div className='col'>
          <section className='bg-black-1'>
            {activityHook.isLoading ? <Loader centerScreen={false} /> :
            <ProtectedScreen deniedWhen={!categoryXContainer}>
              <div className="ht-100p">
                <div className='row'>
                  <div className='col-12 mg-b-10'>
                    <div className="br-pagetitle pd-x-15 bg-black-1">
                      <i className="icon ion-ios-search"></i>
                      <div>
                        <h4>Forms</h4>
                        <p className="mg-b-0">    
                          <Link className={`pd-y-12 wd-100p-force active`} to={'/administration/forms'}>
                            <CategoryBreadcrumbText category={categoryXContainer?.category}/>
                          </Link>
                          {' - '}
                          <Link 
                            className={`pd-y-12 wd-100p-force active`} 
                            to={`/administration/forms/${categoryXContainer?.category.id}/container/settings`}>
                            {categoryXContainer?.container?.translatedName}
                          </Link>
                        </p>
                      </div>
                      <div className="btn-group pos-absolute r-30">
                        <button type="button" className="btn btn-outline-light rounded-0" onClick={handleSubmit(onSubmit)}><i className="fa fa-save"></i></button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='row'>
                  <div className='col-12'>
                    <div className='bg-black-1 pd-20'>
                      <div className='row'>
                        <div className="col-12">
                          <Controller
                            control={control}
                            name="formFields"
                            render={({ field }) => (
                              <MovableContainer
                                {...field}
                                render={({ attributes }) => (
                                  <div className="row">
                                    <div className="col-6">
                                      <div className="row">
                                        <div className="col-12 mg-b-10"><h4>Cemetery</h4></div>
                                      </div>
                                      <MovableContext
                                        {...attributes}
                                        droppable="cemetery"
                                        component={(item, onChange) =>
                                          <MovableItem item={item} onChange={onChange}/>
                                        }/>
                                    </div>
                                    <div className="col-6">
                                      <div className="row">
                                        <div className="col-12 mg-b-10"><h4>Mandatory</h4></div>
                                      </div>
                                      <MovableContext
                                        {...attributes}
                                        droppable="main"
                                        component={(item, onChange) =>
                                          <MovableItem item={item} onChange={onChange}/>
                                        }/>
                                      <div className="row">
                                        <div className="col-12 mg-b-10"><h4>Optional</h4></div>
                                      </div>
                                      <MovableContext
                                        {...attributes}
                                        droppable="optional"
                                        component={(item, onChange) =>
                                          <MovableItem item={item} onChange={onChange}/>
                                        }/>
                                    </div>
                                  </div>
                                )}
                              />
                            )}
                          />  
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </ProtectedScreen>
            }
          </section>
        </div>
      </div>
    </div>
  )
}

const MovableItem = ({item, onChange}) => {
  var syncGroup = [];

  syncGroup.push({label: 'None', value: 0});

  for (let i = 1; i <= 10; i++) {
    syncGroup.push({label: 'Group' + i, value: i});
  }
  
  const handleFormChange = ({name, value}) => {
    item.data[name] = value; // Update xFied property. Ex xField.syncField = value

    // Container callback for updated element
    onChange(item);
  }

  return (
    <div className="card bd-0 mb-2">
      <div className="card-header bg-primary text-white">
        <label className="mg-y-0">{item.data?.formField?.reference}</label>
        <input
          className="float-right"
          type="text"
          name="label"
          value={item.data?.label ?? ''}
          onChange={(e) => handleFormChange({name: e.target.name, value: e.target.value})}
        />
      </div>
      <div className="container">
        <div onChange={(e) => handleFormChange({name: 'syncField', value: parseInt(e.target.value)})} className="row">
            <div className='col-12 mg-b-5 tx-9'>
              Synchronisation
            </div>
            {syncGroup.map((syncType) => (
              <div key={syncType.value} className='col-3 text-center'>
                <label className='tx-11 mg-r-5'>{syncType.label}</label>
                <input
                  type="radio"
                  name={`syncField_${item.data?.id}`}
                  value={syncType.value}
                  defaultChecked={item.data?.syncField === syncType.value}
                />
              </div>
            ))}
        </div>
      </div>
    </div>
  );
}

export default AdminFormsFieldsSettings;