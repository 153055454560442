// import {Link, resolvePath, useLocation} from "react-router-dom";
import useCustomNavigate from "@crosslud/hooks/useCustomNavigate";

const Link = (props) => {
  const navigate = useCustomNavigate();
  
  return (
    <span className="cursor-pointer">
      <span {...props} onClick={(e) => navigate(props.to)}>
        {props.children}
      </span>
    </span>
  );
};

export default Link;
