/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import {Loader, Media, NiceDropdown, ProtectedScreen} from '@crosslud/components';
import { Route, Routes, useLocation, useParams } from 'react-router';
import { NotFound, Collections, CollectionItems, CollectionScans } from '.';
import { useSelector } from 'react-redux';
import { UserAPI } from '@crosslud/apis/UserAPI';
import { useTranslation } from 'react-i18next';
import useActivity from '@crosslud/hooks/useActivity';
import useCheckRole from '@crosslud/hooks/useCheckRole';
import useCollectionAction from '@crosslud/hooks/useCollectionAction';

const CollectionLayout = () => {
  const {t} = useTranslation();
  const {collectorUuid} = useParams();
  const location = useLocation();

  // State
  const [collector, setCollector] = useState();
  const [pathnamePrefix, setPathnamePrefix] = useState();
  const [menus, setMenus] = useState();
  
  // Selector
  const auth = useSelector(state => {
    return state.auth;
  });

  // Hook
  const activityHook = useActivity(true);
  const checkRole = useCheckRole();
  const collectionAction = useCollectionAction();

  const isCurrentUser = collectionAction.isCurrentUser(collectorUuid ?? auth.user.uuid);

  // Action + Api
  
  // Fetch collector
  const fetchCollector = async uuid => {
    var activityRef = activityHook.start();

    const response = await UserAPI.get(uuid);

    if (response?.status) {
      setCollector(response?.payload);
    }

    activityHook.stop(activityRef);
  };

  const buildMenus = (pathnamePrefix, isCurrentUser) => {
    const pathnames = [
      {identifier: 'collections', pathname: `${pathnamePrefix}/collections`, label: t('fe.collection.collections.menu'), enable: true},
      {identifier: 'collections-scans', pathname: `${pathnamePrefix}/collections/scans`, label: t('fe.collection.scans.menu'), enable: isCurrentUser}
    ];

    setMenus(pathnames);
  }

  // Effect

  // Get Collector
  useEffect(() => {
    if (collectorUuid || auth?.user?.uuid) {
      fetchCollector(collectorUuid ?? auth.user.uuid);
    }
  }, [auth, collectorUuid]);

  // Set PathnamePrefix, isCurrentUser
  useEffect(() => {
    setPathnamePrefix(isCurrentUser ? '' : '/collectors/' + collectorUuid);
  }, [isCurrentUser]);

  // Build Menus
  useEffect(() => {
    if (pathnamePrefix !== undefined && isCurrentUser !== undefined) {
      buildMenus(pathnamePrefix, isCurrentUser);
    }
  }, [isCurrentUser, pathnamePrefix])

  return (
    activityHook.isLoading ? <Loader centerScreen={false} /> :
    <ProtectedScreen deniedWhen={!collector}>
      <section className="mg-t-80">
        <div className="row">
          <div className="col-12 col-lg-4">
            <div className="card widget-7 bd bd-gray-400 ht-auto">
              <div className="tx-center">
                <div className='d-flex align-items-center justify-content-center'>
                  <Media mediaUri={collector?.avatar?.contentUrl} avatar={true} size={150} />
                </div>
                <h4 className="tx-normal tx-white tx-roboto mg-t-20 mg-b-2">{collector?.nickname}</h4>
                <p className="mg-b-20">{collector?.description}</p>
                {/*<p className="mg-b-25 tx-20">
                  <span className="tx-primary mg-r-5"><i className="fab fa-facebook-official"></i></span>
                  <span className="tx-info mg-r-5"><i className="fab fa-twitter"></i></span>
                  <span className="tx-danger"><i className="fab fa-pinterest"></i></span>
                </p>*/}
                {/*
                <p className="mg-b-0">
                  <span className="btn btn-secondary pd-x-50">{t('fe.collection.collector.follow.button')}</span>
                </p>
              */}
              </div>
            </div>
            <div className="mg-y-10">
              {menus && <NiceDropdown data={menus} scope={'collectionLayout'}/>}
            </div>
          </div>
          <div className="col-12 col-lg-8">
            <ProtectedScreen 
              deniedWhen={!isCurrentUser && !checkRole.isGranted('ROLE_SUPER_ADMIN')}
              deniedComponent={<PrivateCollection />}
            >
              <Routes location={location?.state?.backgroundLocation ?? location}>
                <Route index path="/" element={<Collections collector={collector} isCurrentUser={isCurrentUser}/>} pathnamePrefix={pathnamePrefix} />
                <Route path=":id/items" element={<CollectionItems collector={collector} isCurrentUser={isCurrentUser} pathnamePrefix={pathnamePrefix} titles={false} />} />
                <Route path=":id/titles" element={<CollectionItems collector={collector} isCurrentUser={isCurrentUser} pathnamePrefix={pathnamePrefix} titles={true} />} />
                <Route index path="scans" element={<CollectionScans />} />
                {/* Collector collections */}
                <Route path="collections" element={<Collections collector={collector} isCurrentUser={isCurrentUser} pathnamePrefix={pathnamePrefix} />} />
                <Route path="collections/:id/items" element={<CollectionItems collector={collector} isCurrentUser={isCurrentUser} pathnamePrefix={pathnamePrefix} titles={false} />} />
                <Route path="collections/:id/titles" element={<CollectionItems collector={collector} isCurrentUser={isCurrentUser} pathnamePrefix={pathnamePrefix} titles={true} />} />
                <Route path="*" element={<NotFound />} />
              </Routes>
            </ProtectedScreen>
          </div>
        </div>
      </section>
    </ProtectedScreen>
  );
}

export default CollectionLayout;

const PrivateCollection = () => {
  const {t} = useTranslation();

  return (
    <div className="pd-y-65 bd d-flex align-items-center justify-content-center">
      <div className="wd-lg-70p wd-xl-70p wd-80p">
        <h5 className="tx-xs-24 tx-normal tx-info mg-b-30 lh-5">{t('Collections privées')}</h5>
        <p className="tx-16 mg-b-30">{t('Le collectionneur ne souhaite pas partager les informations concernant sa collection')}</p>
      </div>
    </div>
  )
}