import React from 'react';
import { useNavigate } from 'react-router-dom';
import Text from '@crosslud/helpers/Text';
import FormInputTree from '../FormInputTree/FormInputTree';

const CategoryTreeLink = ({data, to}) => {
  const navigate = useNavigate();

  return (
    <FormInputTree
      name={'categorie'}
      data={data}
      idSelector={'id'}
      labelSelector={'translatedName'}
      submitOnSelect={true}
      onChange={(category) => {
        navigate(Text.sprintf(to, category))
      }}
    />
  )
}

export default CategoryTreeLink;