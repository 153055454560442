/* eslint-disable react-hooks/exhaustive-deps */
import {Button, CText, CheckWrapper, GridList, IconText, ItemCard, Loader, Media, Modal} from '..';
import { useEffect, useState } from 'react';
import { ItemAPI } from '@crosslud/apis/ItemAPI';
import useActivity from '@crosslud/hooks/useActivity';
import useItemChecker from '@crosslud/hooks/useItemChecker';
import { ScanAPI } from '@crosslud/apis/ScanAPI';
import Text from '@crosslud/helpers/Text';
import { useTranslation } from 'react-i18next';

const ScanCard = ({data, imageHeight}) => {
  const { t } = useTranslation();

  const IN_COLLECTION = 'IN_COLLECTION';
  const VERIFY_COLLECTION = 'VERIFY_COLLECTION';
  const NOT_IN_COLLECTION = 'NOT_IN_COLLECTION';
  const MISSING_BUILDING = 'MISSING_BUILDING';
  const MISSING_CREATED = 'MISSING_CREATED';
  const MISSING_WITH_STATUS = 'MISSING_WITH_STATUS';

  const [item, setItem] = useState(data);
  const [modal, setModal] = useState(false);
  const [action, setAction] = useState();

  useEffect(() => {
    setAction(() => {
      if (item.accepted === true && item.itemCollection) {
        return IN_COLLECTION;
      }

      if (item.accepted !== true && item.itemCollection) {
        return VERIFY_COLLECTION;
      }

      if (item.chosenItem && !item.itemCollection) {
        return NOT_IN_COLLECTION;
      }

      if (item.accepted !== true && !item.itemCollection) {
        if (item.chosenItem && item?.status) {
          return MISSING_CREATED;
        }

        if (!item.chosenItem && item?.status) {
          return MISSING_WITH_STATUS;
        }

        return MISSING_BUILDING;
      }

      return null;
    })

  }, [item]);

  return (
    <>
      <div className='cursor-pointer'>
        {action === IN_COLLECTION && (
          <InfoIcon 
            mainIcon={'fa fa-box-open'}
            mainIconColor={'white'}
            subIcon={'fa fa-check'}
            subIconColor={'lightgreen'}
            onPress={() => setModal(true)}
          />
        )}

        {action === VERIFY_COLLECTION && (
          <InfoIcon 
            mainIcon={'fa fa-box-open'}
            mainIconColor={'white'}
            subIcon={'fa fa-exclamation'}
            subIconColor={'orange'}
            onPress={() => setModal(true)}
          />
        )}

        {action === NOT_IN_COLLECTION && (
          <InfoIcon 
            mainIcon={'fa fa-box-open'}
            mainIconColor={'white'}
            subIcon={'fa fa-times'}
            subIconColor={'red'}
            onPress={() => setModal(true)}
          />
        )}

        {action === MISSING_BUILDING && (
          <InfoIcon 
            mainIcon={'fa fa-hammer'}
            mainIconColor={'white'}
            subIcon={'fa fa-clock'}
            subIconColor={'cyan'}
            onPress={() => setModal(true)}
          />
        )}

        {action === MISSING_CREATED && (
          <InfoIcon 
            mainIcon={'fa fa-box-open'}
            mainIconColor={'white'}
            subIcon={'fa fa-clock'}
            subIconColor={'cyan'}
            onPress={() => setModal(true)}
          />
        )}

        {action === MISSING_WITH_STATUS && (
          <InfoIcon 
            mainIcon={'fa fa-hammer'}
            mainIconColor={'white'}
            subIcon={'fa fa-question'}
            subIconColor={'orange'}
            onPress={() => setModal(true)}
          />
        )}
        
        <figure className="ht-150">
          <Media 
            mediaUri={item?.chosenItem?.cover?.contentUrl}
            mode="cover" 
            alt={Text.truncate((item?.chosenItem?.fullName ?? ''), 60)}
          />
        </figure>
        <CText className='tx-12'>
          {item?.barcode}
        </CText>
      </div>

      <Modal
        show={modal} 
        handleClose={() => setModal(false)}
        fullscreen={true}
        centered={false}
        contentClassName={'col'}
        closeButton={true}
      >
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12">
              {action === IN_COLLECTION && (
                <div style={{
                  flex: 1,
                  justifyContent: 'center',
                  alignSelf: 'center'
                }}>
                  <CText style={{color: 'lightgreen'}}>
                    {t('main.item.in.your.collection')}
                  </CText>
                </div>
              )}

              {action === VERIFY_COLLECTION && (
                <VerifyCollection
                  data={item}
                  onFinish={(updatedItem) => {
                    updatedItem && setItem(updatedItem);

                    setModal(false);
                  }}
                />
              )}

              {action === NOT_IN_COLLECTION && (
                <div style={{
                  flex: 1,
                  justifyContent: 'center',
                  alignSelf: 'center'
                }}>
                  <CText style={{color: 'red'}}>
                    {t('fe.collection.scans.item.not.in.your.collection')}
                  </CText>
                </div>
              )}

              {action === MISSING_BUILDING && (
                <div style={{
                  flex: 1,
                  justifyContent: 'center',
                  alignSelf: 'center'
                }}>
                  <CText style={{color: 'cyan'}}>
                    {t('fe.collection.scans.item.creating.in.progress')}
                  </CText>
                </div>
              )}

              {action === MISSING_CREATED && (
                <div style={{
                  flex: 1,
                  justifyContent: 'center',
                  alignSelf: 'center'
                }}>
                  <CText style={{color: 'cyan'}}>
                    {t('fe.collection.scans.item.created.waiting')}
                  </CText>
                </div>
              )}

              {action === MISSING_WITH_STATUS && (
                <div style={{
                  flex: 1,
                  justifyContent: 'center',
                  alignSelf: 'center'
                }}>
                  <CText style={{color: 'orange'}}>
                  {t('fe.collection.scans.item.with.status')} {item?.status?.translatedName ?? item?.status?.name}
                  </CText>
                </div>
              )}
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

const InfoIcon = ({
  mainIcon,
  mainIconColor,
  subIcon,
  subIconColor,
  onPress,
}) => {
  return (
    <IconText
      icon={mainIcon}
      iconSize={18}
      containerStyle={{
        color: mainIconColor,
        backgroundColor: 'rgba(0,0,0,0.5)',
        position: 'absolute',
        zIndex: 997,
        top: 2,
        right: 18,
      }}
      onPress={onPress}
    >
      <i className={subIcon} style={{
        fontSize: 14,
        color: subIconColor,
        position: 'absolute',
        bottom: 3,
        right: 2,
      }}/>
    </IconText>
  )
}

const VerifyCollection = ({data, onFinish}) => {
  const { t } = useTranslation();
  const [items, setItems] = useState();
  const activityHook = useActivity();
  const [violations, setViolations] = useState([]);

  const checkerControlName = `scan-card-${data.id}`;

  // ITEM CHECKER
  const itemChecker = useItemChecker(checkerControlName);

  // ACTION
  const fetchItems = async () => {
    var activityRef = activityHook.start();

    const response = await ItemAPI.getByCriteria(data.barcode, 1);

    if (response?.status) {
      setItems(response?.payload);
    }

    activityHook.stop(activityRef);
  };

  const validateHandler = async () => {
    var activityRef = activityHook.start();

    var formData = {
      chosenItem: itemChecker.list()
    }

    var response = await ScanAPI.chooseItem(data.id, formData);

    if (response.status) {
      onFinish && onFinish(response.payload);
    }

    // Violations
    setViolations(response?.violations);

    activityHook.stop(activityRef);
  }

  const cancelHandler = () => {
    onFinish && onFinish();
  }

  useEffect(() => {
    fetchItems(data);
  }, [data])

  return (
    <div className='container'>
      <div className='row mg-b-50'>
        <div className='col-12'>
          <CText>
            <CText style={{color: '#17A2B8'}}>{data.barcode}</CText>
            {' '}
            {t('fe.collection.scans.verification.description')}
          </CText>
        </div>
      </div>
      {activityHook.isLoading ? <Loader centerScreen={false}/> : items && (
        <GridList
          data={items}
          numColumns={'col-lg-6 col-12 mb-3'}
          renderItem={({item, index}) => {
            return (
              <CheckWrapper
                key={item.uuid}
                identifier={item.uuid}
                checkerControl={checkerControlName}
                checked={item.uuid === data.chosenItem.uuid}
              >
                <div style={{margin: 1}}>
                  <ItemCard data={item} isList={true} readOnly={true} />
                </div>
              </CheckWrapper>
            )
          }}
        />
      )}
      <div className="row mg-t-50">
        <div className='col-12 mg-t'>
          <Button
            title={t('fe.collection.scans.verification.confirm')}
            className={'btn btn-primary mg-r-10'}
            onClick={() => validateHandler()}
          />
          <Button
            title={t('fe.collection.scans.verification.cancel')}
            className={'btn btn-secondary'}
            onClick={() => cancelHandler()}
          />
        </div>
      </div>
    </div>
  )
}

export default ScanCard;