/* eslint-disable react-hooks/exhaustive-deps */

import { useDispatch, useSelector } from "react-redux";
import { checkerCancel } from "../redux/Checker/checker-actions";
import { COLLECTION_BATCH_DELETE, COLLECTION_DELETE } from "../redux/CollectionItem/collectionItem-types";

const useCollectionAction = () => {
  // DISPATCH
  const dispatch = useDispatch();

  // SELECTOR
  const auth = useSelector(state => {
    return state.auth;
  });

  const collectionItemState = useSelector(state => {
    return state.collectionItem;
  });

  const checkerState = useSelector(state => {
    return state.checker;
  });

  // isCurrent User
  const isCurrentUser = (identifier) => {
    return identifier === auth?.user?.uuid ? true : false;
  }

  // Collection ScrollList state handler
  const collectionStateHandler = ({datas, setDatas, checkerControlName}) => {
    // COLLECTION_DELETE_ITEM
    if (collectionItemState.action === COLLECTION_DELETE) {
      if (collectionItemState?.collectionItem?.uuid) {
        const foundData = datas.find(data => {
          return data?.uuid === collectionItemState?.collectionItem?.uuid
        });

        if (foundData) {
          const filteredData = datas.filter(
            data => data.uuid !== foundData.uuid,
          );

          setDatas(filteredData);
        }
      }
    }

    // COLLECTION_BATCH_DELETE
    if (collectionItemState.action === COLLECTION_BATCH_DELETE) {
      var filteredData = [...datas];

      // eslint-disable-next-line array-callback-return
      checkerState?.data[checkerControlName]?.checkList.map(checked => {
        filteredData = filteredData.filter(
          data => data.uuid !== checked,
        );
      })

      // Update scrollview
      setDatas(filteredData);

      // Reinitialize checker
      dispatch(checkerCancel(checkerControlName));
    }
  }

  return {
    collectionStateHandler: collectionStateHandler,
    isCurrentUser: isCurrentUser
  };
};

export default useCollectionAction;
