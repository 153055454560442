/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from "react";
import {useParams} from "react-router-dom";
import {GridList, ItemCard, Loader, ModalLink} from '@crosslud/components';
import useItemLicenceForm from "@crosslud/hooks/useItemLicenceForm";
import useModalNavigate from "@crosslud/hooks/useModalNavigate";
import { ItemAPI } from "@crosslud/apis/ItemAPI";
import Alert from "@crosslud/helpers/Alert";

const RequestItemCode = () => {
  const {code} = useParams();
  const modalNavigate = useModalNavigate();

  const [barcodeInfo, setBarcodeInfo] = useState([]);
  const [activity, setActivity] = useState(true);

  const itemLicenceForm = useItemLicenceForm();

  const fetchBarcodeInfo = async () => {
    setActivity(true);

    const response = await ItemAPI.getBarcodeInfo(code, 1);

    if (response?.status) {
      setBarcodeInfo(response?.payload);
    }

    setActivity(false);
  };

  const handleFormSubmit = () => {
    if (barcodeInfo?.isCreatable) {
      itemLicenceForm.init();
      itemLicenceForm.setBarcode(code);
    
      modalNavigate('/item-modal/item-header-edit');
    } else {
      Alert.alert(
        'Assignation requise',
        'Vous n\'avez pas les droits requis pour effectuer cette action',
        [
          {
            text: 'J\'ai compris',
            style: 'cancel',
          },
        ],
      );
    }
  }

  // Main
  useEffect(() => {
    fetchBarcodeInfo();
  }, [code]);

  return (
    <div className='container'>
      <div className='row'>
        <div className="col">
          <div className="br-pagetitle mg-b-20-force pd-x-15 bg-black-1">
            <i className="icon ion-ios-search"></i>                            
            <div>
              <h4>Item request</h4>
            </div>
          </div>
          {activity ? <Loader /> : (
          <div className="row justify-content-center">
            <div className="col-md-10 col-lg-8">
              <div className="row">
                <div className="col-12">
                  <div style={{display: 'flex', backgroundColor: 'rgba(0, 0, 0, 0.6)', padding: 10}}>
                    <div style={{flex: 1, color: 'rgba(255, 255, 255, 0.7)', fontSize: 14, fontWeight: 'bold'}}>
                      Barcode<br />
                      <span style={{fontWeight: 'bold', color: 'orange', fontSize: 20}}>
                        {code && code}
                      </span>
                    </div>
                    <div>
                      <span className="btn" onClick={() => handleFormSubmit()}>
                        <i className="icon ion-ios-plus pos-absolute r-20 t--15" style={{fontSize: 70, color: 'green'}}></i>
                      </span>
                    </div>
                  </div>
                </div>
              </div>

              {barcodeInfo?.items &&
                <div className="row mg-t-20">
                  <div className="col-12">
                    <GridList
                      data={barcodeInfo?.items}
                      numColumns={'col-lg-3 col-md-4 col-6 mb-3'}
                      renderItem={({item, index}) =>
                        <ModalLink
                          key={item?.uuid}
                          to={{
                            pathname: `/item-modal/item/details/${(item.parentUuid || item.uuid)}`,
                          }}>
                          <ItemCard data={item} overlay={true} />
                        </ModalLink>
                      }
                    />
                  </div>
                </div>
              }
            </div>
          </div>
        )}
        </div>
      </div>
    </div>
  )
}

export default RequestItemCode;