/* eslint-disable react-hooks/exhaustive-deps */
import { ScrollList, ItemCard, Topic, ModalLink, GridList, Loader } from '@crosslud/components';
import { Link } from 'react-router-dom';
import { CommonAPI } from '@crosslud/apis/CommonAPI';
import { memo, useEffect, useState } from 'react';
import useActivity from '@crosslud/hooks/useActivity';
import { useTranslation } from 'react-i18next';
import crossludInstagramQr from '@crosslud/crosslud-insta-qr.png';

const Home = () => {
  const {t} = useTranslation();

  return (
    <div className="br-pagebody">
      <section className='mg-t-100 mg-b-80'>
        <div className="row justify-content-md-center">
          <div className='col-12 col-md-8 mg-t-40 tx-18'>
            {t('fe.application.home.title')}<br />
            {t('fe.application.home.description')}
          </div>
        </div>
      </section>
      <section className='mg-t-100 mg-b-80'>
        <h2 className="tx-info tx-light tx-center">{t('fe.application.beta.test.title')}</h2>
        <div className="row justify-content-md-center">
          <div className='col-12 col-md-8 mg-t-40 tx-18 tx-center'>
            {t('fe.application.beta.test.description')}
          </div>
        </div>
        <div className="row justify-content-md-center">
          <div className='col-12 tx-18 tx-center mg-t-20'>
            <img src={crossludInstagramQr} className="ht-200" alt={'instagram'} />
          </div>
        </div>
        <div className="row justify-content-md-center">
          <div className='col-12 tx-16 tx-center mg-t-20'>
            <a rel="noreferrer" target="_blank" className="pd-x-5" href="https://www.instagram.com/_crosslud_">
              {t('fe.application.beta.test.link')}
            </a>
          </div>
        </div>
      </section>
      <ScrollList
        urlCaller={{
          url: CommonAPI.getInfoFeeds,
          params: [1],
          paginationIndex: 0,
        }}
        className="row"
        numColumns={'col-12'}
        renderItem={({item, index}) => (
          <InfoFeed key={`infoFeed_${index}`} data={item} />
        )}
        ListEmptyComponent={() => (
          <div className="col-12 mb-3">
            <div className="bd bd-white-1 ht-100 d-flex align-items-center justify-content-center">
              <h6 className="tx-uppercase mg-b-0 tx-roboto tx-normal tx-spacing-2">Aucun résultat</h6>
            </div>
          </div>
        )}
        //onScroll={onScroll}
        loaderCenterScreen={false}
      />
    </div>
  )
}

export default Home;

const InfoFeed = memo(({data}) => {
  const [info, setInfo] = useState();
  const activityHook = useActivity();
  
  const fetchInfoFeed = async (reference) => {
    const activityRef = activityHook.start();

    const response = await CommonAPI.getInfoFeed(reference);

    if (response.status) {
      setInfo(response?.payload);
    }

    activityHook.stop(activityRef);
  };

  useEffect(() => {
    fetchInfoFeed(data.reference);
  }, []);

  return (
    activityHook.isLoading ? <Loader centerScreen={false} /> :
    <section className='mg-t-40'>
      {info?.translatedTitle && 
        <h3 className="tx-info tx-light">
          {info?.translatedTitle}
        </h3>
      }
      {info?.subTitle && <h5>{info?.subTitle}</h5>}
      <div className="mg-b-10"></div>
      {/**
       * EXTRADATA NON-OBJECT FEED
       * Main categories
       */}
      {info?.dataType === 'main_categories' &&
        <GridList
          data={info?.extraData}
          numColumns={'col-lg-3 col-md-4 col-6 mg-b-20'}
          renderItem={({item, index}) =>
            <Link key={index} to={`/browse/${item?.alias?.id}`}>
              <div className="card">
                <div className="pd-x-20 pd-y-5 d-flex align-items-center">
                  <i className={`ion ${item?.icon ? item.icon : 'ion-earth'} tx-50 lh-0 tx-primary`}></i>
                  <div className="mg-l-20">
                    <p className="tx-16 tx-white tx-lato mg-b-0 lh-1">
                      {item?.translatedName}
                    </p>
                  </div>
                </div>
              </div>
            </Link>
          }
        />
      }

      {/**
       * EXTRADATA NON-OBJECT FEED
       * Last inserted
       */}
      {info?.dataType === 'last_inserted' &&
        <GridList
          data={info?.extraData}
          numColumns={'col-lg-2 col-md-4 col-6'}
          renderItem={({item, index}) =>
            <div key={index} className="mb-3">
              <ModalLink
                to={{
                  pathname: `/item-modal/item/details/${(item.uuid)}`,
                }}>
                <ItemCard data={item} overlay={true} />
              </ModalLink>
            </div>
          }
        />
      }

      {/**
       * ITEM OBJECT FEED
       */}
      {info?.dataType === 'item' && (
        <GridList
          data={info?.extraData}
          numColumns={'col-lg-2 col-md-4 col-6'}
          renderItem={({item, index}) =>
            <div key={index} className="mb-3">
              <ModalLink
                to={{
                  pathname: `/item-modal/item/details/${(item.uuid)}`,
                }}>
                <ItemCard data={item} overlay={true} />
              </ModalLink>
            </div>
          }
        />
      )}

      {/**
       * TOPIC OBJECT FEED
       */}
      {info?.dataType === 'topic' && (
        <Topic 
          domain={info?.extraData?.[0]?.domain} 
          title={info?.extraData?.[0]?.title} 
          description={info?.extraData?.[0]?.shortDescription}
          mediaType={info?.extraData?.[0]?.mediaType?.type}
          mediaPath={info?.extraData?.[0]?.mediaPath} 
          mediaPosition={info?.extraData?.[0]?.mediaPosition?.position}
        />
      )}
    </section>
  )
});