import { api } from "./configs/fetchConfig";
import { fetchUtils } from "./configs/fecthUtils";
import { itemReviewSchema } from "./configs/schema";

export const ReviewAPI = {
  /**
   * Get item reviews
   * @param {*} itemId
   * @param {*} filters
   * @param {*} page
   * @returns 
   */
  getReviews: async (itemId, filters = [], page = 1) => {
    const newFilters = filters.concat('page=' + page);

    var headers = new Headers();
    headers.set('Accept', 'application/ld+json');

    return await api.fetchResource('/api/items/' + itemId + '/reviews.jsonld?' + newFilters.join('&'), {
      method: 'GET',
      headers: headers,
    });
  },
  /**
   * Get current-user reviews
   * @param {*} itemId
   * @param {*} filters
   * @param {*} page
   * @returns 
   */
  getCurrentUserReview: async (itemId, filters = [], page = 1) => {
    const newFilters = filters.concat('page=' + page);

    var headers = new Headers();
    headers.set('Accept', 'application/ld+json');

    return await api.fetchResource('/api/items/' + itemId + '/current-user-review.jsonld?' + newFilters.join('&'), {
      method: 'GET',
      headers: headers,
    });
  },
  /**
   * Get others-reviews
   * @param {*} itemId
   * @param {*} filters
   * @param {*} page
   * @returns 
   */
  getOthersUsersReviews: async (itemId, filters = [], page = 1) => {
    const newFilters = filters.concat('page=' + page);

    var headers = new Headers();
    headers.set('Accept', 'application/ld+json');

    return await api.fetchResource('/api/items/' + itemId + '/others-users-reviews.jsonld?' + newFilters.join('&'), {
      method: 'GET',
      headers: headers,
    });
  },
  /**
   * Create review
   * @param {*} data 
   * @returns 
   */
  createReview: async data => {
    return await api.fetchResource('/api/item-reviews', {
      method: 'POST',
      body: JSON.stringify(fetchUtils.bodyFormat(data, itemReviewSchema)),
    });
  },
  /**
   * Update review
   * @param {*} id 
   * @param {*} data 
   * @returns 
   */
  updateReview: async (id, data) => {
    return await api.fetchResource('/api/item-reviews/' + id, {
      method: 'PUT',
      body: JSON.stringify(fetchUtils.bodyFormat(data, itemReviewSchema)),
    });
  },
}