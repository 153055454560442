/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from 'react';
import {FormInputDate, FormInputText, FormSelect, Loader} from '@crosslud/components';
import {useDispatch} from 'react-redux';
import {collectionUpdate} from '@crosslud/redux/CollectionItem/collectionItem-actions';
import {loaderStart, loaderEnd} from '@crosslud/redux/Loader/loader-actions';
import {Controller, useForm} from 'react-hook-form';
import { CollectionAPI } from '@crosslud/apis/CollectionAPI';
import { CommonAPI } from '@crosslud/apis/CommonAPI';
import useActivity from '@crosslud/hooks/useActivity';
import Alert from '@crosslud/helpers/Alert';
import { useTranslation } from 'react-i18next';

const PrivateNote = ({collectionItem, onFinish}) => {
  const {t} = useTranslation();
  const activityHook = useActivity();
  const [status, setStatus] = useState();
  const [currencies, setCurrencies] = useState();
  const [violations, setViolations] = useState([]);

  // FORM
  const {
    control,
    handleSubmit,
  } = useForm({
    defaultValues: {
      purchasedAt: collectionItem?.purchasedAt ?? "",
      status: collectionItem?.status,
      price: collectionItem?.price ?? "",
      currency: collectionItem?.currency,
      note: collectionItem?.note ?? ""
    }
  });

  const dispatch = useDispatch();

  const fetchStatus = async () => {
    var activityRef = activityHook.start();

    const response = await CollectionAPI.getStatus();

    if (response.status) {
      setStatus(response?.payload);
    }

    activityHook.stop(activityRef);
  };

  const fetchCurrencies = async () => {
    var activityRef = activityHook.start();

    const response = await CommonAPI.getCurrencies();

    if (response.status) {
      setCurrencies(response?.payload);
    }

    activityHook.stop(activityRef);
  };

  // On submit
  const onSubmit = async formData => {
    dispatch(loaderStart());

    const response = await CollectionAPI.updateCollectionItem(
      collectionItem.uuid,
      formData.purchasedAt,
      formData.note,
      formData.price,
      formData.status,
      formData.currency,
    );

    if (response?.status) {
      dispatch(collectionUpdate(response?.payload));
      onFinish && onFinish();

      Alert.notify(
        t('fe.privateNote.update.alert.success.title'),
        t('fe.privateNote.update.alert.success.description'),
        'success',
      );
    }

    // Violations
    setViolations(response?.violations);

    dispatch(loaderEnd());
  };

  useEffect(() => {
    fetchStatus();
    fetchCurrencies();
  }, []);

  return (
    <div className="container">
      <div className="row">
        <div className="col-12 pd-x-40 pd-b-40">
          <div className="row">
            <div className="col-12 mg-b-20">
              <h4 className="text-center">{t('fe.privateNote.form.title')}</h4>
            </div>
          </div>
          <div className="row">
            <div className="col-12 tx-center">
            {activityHook.isLoading ? <Loader centerScreen={false} /> :
              <>
                {violations?.error?.message && <p className='tx-center text-danger'>{violations?.error?.message}</p>}
                {violations?.currentPassword?.message && <p className='tx-center text-danger'>{violations?.currentPassword?.message}</p>}
                <form className="form-layout form-layout-6">
                  <div className="row no-gutters">
                    <div className="col-5">
                      <label className="form-control-label">
                        {t('fe.privateNote.form.purchaseDate.label')}:
                      </label>
                    </div>
                    <div className="col-7">
                      <Controller
                        control={control}
                        name={'purchasedAt'}
                        render={({ field }) =>
                          <FormInputDate
                            {...field}
                            containerStyle={{
                              borderWidth: 1,
                              borderColor: 'rgba(255, 255, 255, 0.08)',
                            }}
                            inputStyle={{color: '#fff'}}
                          />
                        }
                      />
                      {violations?.purchasedAt?.message && 
                        <span className='text-danger'>
                          {violations?.purchasedAt?.message}
                        </span>
                      }
                    </div>
                  </div>
                  <div className="row no-gutters">
                    <div className="col-5">
                      <label className="form-control-label">
                        {t('fe.privateNote.form.purchasePrice.label')}:
                      </label>
                    </div>
                    <div className="col-7">
                      <Controller
                        control={control}
                        name={'price'}
                        render={({ field }) =>
                          <FormInputText
                            {...field}
                            placeholder={t('fe.privateNote.form.price.placeholder')}
                            violation={violations['price']}
                          />
                        }
                      />
                      {violations?.price?.message && 
                        <span className='text-danger'>
                          {violations?.price?.message}
                        </span>
                      }
                    </div>
                  </div>
                  <div className="row no-gutters">
                    <div className="col-5">
                      <label className="form-control-label">
                        {t('fe.privateNote.form.currency.label')}:
                      </label>
                    </div>
                    <div className="col-7">
                      <Controller
                        control={control}
                        name={'currency'}
                        render={({ field }) =>
                          <FormSelect
                            {...field}
                            items={currencies}
                            labelselector={'code'}
                            idselector={'id'}
                            placeholder={t('fe.privateNote.form.currency.placeholder')}
                          />
                        }
                      />
                      {violations?.currency?.message && 
                        <span className='text-danger'>
                          {violations?.currency?.message}
                        </span>
                      }
                    </div>
                  </div>
                  <div className="row no-gutters">
                    <div className="col-5">
                      <label className="form-control-label">
                        {t('fe.privateNote.form.status.label')}:
                      </label>
                    </div>
                    <div className="col-7">
                      <Controller
                        control={control}
                        name={'status'}
                        render={({ field }) =>
                          <FormSelect
                            {...field}
                            items={status}
                            labelselector={'name'}
                            idselector={'id'}
                            placeholder={t('fe.privateNote.form.status.placeholder')}
                          />
                        }
                      />
                      {violations?.status?.message && 
                        <span className='text-danger'>
                          {violations?.status?.message}
                        </span>
                      }
                    </div>
                  </div>
                  <div className="row no-gutters">
                    <div className="col-5">
                      <label className="form-control-label">
                        {t('fe.privateNote.form.note.label')}:
                      </label>
                    </div>
                    <div className="col-7">
                      <Controller
                        control={control}
                        name={'note'}
                        render={({ field }) =>
                          <FormInputText
                            {...field}
                            violation={violations['note']}
                            placeholder={t('fe.privateNote.form.note.placeholder')}
                            multiline
                          />
                        }
                      />
                      {violations?.currency?.message && 
                        <span className='text-danger'>
                          {violations?.currency?.message}
                        </span>
                      }
                    </div>
                  </div>
                </form>
                <div className="form-layout-footer bd pd-20 bd-t-0">
                  <button className="btn btn-primary col" onClick={handleSubmit(onSubmit)}>
                    <span>{t('fe.privateNote.form.submit.button')}</span>
                  </button>
                </div>
              </>
            }
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PrivateNote;
