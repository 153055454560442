import {produce} from 'immer';
import * as actionTypes from './checker-types';

const INITIAL_STATE = {
  data: {},
};

// crosslud.contact@gmail.com
const checkerReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actionTypes.CHECKER_ADD:
      return produce(state, function (draft) {
        // checklist add
        draft.data[action.payload.category] = {
          checkList: [
            ...(draft?.data[action.payload.category]?.checkList ?? []),
            action.payload.identifier,
          ],
        };

        // count
        draft.data[action.payload.category].count =
          draft?.data[action.payload.category]?.checkList?.length;

        // action
        draft.action = actionTypes.CHECKER_ADD;

        return draft;
      });

    case actionTypes.CHECKER_REPLACE:
      return produce(state, function (draft) {
        if (draft?.data[action?.payload?.category]) {
          // checklist replace
          draft.data[action.payload.category] = {
            checkList: [...[], action.payload.identifier]
          }

          // count
          draft.data[action.payload.category].count =
            draft?.data[action.payload.category]?.checkList?.length;

          // action
          draft.action = actionTypes.CHECKER_REPLACE;

          return draft;
        }
      });

    case actionTypes.CHECKER_REMOVE:
      return produce(state, function (draft) {
        if (draft?.data[action?.payload?.category]) {
          // checklist remove
          draft.data[action.payload.category].checkList = draft?.data[
            action.payload.category
          ]?.checkList.filter(identifier => {
            return identifier !== action.payload.identifier;
          });

          // count
          draft.data[action.payload.category].count =
            draft?.data[action.payload.category]?.checkList?.length;

          // action
          draft.action = actionTypes.CHECKER_REMOVE;

          return draft;
        }
      });

    case actionTypes.CHECKER_CANCEL:
      return produce(state, function (draft) {
        if (draft?.data[action?.payload?.category]) {
          // checklist remove
          draft.data[action.payload.category].checkList = [];

          // count
          draft.data[action.payload.category].count = 0;

          // action
          draft.action = actionTypes.CHECKER_CANCEL;

          return draft;
        }
      });
    default:
      return produce(state, function (draft) {});
  }
};

export default checkerReducer;
