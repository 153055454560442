import { api } from "./configs/fetchConfig";
import { fetchUtils } from "./configs/fecthUtils";
import { itemCollectionSchema, itemSchema } from "./configs/schema";

export const CollectionAPI = {
  getStatus: async (filters = [], page = 1) => {
    const newFilters = filters.concat('page=' + page);

    var headers = new Headers();
    headers.set('Accept', 'application/ld+json');

    return await api.fetchResource('/api/item-collection-statuses.jsonld?' + newFilters.join('&'), {
      method: 'GET',
      headers: headers,
    });
  },
  /**
   * Get collection titles
   * @param {*} user 
   * @param {*} category 
   * @param {*} filters 
   * @param {*} page 
   * @returns 
   */
  getCollectionTitles: async (
    user,
    category,
    filters = [],
    page = 1,
  ) => {
    let query = fetchUtils.customQueryFilterFormat(filters, itemSchema).join('&');

    let q = '?page=' + page + (query && '&' + query);

    var headers = new Headers();
    headers.set('Accept', 'application/ld+json');

    return await api.fetchResource('/api/users/' + user + '/collections/' + category + '/titles.jsonld' + q, {
      method: 'GET',
      headers: headers
    });
  },
  /**
   * Get Collection title
   * @param {*} user 
   * @param {*} title 
   * @returns 
   */
  getCollectionTitle: async (user, title) => {
    var headers = new Headers();
    headers.set('Accept', 'application/ld+json');

    return await api.fetchResource(
      '/api/users/' + user + '/titles/' + title + '.jsonld',
      {
        method: 'GET',
        headers: headers,
      },
    );
  },
  /**
   * get collection item-collections
   * @param {*} user 
   * @param {*} category 
   * @param {*} filters 
   * @param {*} page 
   * @returns 
   */
  getCollectionItems: async (
    user,
    category,
    filters = [],
    page = 1,
  ) => {
    let query = fetchUtils.customQueryFilterFormat(filters, itemSchema).join('&');

    let q = '?page=' + page + (query && '&' + query);

    var headers = new Headers();
    headers.set('Accept', 'application/ld+json');

    return await api.fetchResource('/api/users/' + user + '/collections/' + category + '/items.jsonld' + q, {
      method: 'GET',
      headers: headers
    });
  },
  /**
   * Get item-collection
   * @param {*} id 
   * @returns 
   */
  getCollectionItem: async id => {
    var headers = new Headers();
    headers.set('Accept', 'application/json');

    return await api.fetchResource('/api/item-collections/' + id + '.json', {
      method: 'GET',
      headers: headers,
    });
  },
  /**
   * Get collector title item-collections
   * @param {*} collector 
   * @param {*} title 
   * @param {*} filters 
   * @param {*} page 
   * @returns 
   */
  getCollectorTitleItems: async (collector, title, filters = {}, page = 1) => {
    let query = fetchUtils
      .customQueryFilterFormat(filters, itemSchema)
      .join('&');

    let q = '?page=' + page + (query && '&' + query);

    var headers = new Headers();
        headers.set('Accept', 'application/ld+json');

    return await api.fetchResource(
      '/api/users/' + collector + '/titles/' + title + '/items.jsonld' + q,
      {
        method: 'GET',
        headers: headers
      },
    );
  },
  /**
   * Create item-collection
   * @param {*} id 
   * @returns 
   */
  addItem: async id => {
    return await api.fetchResource('/api/item-collections', {
      method: 'POST',
      body: JSON.stringify({
        item: '/api/items/' + id,
      }),
    });
  },
  /**
   * Update item-collection
   * @param {*} id 
   * @param {*} purchasedAt 
   * @param {*} note 
   * @param {*} price 
   * @param {*} status 
   * @param {*} currency 
   * @returns 
   */
  updateCollectionItem: async (id, purchasedAt, note, price, status, currency) => {
    var data = {
      note: note,
      purchasedAt: purchasedAt,
      price: price,
      status: status,
      currency: currency
    }
    
    return await api.fetchResource('/api/item-collections/' + id, {
      method: 'PUT',
      body: JSON.stringify(fetchUtils.bodyFormat(data, itemCollectionSchema)),
    });
  },
  /**
   * Delete item-collection
   * @param {*} id 
   * @returns 
   */
  deleteCollectionItem: async id => {
    return await api.fetchResource('/api/item-collections/' + id, {
      method: 'DELETE'
    });
  },
  /**
   * Batch delete item-collections
   * @param {*} uuids 
   * @returns 
   */
  batchDeleteCollectionItem: async uuids => {
    return await api.fetchResource('/api/batch-delete/item-collections', {
      method: 'POST',
      body: JSON.stringify({
        uuids: uuids,
      }),
    });
  },
}