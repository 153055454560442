//import Rating from 'react-rating';
import {Media} from '@crosslud/components';
import { useTranslation } from 'react-i18next';

const Review = ({item, defaultAvatar, defaultMessage, footer}) => {
  const { t } = useTranslation();

  return (
    <div className="list-group-item list-group-item-action media d-flex align-items-start">
      <Media 
        mediaUri={item?.user?.avatar?.contentUrl ?? defaultAvatar} 
        avatar={true} 
        size={70} 
      />
      <div className="media-body mg-l-15">
        <div className="msg-top">
          <span>
            {item?.user?.nickname}<br />
            <span>{item?.updatedAt}</span>
          </span>
          <div className="valign-top">
            <span className='tx-13 tx-white'>
              {item?.rate ? `${t('fe.review.rating.text')}: ${item?.rate} / 10` : ''}
            </span>
            {/*
            <Rating
              initialRating={item?.rate}
              stop={10}
              emptySymbol={<i className="icon ion-star tx-gray-500 tx-18"></i>}
              fullSymbol={<i className="icon ion-star tx-warning tx-18"></i>}
              readonly
              />*/}
          </div>
        </div>
        <p className="msg-summary">
          {item?.message ?? defaultMessage}
        </p>
        {footer}
      </div>
    </div>
  )
}

export default Review;