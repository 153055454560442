import { api } from "./configs/fetchConfig";
import { fetchUtils } from "./configs/fecthUtils";
import { itemGroupSchema } from "./configs/schema";

export const LicenceAPI = {
  /**
   * Create item-group
   * @param {*} data 
   * @returns 
   */
  create: async (data) => {
    return await api.fetchResource('/api/item-groups', {
      method: 'POST',
      body: JSON.stringify(fetchUtils.bodyFormat(data, itemGroupSchema)),
    });
  },
  /**
   * Create item-group child
   * @param {*} id 
   * @param {*} data 
   * @returns 
   */
  postItemGroup: async (id, data) => {
    return await api.fetchResource('/api/item-groups/' + id, {
      method: 'POST',
      body: JSON.stringify(fetchUtils.bodyFormat(data, itemGroupSchema)),
    });
  },
  /**
   * Update item-group
   * @param {*} id 
   * @param {*} data 
   * @returns 
   */
  update: async (id, data) => {
    return await api.fetchResource('/api/item-groups/' + id, {
      method: 'PUT',
      body: JSON.stringify(fetchUtils.bodyFormat(data, itemGroupSchema)),
    });
  },
  /**
   * Get item-group
   * @param {*} id 
   * @returns 
   */
  get: async id => {
    var headers = new Headers();
    headers.set('Accept', 'application/ld+json');

    return await api.fetchResource('/api/item-groups/' + id + '.jsonld', {
      method: 'GET',
      headers: headers,
    });
  },
  /**
   * Get item-group item-groups
   * @param {*} id 
   * @param {*} filters 
   * @param {*} page 
   * @returns 
   */
  getItemGroups: async (id, filters = [], page = 1) => {
    const newFilters = filters.concat('page=' + page);

    var headers = new Headers();
    headers.set('Accept', 'application/ld+json');

    return await api.fetchResource('/api/item-groups/' + id + '/item-groups.jsonld?' + newFilters.join('&'), {
      method: 'GET',
      headers: headers,
    });
  },
  /**
   * Delete item-group
   * @param {*} id 
   * @returns 
   */
  delete: async (id) => {
    return await api.fetchResource('/api/item-groups/' + id, {
      method: 'DELETE'
    });
  },
  /**
   * Get Category Titles
   * @param {*} category 
   * @param {*} filters 
   * @param {*} page 
   * @returns 
   */
  getTitles: async (category, filters = [], page = 1) => {
    let query = fetchUtils
      .customQueryFilterFormat(filters, itemGroupSchema)
      .join('&');
    
    let q = '?page=' + page + (query && '&' + query);

    var headers = new Headers();
    headers.set('Accept', 'application/ld+json');

    return await api.fetchResource(
      '/api/categories/' + category + '/titles.jsonld' + q,
      {
        method: 'GET',
        headers: headers,
      },
    );
  },
  /**
   * Get all protected
   * @param {*} category 
   * @param {*} filters 
   * @param {*} page 
   * @returns 
   */
  getAllProtected: async (category, filters = [], page = 1) => {
    let query = fetchUtils
      .customQueryFilterFormat(filters, itemGroupSchema)
      .join('&');
    
    let q = '?page=' + page + (query && '&' + query);

    var headers = new Headers();
    headers.set('Accept', 'application/ld+json');

    return await api.fetchResource(
      '/api/protected/categories/' + category + '/item-groups.jsonld' + q,
      {
        method: 'GET',
        headers: headers,
      },
    );
  },
}