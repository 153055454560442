/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
import React, { useEffect, useState } from 'react';
import { CommandAPI } from '@crosslud/apis/CommandAPI';
import useMercure from '@crosslud/hooks/useMercureHub';
import useActivity from '@crosslud/hooks/useActivity';
import { Loader } from '@crosslud/components';
import useActiveLink from '@crosslud/hooks/useActiveLink';

const AdminCommand = () => {
  // SET ACTIVELINK
  useActiveLink('adminLayout', 'commands');

  const activityHook = useActivity();

  const [executables, setExecutables] = useState([]);
  const [commands, setCommands] = useState([]);

  const fetchExecutables = async () => {
    const response = await CommandAPI.getExecutables([], 1);

    if (response?.status) {
      setExecutables(response?.payload);
    }
  }

  const fetchCommands = async () => {
    var activityRef = activityHook.start();

    const response = await CommandAPI.getAll([], 1);

    if (response?.status) {
      setCommands(response?.payload);
    }

    activityHook.stop(activityRef);
  }

  const fieldUpdateHandler = async (id) => {
    const response = await CommandAPI.post({executable: id});

    if (response?.status) {
      setCommands([response?.payload, ...commands]);
    }
  }

  useEffect(() => {
    fetchCommands();
    fetchExecutables();
  }, []);

  return (
    <div className='container'>
      <div className='row'>
        <div className='col'>
          <div className='bg-black-1'>
            <div className="ht-100p">
              <div className='row'>
                <div className='col-12 mg-b-10'>
                  <div className="br-pagetitle pd-x-15 bg-black-1">
                    <i className="icon ion-ios-search"></i>
                    <div>
                      <h4>Command</h4>
                    </div>
                    <div className="btn-group pos-absolute r-30">
                      
                    </div>
                  </div>
                </div>
              </div>
              <div className='row'>
                <div className='col-12'>
                  <div className='bg-black-1 pd-20'>
                    <div className="row">
                      <div className="col-12">
                        <div className="d-flex justify-content-center">
                          {executables?.map((executable) => {
                            return (
                              <button key={executable.id} title={executable.name} type="button" className="btn btn-outline-light rounded-0 mg-5 tx-18" onClick={() => fieldUpdateHandler(executable.id)}>
                                <i className={executable?.icon ? executable?.icon : "fa fa-cog"}></i>
                              </button>
                            )
                          })}
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12 mg-t-20">
                        {activityHook.isLoading ? <Loader centerScreen={false} /> : (
                          <ul className="list-group">
                            {commands?.map(command => {
                              return (
                                <li key={command.uuid} className="list-group-item">
                                  <CommandRow data={command} />
                                </li>
                              )
                            })}
                          </ul>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const CommandRow = ({data}) => {
  const mercureHook = useMercure();
  const [command, setCommand] = useState(data);

  if (!command.finishedAt) {
    const es = mercureHook.topic(data.target);

    es.onmessage = e => {
      // Will be called every time an update is published by the server
      //console.log(e.data);
      setCommand(JSON.parse(e.data));
    }
  }

  return (
    <p className="mg-b-0">
      <div className="d-flex justify-content-between align-items-center tx-12 mg-b-10">
        <span className="tx-info">{command?.executable?.name}</span>
        <span>{(new Date(command?.createdAt)).toLocaleString()}</span>
      </div>
      <span className="tx-12 mg-r-8">
        <span className="square-10 mg-r-5 rounded-circle" style={{backgroundColor: command?.status?.color ?? '#ffffff'}}></span>
      </span>
      <strong className="tx-white tx-medium">{command?.executable?.name}</strong>
      <span className="text-muted mg-l-10">
        {command?.finishedAt ? 'Done' : (command?.updatedAt ? 'Running...' : 'Waiting...')}
      </span>
    </p>
  )
}

export default AdminCommand;