import React from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import { useTranslation } from 'react-i18next';

const LangSwitcher = ({user}) => {
  const { t, i18n } = useTranslation();

  const languages = {
    fr: { name: t('Français') },
    en: { name: t('English') }
  };

  const changeLanguage = (code) => {
    i18n.changeLanguage(code);

    // reload page
    window.location.reload();
  }

  return (
    <Dropdown>
      <Dropdown.Toggle as={"span"} className='dropdown-toggle cursor-pointer'>
        {languages?.[i18n.resolvedLanguage.toLocaleLowerCase()]?.name}
      </Dropdown.Toggle>
      <Dropdown.Menu className="dropdown-menu dropdown-menu-header" x-placement="bottom-start">
        {Object.keys(languages).map((lang) => {
          return (
            <Dropdown.Item key={lang} className='bd-b pd-y-5' onClick={() => changeLanguage(lang)}>
              <span className={`${i18n.resolvedLanguage === lang ? 'tx-info ' : 'tx-white'} tx-14`}>
                {languages?.[lang].name}
              </span>
            </Dropdown.Item>
          )
        })}
      </Dropdown.Menu>
    </Dropdown>
  )
}

export default LangSwitcher;