import React, { memo } from 'react';
import {useDispatch} from 'react-redux';
import {collectionBatchDeleteItem} from '@crosslud/redux/CollectionItem/collectionItem-actions';
import {loaderStart, loaderEnd} from '@crosslud/redux/Loader/loader-actions';
import Alert from '@crosslud/helpers/Alert';
import useItemChecker from '@crosslud/hooks/useItemChecker';
import { CollectionAPI } from '@crosslud/apis/CollectionAPI';
import { useTranslation } from 'react-i18next';

const ScrollListCheckerHeader = memo(({titleComponent, checkerControl, style = {}}) => {
  const {t} = useTranslation();

  // DISPATCH
  const dispatch = useDispatch();

  // ITEM CHECKER
  const itemChecker = useItemChecker(checkerControl);

  // ACTION & API

  // Checker cancellation
  const cancelHandler = () => {
    itemChecker.cancel();
  };

  // Remove request
  const confirmRemoveHandler = () => {
    Alert.alert(
      t('fe.listCheckerHeader.multidelete.confirm.title'),
      t('fe.listCheckerHeader.multidelete.confirm.description', {total: itemChecker.count()}), 
      [{
        text: t('fe.confirm.button'),
        onPress: () => {
          applyRemoveHandler(itemChecker.list());
        },
        style: 'confirm',
      }, {
        text: t('fe.cancel.button'),
        style: 'cancel',
      }]
    );
  };

  // Confirm Remove
  const applyRemoveHandler = async uuids => {
    dispatch(loaderStart());

    const response = await CollectionAPI.batchDeleteCollectionItem(uuids);

    if (response?.status) {
      Alert.notify(
        t('fe.listCheckerHeader.multidelete.alert.success.title'),
        t('fe.listCheckerHeader.multidelete.alert.success.description'),
        'info',
      );

      dispatch(collectionBatchDeleteItem(response?.payload));
    }

    dispatch(loaderEnd());
  };

  return (
    itemChecker.count() > 0 && (
      <div className="pos-fixed t-60 l-0 r-0 bg-black" style={{...style, zIndex: 1001}}>
        <div className="container">
          <div className="row">
            <div className="col">
              <div className="br-pagetitle pd-x-15">
                {titleComponent}
                <div className="btn-group pos-absolute r-15">
                  <span className="btn btn-warning btn-icon rounded-circle mg-r-5 mg-b-10">
                    <div>{itemChecker.count()}</div>
                  </span>
                  <button type="button" className="btn btn-outline-light rounded-0" onClick={() => confirmRemoveHandler()}>
                    <i className="fa fa-trash"></i>
                  </button>
                  <button type="button" className="btn btn-outline-light rounded-0" onClick={() => cancelHandler()}>
                    <i className="fa fa-times"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  );
});

export default ScrollListCheckerHeader;
