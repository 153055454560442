/* eslint-disable react-hooks/exhaustive-deps */
import {useDispatch, useSelector} from 'react-redux';
import { useTranslation } from 'react-i18next';
import Alert from '@crosslud/helpers/Alert';
import { useNavigate } from 'react-router';
import { loaderEnd, loaderStart } from '@crosslud/redux/Loader/loader-actions';
import { collectionAddItem, collectionDeleteItem } from '@crosslud/redux/CollectionItem/collectionItem-actions';
import {
  COLLECTION_ADD_ITEM,
  COLLECTION_UPDATE,
} from '@crosslud/redux/CollectionItem/collectionItem-types';
import { ITEM_UPDATE } from '@crosslud/redux/Item/item-types';
import { CollectionAPI } from '@crosslud/apis/CollectionAPI';
import { useEffect } from 'react';

const useItemAction = (entity, setEntity, collectionEntity, setCollectionEntity) => {
  const {t} = useTranslation();

  const navigate = useNavigate();

  const itemState = useSelector(state => {
    return state.item;
  });

  const collectionItemState = useSelector(state => {
    return state.collectionItem;
  });

  // DISPACTH
  const dispatch = useDispatch();

  // Go authentication ok
  const goToAuhentication = () => {
    Alert.alert(
      t('fe.authentication.confim.title'), 
      t('fe.authentication.confim.description'),
      [
        {
          text: t('fe.confirm.button'),
          onPress: () => {
            navigate('/security/sign-in');
          },
          style: 'confirm',
        },
        {
          text: t('fe.cancel.button'),
          style: 'cancel',
        },
      ],
    );
  };

  // Add to collection
  const collectionAddItemHandler = id => {
    const alertMessage =
      entity?.quantityInUserCollection > 0
        ? t('fe.itemHelper.collectionAdd.confim.description.already.in', { quantity: entity?.quantityInUserCollection }) 
        : t('fe.itemHelper.collectionAdd.confim.description');

    Alert.alert(t('fe.itemHelper.collectionAdd.confim.title'), alertMessage, 
      [
        {
          text: t('fe.confirm.button'),
          onPress: () => {
            confirmCollectionAddItemHandler(id);
          },
          style: 'confirm',
        },
        {
          text: t('fe.cancel.button'),
          style: 'cancel',
        },
    ]);
  };

  // Confirm add to collection
  const confirmCollectionAddItemHandler = async id => {
    dispatch(loaderStart());

    var response = await CollectionAPI.addItem(id);

    if (response?.status) {
      // Only update collection entity with new data
      // when we are in a collection
      if (collectionEntity) {
        setCollectionEntity(response?.payload);
      }
      
      setEntity(response?.payload?.item);

      Alert.notify(
        t('fe.itemHelper.collectionAdd.alert.success.title'),
        t('fe.itemHelper.collectionAdd.alert.success.description'),
        'success',
      );

      dispatch(collectionAddItem(response?.payload));
    }

    dispatch(loaderEnd());
  };

  // Delete from collection
  const collectionDeleteItemHandler = id => {
    Alert.alert(
      t('fe.itemHelper.collectionDelete.confirm.title'),
      t('fe.itemHelper.collectionDelete.confirm.description'),
      [
        {
          text: t('fe.confirm.button'),
          onPress: () => {
            confirmCollectionDeleteItemHandler(id);
          },
          style: 'confirm',
        },
        {
          text: t('fe.cancel.button'),
          style: 'cancel',
        },
      ],
    );
  };

  // Confirm delete from collection
  const confirmCollectionDeleteItemHandler = async id => {
    dispatch(loaderStart());

    const response = await CollectionAPI.deleteCollectionItem(id);

    if (response.status) {
      Alert.notify(
        t('fe.itemHelper.collectionDelete.alert.info.title'),
        t('fe.itemHelper.collectionDelete.alert.info.description'),
        'info',
      );

      dispatch(collectionDeleteItem(collectionEntity));
    }

    dispatch(loaderEnd());
  };

  // Item STATE
  useEffect(() => {
    // UPDATE
    if (
      entity &&
      itemState.action === ITEM_UPDATE &&
      itemState?.item?.uuid === entity.uuid
    ) {
      setEntity(itemState?.item);
    }
  }, [itemState]);

  // ItemCollection STATE
  useEffect(() => {
    // Collection ADD
    if (
      entity &&
      collectionItemState?.action === COLLECTION_ADD_ITEM &&
      collectionItemState?.collectionItem?.item?.uuid === entity.uuid
    ) {
      setEntity(collectionItemState.collectionItem.item);
    }

    // Collection UPDATE
    if (
      collectionEntity &&
      collectionItemState.action === COLLECTION_UPDATE &&
      collectionItemState?.collectionItem?.uuid === collectionEntity.uuid
    ) {
      setCollectionEntity(collectionItemState.collectionItem);
    }
  }, [collectionItemState]);

  return {
    collectionAddItemHandler: collectionAddItemHandler,
    collectionDeleteItemHandler: collectionDeleteItemHandler,
    goToAuhentication: goToAuhentication
  };
};

export default useItemAction;
