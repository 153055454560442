const useMercure = () => {
  const MERCURE_HUB = process.env.EXPO_PUBLIC_MERCURE_HUB ?? process.env.REACT_APP_MERCURE_HUB;

  var topic = (data) => {
    return new EventSource(MERCURE_HUB + '?topic=' + encodeURIComponent(data));
  };

  return {
    topic: topic,
  };
};

export default useMercure;
