import {api} from './configs/fetchConfig';

export const CategoryAPI = {
  /**
   * Get categories
   * @returns 
   */
  getAll: async () => {
    return await api.fetchResource('/api/categories.json');
  },
  /**
   * Get category
   * @param {*} id 
   * @returns 
   */
  get: async id => {
    return await api.fetchResource('/api/categories/' + id + '.json');
  },
  /**
   * Get Protected categories
   * @returns 
   */
  getAllProtected: async () => {
    return await api.fetchResource('/api/protected/categories.json');
  },
  /**
   * Get user categories
   * @param {*} user 
   * @returns 
   */
  getUserCollections: async (user) => {
    var headers = new Headers();
    headers.set('Accept', 'application/ld+json');

    return await api.fetchResource('/api/users/' + user + '/collections.jsonld', {
      method: 'GET',
      headers: headers,
    });
  }
};
