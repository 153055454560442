import {Outlet, useLocation, useNavigate} from "react-router-dom";
import { Modal } from "@crosslud/components";
import { useEffect, useState } from "react";

const ModalLayout = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [webViewHeader, setWebViewHeader] = useState('');

  const handleModalClose = () => {
    return navigate(location?.state?.backgroundLocation || '/');
  }

  useEffect(() => {
    // Access the injected header from the URL query parameters
    const params = new URLSearchParams(window.location.search);
    const webViewHeaderValue = params.get('X-WebView');
    
    if (webViewHeaderValue) {
      setWebViewHeader(webViewHeaderValue);
    }
  }, []);

  return (
    <Modal 
      show={true} 
      fullscreen={true} 
      centered={false} 
      handleClose={() => handleModalClose()}
      contentClassName={'col'}
      closeButton={!webViewHeader ? true : false}>
      <Outlet />
    </Modal>
  );
};

export default ModalLayout;