import logo from '@crosslud/crosslud.png';
import View from '@crosslud/components/View/View';

const Splash = () => {
  return (
    <View className="pos-fixed ht-100p justify-content-center d-flex align-items-center t-0 b-0 l-0 r-0">
      <div className="loader-rotating-plane">
        <img src={logo} className="ht-50" alt={logo} />
      </div>
    </View>
  );
};

export default Splash;
