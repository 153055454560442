import { api } from "./configs/fetchConfig";
import { fetchUtils } from "./configs/fecthUtils";
import { CommandExecutionSchema } from "./configs/schema";

export const CommandAPI = {
  getAll: async (filters = [], page = 1) => {
    const newFilters = filters.concat('page=' + page);

    var headers = new Headers();
    headers.set('Accept', 'application/ld+json');
    
    return await api.fetchResource('/api/command-executions.jsonld?' + newFilters.join('&'), {
      method: 'GET',
      headers: headers,
    });
  },
  get: async id => {
    var headers = new Headers();
    headers.set('Accept', 'application/json');

    return await api.fetchResource('/api/command-executions/' + id + 'json', {
      method: 'GET',
      headers: headers,
    });
  },
  post: async data => {
    return await api.fetchResource('/api/command-executions', {
      method: 'POST',
      body: JSON.stringify(fetchUtils.bodyFormat(data, CommandExecutionSchema)),
    });
  },
  getExecutables: async (filters = [], page = 1) => {
    const newFilters = filters.concat('page=' + page);

    var headers = new Headers();
    headers.set('Accept', 'application/ld+json');
    
    return await api.fetchResource('/api/command-executables.jsonld?' + newFilters.join('&'), {
      method: 'GET',
      headers: headers,
    });
  },
}

