/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from 'react';
import {Link, useLocation, useNavigate} from 'react-router-dom';
import {Loader, ProtectedScreen} from '@crosslud/components';
import { UserAPI } from '@crosslud/apis/UserAPI';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Alert from '@crosslud/helpers/Alert';
import useActivity from '@crosslud/hooks/useActivity';

const Password = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const {t} = useTranslation();
  const activityHook = useActivity(false);
  const [isNewUser, setIsNewUser] = useState();
  const [violations, setViolations] = useState([]);

  // FORM
  const {
    control,
    getValues,
    handleSubmit,
  } = useForm({
    defaultValues: {
      nickname: "",
      password: "",
      rePassword: "",
      email: location?.state?.email,
      token: location?.state?.token,
    }
  });

  const onSubmit = async formData => {
    var activityRef = activityHook.start();

    var response = null;
    
    if (isNewUser) {
      response = await UserAPI.registration(formData);
    } else {
      response = await UserAPI.changePassword(formData);
    }

    if (response?.status) {
      if (isNewUser) {
        Alert.notify(
          t('fe.password.newUser.form.onSubmit.alert.success.title'),
          t('fe.password.newUser.form.onSubmit.alert.success.description'),
          'success',
        );
      } else {
        Alert.notify(
          t('fe.password.changePassword.form.onSubmit.alert.success.title'),
          t('fe.password.changePassword.form.onSubmit.alert.success.description'),
          'success',
        );
      }

      navigate('/security/sign-in', {replace: true});
    }

    // Violations
    setViolations(response?.violations);

    activityHook.stop(activityRef);
  }

  useEffect(() => {
    location?.state?.isNewUser && setIsNewUser(location.state.isNewUser);
  }, [location?.state?.isNewUser]);

  return (
    activityHook.isLoading ? <Loader /> : (
    <ProtectedScreen deniedWhen={!getValues().email || !getValues().token}>
      <div className="br-pagebody">
        <div className="row no-gutters" /* flex-row-reverse */>
          <div className="offset-md-3 col-md-6 bg-br-primary d-flex align-items-center justify-content-center">
            <form method='post'>
              <div className="login-wrapper wd-250 wd-xl-350 mg-y-30">
                <h3 className="tx-white tx-center mg-b-30">
                  {t('fe.password.title')}
                </h3>
                {violations?.error?.message && 
                  <p className='text-center text-danger'>
                    {violations?.error?.message}
                  </p>
                }
                {isNewUser && (
                  <div className="form-group">
                    <Controller
                      name="nickname"
                      control={control}
                      render={({ field }) => 
                        <input
                          {...field}
                          className="form-control form-control-dark" 
                          type="text"
                          placeholder={t('fe.password.form.nickname.placeholder')}
                        />
                      }
                    />
                    {violations?.nickname?.message && <span className='text-danger'>{violations?.nickname?.message}</span>}
                  </div>
                )}
                <div className="form-group">
                  <Controller
                    name="password"
                    control={control}
                    render={({ field }) => 
                      <input
                        {...field}
                        className="form-control form-control-dark" 
                        type="password"
                        placeholder={t('fe.password.form.password.placeholder')}
                      />
                    }
                  />
                  {violations?.password?.message && <span className='text-danger'>{violations?.password?.message}</span>}
                </div>
                <div className="form-group">
                  <Controller
                    name="rePassword"
                    control={control}
                    render={({ field }) => 
                      <input
                        {...field}
                        className="form-control form-control-dark" 
                        type="password"
                        placeholder={t('fe.password.form.repeatPassword.placeholder')}
                      />
                    }
                  />
                  {violations?.rePassword?.message && <span className='text-danger'>{violations?.rePassword?.message}</span>}
                </div>
                <button type="submit" className="btn btn-info btn-block" onClick={handleSubmit(onSubmit)}>{t('fe.forgottenPassword.password.form.submit.button')}</button>
                <div className="mg-t-30 tx-center">
                  <Link to={'/security/sign-in'} className="tx-info">
                    {t('fe.authentication.already.member.question')}
                  </Link>
                </div>
              </div>
            </form>
          </div>
          {/*
          <div className="col-md-6 bg-primary d-flex align-items-center justify-content-center">
            <div className="wd-250 wd-xl-450 mg-y-30">
              <div className="signin-logo tx-28 tx-bold tx-white"><span className="tx-normal">[</span> Cross<span className="tx-white-8">Lud</span> <span className="tx-normal">]</span></div>
              <div className="tx-white-8 mg-b-60">Collections For Perfectionist</div>

              <h5 className="tx-white">Why crosslud?</h5>
              <p className="tx-white-6">When it comes to websites or apps, one of the first impression you consider is the design. It needs to be high quality enough otherwise you will lose potential users due to bad design.</p>
              <p className="tx-white-6 mg-b-60">When your website or app is attractive to use, your users will not simply be using it, they’ll look forward to using it. This means that you should fashion the look and feel of your interface for your users.</p>

              <a href="" className="btn btn-outline-white pd-x-25 tx-uppercase tx-12 tx-spacing-2 tx-medium">Learn more</a>
            </div> 
          </div>
          */}
        </div>
      </div>
    </ProtectedScreen>
  ))
}

export default Password;