/* eslint-disable react-hooks/exhaustive-deps */
import {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {useDispatch, useSelector} from 'react-redux';
import {loaderStart, loaderEnd} from '@crosslud/redux/Loader/loader-actions';
import {Loader, FormInputTree, Modal, ProtectedScreen, CategoryBreadcrumbText, FormInputTag, FormInputNode} from '@crosslud/components';
import useItemLicenceForm from "@crosslud/hooks/useItemLicenceForm";
import {Controller, useForm} from "react-hook-form";
import useModalNavigate from "@crosslud/hooks/useModalNavigate";
import { ItemAPI } from "@crosslud/apis/ItemAPI";
import { CategoryAPI } from "@crosslud/apis/CategoryAPI";
import useActivity from "@crosslud/hooks/useActivity";
import Alert from "@crosslud/helpers/Alert";
import { LicenceAPI } from "@crosslud/apis/LicenceAPI";
import { CommonAPI } from "@crosslud/apis/CommonAPI";

const ItemHeaderEdit = ({isComposition}) => {
  // PARAMS
  const {id} = useParams();

  const dispatch = useDispatch();
  const modalNavigate = useModalNavigate();
  const activityHook = useActivity();

  const [isEditable, setIsEditable] = useState(null);
  const [entity, setEntity] = useState();
  const [categories, setCategories] = useState();
  const [xContainers, setXContainers] = useState();
  const [modalVisible, setModalVisible] = useState(false);
  const [violations, setViolations] = useState([]);

  const itemLicenceForm = useItemLicenceForm();

  // SELECTOR
  const auth = useSelector(state => {
    return state.auth;
  });

  // FORM
  const {
    control,
    handleSubmit,
    getValues,
    setValue,
    watch,
  } = useForm({
    defaultValues: {
      barcode: itemLicenceForm.getBarcode(),
    }
  });

  // Default values
  // "watch" should everytime be declared before "setValue"
  watch('category', null);
  watch('xContainer', null);
  watch('itemGroup', '');

  // ACTION
  const fetchBarcodeInfo = async () => {
    // On create
    if (!id) {
      const response = await ItemAPI.getBarcodeInfo(getValues().barcode, 1);

      if (response?.status) {
        setIsEditable(response?.payload?.isCreatable);
      }
    } else {
      // On update
      setIsEditable(true);
    }
  };

  const fetchProtectedItem = async id => {
    const activityRef = activityHook.start();

    const response = await ItemAPI.getProtected(id);

    if (response.status) {
      setEntity(response?.payload);
    }

    activityHook.stop(activityRef);
  };

  // Fetch category
  const fetchCategories = async () => {
    const activityRef = activityHook.start();

    const response = await CategoryAPI.getAllProtected();

    if (response?.status) {
      setCategories(response?.payload);
    }

    activityHook.stop(activityRef);
  };

  // Fetch category container
  const fetchCategoryXContainers = async categoryId => {
    const response = await CommonAPI.getXContainers(categoryId);

    if (response.status) {
      setXContainers(response?.payload);
    }
  };

  const onSubmit = async formData => {
    dispatch(loaderStart());

    // Post new half filled item
    var data = {
      xContainer: formData?.xContainer,
      itemGroup: formData?.itemGroup,
    };

    var response = null;

    if (isComposition) {
      data.parent = entity.uuid
    }

    if (entity?.uuid && !isComposition) {
      response = await ItemAPI.updateHeader(entity.uuid, data);
    } else {
      data.barcode = formData.barcode;

      response = await ItemAPI.create(data);
    }

    if (response?.status) {
      itemLicenceForm.clean();

      modalNavigate('/item-modal/edit-details/item/' + response?.payload?.uuid);

      Alert.notify(
        'Well done! ',
        'Vos modifications ont été enregistrées.',
        'success',
      );
    }

    // Violations
    setViolations(response?.violations);

    dispatch(loaderEnd());
  }

  // ACTION
  /*
  const handleSelected = (item, ) => {
    setLicenceModalVisible(true);

    var activityRef = activityHook.start();

    const response = await LicenceAPI.get(licenceId);

    if (response?.status) {
      setSelectedLicence(response.payload);
    }

    activityHook.stop(activityRef);
  }*/

  // EFFECT

  // Load item if exists
  useEffect(() => {
    auth?.isLoggedIn && id && fetchProtectedItem(id);
  }, [id]);

  // Load default values from entity when exists
  useEffect(() => {
    if (!entity)
      return;
    
    setValue('barcode', entity?.barcode);
    setValue('category', entity?.xContainer?.category);
    setValue('xContainer', entity?.xContainer?.id);
    entity?.itemGroup && setValue('itemGroup', [entity?.itemGroup]);
  }, [entity]);

  // Load categories
  useEffect(() => {
    fetchBarcodeInfo();
    fetchCategories();
  }, []);

  // On category change
  // Reload container
  useEffect(() => {
    getValues()?.category?.id && fetchCategoryXContainers(getValues()?.category?.id);
  }, [getValues()?.category]);

  return (
    (activityHook.isLoading || isEditable === null) ? <Loader /> : (
      <ProtectedScreen deniedWhen={!isEditable || (!id && !getValues()?.barcode)}>
        <div className='container'>
          <div className='row'>
            <div className="col">
              <div className="br-pagetitle mg-b-20-force pd-x-15 bg-black-1">
                <i className="icon ion-ios-search"></i>
                <div>
                  <h4>Item Licence {isComposition && '- Composition'}</h4>
                </div>
                {/*
                <div className="btn-group pos-absolute r-30">
                  <button type="button" className="btn btn-outline-light rounded-0"><i className="fa fa-th"></i></button>
                  <button type="button" className="btn btn-outline-light rounded-0"><i className="fa fa-th-list"></i></button>
                </div>
                */}
              </div>
              <div className="row justify-content-center">
                <div className="col-12">
                  <div className="br-block-wrapper pd-20">
                    <div>
                      {entity?.fullName && 
                      <table className="table table-bordered table-colored">
                        <tbody className="bg-black-1">
                          <tr>
                            <td>
                              <span className="tx-16 tx-bold">
                                {entity?.fullPackName || entity.fullName}
                                {isComposition && (<span> | <span className="tx-success">New composition</span></span>)}
                              </span>
                            </td>
                          </tr>
                        </tbody>
                      </table>}
                      {violations?.error && <div className="pd-b-20 text-center tx-16"><span className='text-danger'>{violations?.error.message}</span></div>}
                      <form onSubmit={handleSubmit(onSubmit)} className="form-layout form-layout-2">
                        <table className="table table-bordered table-colored">
                          <tbody className="bg-black-1">
                            <tr>
                              <td className="wd-20p bg-dark">
                                <span className="tx-white tx-14">Barcode <span className="tx-danger">*</span></span>
                              </td>
                              <td className="tx-14">{getValues().barcode}</td>
                            </tr>
                            <tr>
                              <td className="wd-20p bg-dark">
                                <span className="tx-white tx-14">Category <span className="tx-danger">*</span></span>
                              </td>
                              <td className="tx-14">
                                {getValues()?.category && (
                                  <span className="mg-r-10">
                                    <CategoryBreadcrumbText category={getValues().category} />
                                  </span>
                                )}
                                <li className="btn btn-outline-success pd-x-20" onClick={() => setModalVisible(true)}>
                                  Selectionner / Modifier une catégorie
                                </li>
                              </td>
                            </tr>
                            <tr>
                              <td className="wd-20p bg-dark">
                                <span className="tx-white tx-14">Container <span className="tx-danger">*</span></span>
                              </td>
                              <td className="tx-14">
                                {(!xContainers || xContainers?.lenght <= 0) ? "Please select a category" :
                                <div className="row">
                                  <Controller
                                    control={control}
                                    name="xContainer"
                                    render={({ field }) => (
                                      xContainers?.map((xContainer) => {
                                        return (
                                          <div key={xContainer?.id} className="col-sm-4">
                                            <div className="card bd-0 mb-4">
                                              <div className="card-header bg-primary text-white">
                                                <label>
                                                  <input
                                                    {...field}
                                                    type="radio"
                                                    required="required"
                                                    value={xContainer?.id}
                                                    defaultChecked={parseInt(xContainer?.id) === parseInt(field.value)}
                                                  />
                                                  <span className="mg-l-10 mg-y-0">{xContainer?.container?.translatedName}</span>
                                                </label>
                                              </div>
                                              <div className="card-body bd bd-t-0 rounded-bottom">
                                                Some quick example text to build on the card title and make up the bulk of the card's content. Lorem ipsum dolor sit amet consictetur...
                                                <img src="/gg/images/icon-mouse.svg" alt="" />
                                              </div>
                                            </div>
                                          </div>
                                        )
                                      })
                                    )}
                                  />
                                </div>}
                              </td>
                            </tr>
                            <tr>
                              <td className="wd-20p bg-dark">
                                <span className="tx-white tx-14">Licence <span className="tx-danger">*</span></span>
                              </td>
                              <td className="tx-14">
                                {!getValues()?.category ? "Please select a category" : (
                                <Controller
                                  control={control}
                                  name={'itemGroup'}
                                  render={({ field }) =>
                                    <FormInputTag
                                      {...field}
                                      itemsFromUrl={{
                                        url: LicenceAPI.getAllProtected,
                                        params: [getValues()?.category?.id, [], 1],
                                        searchingFilter: ['name'],
                                        filterIndex: 1,
                                        paginationIndex: 2,
                                      }}
                                      renderItem={(item, addTag) => (
                                        <LicenceRenderer item={item} addTag={addTag}/>
                                      )}
                                      headerComponent={() => (
                                        <CreateLicence category={getValues()?.category?.id} onFinish={(newLicence) => {
                                          //updateSearch(newLicence?.name);
                                        }}/>
                                      )}
                                      labelSelector={'completeName'}
                                      idSelector={'uuid'}
                                      multiple={false}
                                    />
                                  }
                                />
                              )}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        <div className="form-layout-footer text-right">
                          <button className="btn mg-r-10" onClick={(e) => {
                            e.preventDefault();
                            modalNavigate(-1);
                          }}>
                            <span>Cancel</span>
                            <span className="icon mg-l-5"><i className="ion-paper-airplane"></i></span>
                          </button>
                          <button className="btn btn-primary">
                            <span>Submit</span>
                            <span className="icon"><i className="ion-paper-airplane"></i></span>
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>

              <Modal 
                show={modalVisible} 
                handleClose={() => setModalVisible(false)}
                contentClassName={'offset-md-2 col-8'}
                fullscreen={true}
                closeButton={true}
              >
                <Controller
                  control={control}
                  name="category"
                  render={({ field }) => (
                    <FormInputTree
                      {...field}
                      data={categories}
                      idSelector={'id'}
                      labelSelector={'translatedName'}
                      submitOnSelect={true}
                      onChange={(selection) => {
                        setModalVisible(false);
                        field.onChange(selection);
                      }}
                    />
                  )}
                />
              </Modal>
            </div>
          </div>
        </div>
      </ProtectedScreen>
    )
  )
}

export default ItemHeaderEdit;

const LicenceRenderer = ({item, addTag}) => {
  const [modalVisible, setModalVisible] = useState(false);

  return (
    <>
      <div key={item.uuid} onClick={() => setModalVisible(true)}>
        <span style={{ fontSize: 16, fontWeight: 'bold' }}>
          {item.completeName.toString().trim()}
        </span>
      </div>

      {/* Modal */}
      <Modal
        show={modalVisible}
        handleClose={() => setModalVisible(false)}
        contentClassName={'offset-md-2 col-8'}
        fullscreen={true}
        closeButton={true}
        centered={false}
      >
        <FormInputNode
          data={item}
          itemsFromUrl={{
            url: LicenceAPI.getItemGroups,
            params: [item?.uuid, [], 1],
            paginationIndex: 2
          }}
          idSelector={'uuid'}
          labelSelector={'name'}
          submitOnSelect={true}
          onChange={(item) => {
            addTag(item);
            setModalVisible(false);
          }}
          onDestroy={() => setModalVisible(false)}
        />
      </Modal>
    </>
  )
}

const CreateLicence = ({category, onFinish}) => {
  const [modalVisible, setModalVisible] = useState(false);
  const activityHook = useActivity(false);
  const [violations, setViolations] = useState([]);

  // FORM
  const {
    control,
    handleSubmit,
  } = useForm({
    defaultValues: {
      category: category,
    }
  });

  // ACTION

  const onSubmit = async formData => {
    if (activityHook.isLoading) {
      return false;
    }

    const activityRef = activityHook.start();

    const response = await LicenceAPI.create(formData);

    if (response?.status) {
      Alert.notify(
        'Well done! ',
        'Nouvelle licence créée.',
        'success',
      );
      
      onFinish && onFinish(response?.payload);
      setModalVisible(false);
    }

    // Violations
    setViolations(response?.violations);

    activityHook.stop(activityRef);
  }

  return (
    <div className="col-12 tx-center bg-success mg-b-20">
      <span className="btn" onClick={() => setModalVisible(true)}>
        <span className="btn btn-icon mg-x-10">
          <i className="icon ion-ios-plus tx-18"></i>
        </span>
        <b>Nouvelle Licence</b>
      </span>

      {/**
       * Create licence Modal
       */}
      <Modal 
        show={modalVisible} 
        handleClose={() => setModalVisible(false)}
        contentClassName={'offset-md-2 col-8'}
        fullscreen={true}
        closeButton={true}
        centered={false}
      >
        {activityHook.isLoading && <Loader />}
        <div className='container'>
          <div className="row justify-content-center">
            <div className="col">
              <div className="br-pagetitle mg-b-20-force pd-x-15 bg-black-1">
                <i className="icon ion-ios-search"></i>
                <div>
                  <h4>Create a new Licence</h4>
                </div>
              </div>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col">
              <div className="br-block-wrapper pd-20">
                <div>
                  {violations?.error && <div className="pd-b-20 text-center tx-16"><span className='text-danger'>{violations?.error.message}</span></div>}
                  <form className="form-layout form-layout-2">
                    <table className="table table-bordered table-colored">
                      <tbody className="bg-black-1">
                        <tr>
                          <td>Licence</td>
                          <td>
                            <Controller
                              control={control}
                              name={'name'}
                              render={({ field }) => (
                                <input
                                  {...field}
                                  className="form-control tx-14"
                                  type="text"
                                  placeholder="Enter a name"
                                />
                              )}
                            />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <div className="form-layout-footer text-right">
                      <button className="btn btn-primary" onClick={handleSubmit(onSubmit)}>
                        <span>Submit</span>
                        <span className="icon"><i className="ion-paper-airplane"></i></span>
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  )
}