/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react';

const FlatList = ({
  data, 
  renderItem, 
  onEndReached, 
  //onComponentScroll, 
  ListEmptyComponent,
  ListHeaderComponent,
  ListFooterComponent, 
  className, 
  horizontal = false
}) => {
  const self = useRef();

  const [showHeader, setShowHeader] = useState(false);

  var oldScrollTop = 0;
  var scrollDirection = 'top';

  // Scroll Handler
  const scrollHandler = contentOffsetY => {
    if (contentOffsetY > oldScrollTop) {
      scrollDirection = 'bottom';
    } else {
      scrollDirection = 'top';
    }

    if (scrollDirection === 'top' && contentOffsetY > 250) {
      setShowHeader(true);
    } else {
      setShowHeader(false);
    }

    oldScrollTop = contentOffsetY;
  };
  
  // Get closest scrollable parent
  const getScrollParent = (node) => {
    const isElement = node instanceof HTMLElement;
    const overflowY = isElement && window.getComputedStyle(node).overflowY;
    const isScrollable = overflowY !== 'visible' && overflowY !== 'hidden';
  
    if (!node) {
      return null;
    } else if (isScrollable && node.scrollHeight >= node.clientHeight) {
      return node;
    }
  
    return getScrollParent(node.parentNode) || document.scrollingElement;
  }

  useEffect(() => {
    // Window scroll listener
    const handleWindowScroll = event => {
      //onComponentScroll && onComponentScroll(window.scrollY);
      scrollHandler(window.scrollY);

      var scrollMax = window?.scrollMaxY ? window.scrollMaxY : document.documentElement.scrollHeight - document.documentElement.clientHeight;
      var scrollEndReached = parseInt(window.scrollY) >= parseInt(scrollMax);
      
      if (scrollMax > 0 && scrollEndReached) {
        onEndReached();
      }
    };

    // Element scroll listener
    const handleElementScroll = event => {
      //onComponentScroll && onComponentScroll(event.currentTarget.scrollTop);
      scrollHandler(event.currentTarget.scrollTop);

      var scrollMax = event.currentTarget.scrollHeight;
      var scrollEndReached = (event.currentTarget.offsetHeight + event.currentTarget.scrollTop) === scrollMax;

      if (scrollMax > 0 && scrollEndReached) {
        onEndReached();
      }
    };

    // If closest scrollable parent 
    var closestScrollableParent = getScrollParent(self.current);
    closestScrollableParent.addEventListener('scroll', handleElementScroll);
    
    // Default scrollable window
    window.addEventListener('scroll', handleWindowScroll);

    return () => {
      window.removeEventListener('scroll', handleWindowScroll);
      closestScrollableParent.addEventListener('scroll', handleElementScroll);
    };
  }, []);

  // Memoize
  const renderVerticalItemFormat = React.useMemo(() => {
    return (
      data?.map((item, index) => {
        return item && renderItem({item, index});
      })
    )
  }, [data]);

  // Memoize
  const renderHorizontalItemFormat = React.useMemo(() => {
    return (
      data?.map((item, index) => {
        return item && 
          <div key={index} style={{display: 'inline-block'}}>
            {renderItem(item, index)}
          </div>
      })
    )
  }, [data]);

  const verticalDisplay = () => {
    return (
      <>
        {ListHeaderComponent &&
          <>
            {ListHeaderComponent}
            <div className={`pos-fixed t-35 l-0 r-0 bg-black-9 ${showHeader ? 'd-block' : 'd-none'}`} style={{zIndex: 1000}}>
              <div className="container">
                <div className="row">
                  <div className="col">
                    <div className="br-pagetitle pd-x-15">
                    {ListHeaderComponent}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        }
        <div ref={self} className={className} style={{marginTop: 20}}>
          {renderVerticalItemFormat}
          {ListEmptyComponent && data?.length === 0 && ListEmptyComponent}
          {ListFooterComponent && ListFooterComponent()}
        </div>
      </>
    )
  }

  const horizontalDisplay = () => {
    return (
      <div className={className} style={{maxWidth: '100%', whiteSpace: 'nowrap', overflowX: 'auto'}}>
        {renderHorizontalItemFormat}
        {ListEmptyComponent && data?.length === 0 && ListEmptyComponent()}
        {ListFooterComponent && ListFooterComponent()}
      </div>
    )
  }

  return (
    horizontal ? horizontalDisplay(): verticalDisplay()
  )
}

export default FlatList;