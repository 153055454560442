import { useState } from 'react';
import {Link, useNavigate} from 'react-router-dom';
import { useDispatch } from 'react-redux';
import {userLogin} from '@crosslud/redux/Auth/auth-actions';
import { CommonAPI } from '@crosslud/apis/CommonAPI';
import { UserAPI } from '@crosslud/apis/UserAPI';
import { useTranslation } from 'react-i18next';
import { Controller, useForm } from 'react-hook-form';
import useActivity from '@crosslud/hooks/useActivity';
import { FormInputText, Loader } from '@crosslud/components';

const SignIn = () => {
  const {t} = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const activityHook = useActivity(false);

  const [violations, setViolations] = useState([]);

  // FORM
  const {
    control,
    handleSubmit,
  } = useForm({
    defaultValues: {
      email: "",
      password: "",
    }
  });

  const onSubmit = async formData => {
    var activityRef = activityHook.start();

    const response = await CommonAPI.login(formData.email, formData.password);

    if (response?.status) {
      const userInfoResponse = await UserAPI.getInfo();

      if (userInfoResponse?.status) {
        dispatch(userLogin({user: userInfoResponse?.payload}));

        navigate('/');
      }
    }

    // Violations
    setViolations(response?.violations);

    activityHook.stop(activityRef);
  };

  return (
    activityHook.isLoading ? <Loader /> :
    <div className="br-pagebody">
      <div className="row no-gutters" /* flex-row-reverse */>
        <div className="offset-md-3 col-md-6 bg-br-primary d-flex align-items-center justify-content-center">
          <form>
            <div className="login-wrapper wd-250 wd-xl-350 mg-y-30">
              <h3 className="tx-white tx-center mg-b-30">
                {t('fe.signIn.title')}
              </h3>
              {violations?.authentication?.message && <p className='text-center text-danger'>{violations?.authentication?.message}</p>}
              <div className="form-group">
                <Controller
                  name="email"
                  control={control}
                  render={({ field }) => (
                    <FormInputText
                      {...field}
                      type="email"
                      className="form-control form-control-dark"
                      placeholder={t('fe.signIn.form.email.placeholder')}
                    />
                  )}
                />
              </div>
              <div className="form-group">
                <Controller
                  name="password"
                  control={control}
                  render={({ field }) => (
                    <FormInputText
                      {...field}
                      type="password"
                      className="form-control form-control-dark"
                      placeholder={t('fe.signIn.form.password.placeholder')}
                    />
                  )}
                />
                <Link to={"/security/reset-password"} className="tx-12 d-block mg-t-10 tx-info">
                  {t('fe.authentication.forgotten.password')}
                </Link>
              </div>
              <button 
                type="submit" 
                className="btn btn-info btn-block"
                onClick={handleSubmit(onSubmit)}>
                  {t('fe.authentication.signin.button')}
              </button>
              <hr />
              <div className="mg-t-30 tx-center">
                <Link to={'/security/sign-up'} className="tx-info">
                  {t('fe.authentication.not.yet.a.member.question')}
                </Link>
              </div>
            </div>
          </form>
        </div>
        {/*
        <div className="col-md-6 bg-primary d-flex align-items-center justify-content-center">
          <div className="wd-250 wd-xl-450 mg-y-30">
            <p className="tx-white-6">{t('fe.authentication.welcome.description')}</p>
          </div> 
        </div>
        */}
      </div>
    </div>
  )
}

export default SignIn;