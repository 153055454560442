import {fetchUtils} from './configs/fecthUtils';
import {api} from './configs/fetchConfig';
import { notificationSchema } from './configs/schema';

export const NotificationAPI = {
  /**
   * Get notification
   * @param {*} id 
   * @returns 
   */
  get: async id => {
    var headers = new Headers();
    headers.set('Accept', 'application/ld+json');

    return await api.fetchResource('/api/notifications/' + id + '.jsonld', {
      method: 'GET',
      headers: headers,
    });
  },
  /**
   * Get notifications
   * @returns 
   */
  getAll: async (filters = {}, page = 1) => {
    let query = fetchUtils
      .customQueryFilterFormat(filters, notificationSchema)
      .join('&');

    let q = '?page=' + page + (query && '&' + query);

    var headers = new Headers();
    headers.set('Accept', 'application/ld+json');

    return await api.fetchResource(
      '/api/notifications.jsonld' + q, 
      {
        method: 'GET',
        headers: headers,
      }
    );
  },
  /**
   * Get Lastest notifications
   * @returns 
   */
  getLatest: async () => {
    var headers = new Headers();
    headers.set('Accept', 'application/ld+json');

    return await api.fetchResource('/api/notifications/latest/list.jsonld', {
      method: 'GET',
      headers: headers,
    });
  },
  /**
   * Get unread
   * @returns 
   */
  getUnreadList: async () => {
    var headers = new Headers();
    headers.set('Accept', 'application/ld+json');

    return await api.fetchResource('/api/notifications/unread/list.jsonld', {
      method: 'GET',
      headers: headers,
    });
  },
  /**
   * Update notification
   * @param {*} id 
   * @param {*} data 
   * @returns 
   */
  update: async (id, data) => {
    return await api.fetchResource('/api/notifications/' + id, {
      method: 'PUT',
      body: JSON.stringify(fetchUtils.bodyFormat(data, notificationSchema)),
    });
  },
};
