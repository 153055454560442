import React, {useState} from 'react';
import {Link, useNavigate} from 'react-router-dom';
import { UserAPI } from '@crosslud/apis/UserAPI';
import { useTranslation } from 'react-i18next';
import useActivity from '@crosslud/hooks/useActivity';
import { Loader } from '@crosslud/components';

const ResetPasswordRequest = () => {
  const {t} = useTranslation();
  const activityHook = useActivity(false);
  const [email, setEmail] = useState("");
  const [violations, setViolations] = useState([]);

  const navigate = useNavigate();

  const submit = async (event) => {
    event.preventDefault();

    var activityRef = activityHook.start();

    var data = {};
    data.email = email;

    const response = await UserAPI.forgottenPassword(data);

    if (response?.status) {
      navigate('/security/verification', {state: {email: response?.payload?.email}});
    }

    // Violations
    setViolations(response?.violations);

    activityHook.stop(activityRef);
  };

  return (
    activityHook.isLoading ? <Loader /> :
    <div className="br-pagebody">
      <div className="row no-gutters" /* flex-row-reverse */>
        <div className="offset-md-3 col-md-6 bg-br-primary d-flex align-items-center justify-content-center">
          <form method="post" onSubmit={submit}>
            <div className="login-wrapper wd-250 wd-xl-350 mg-y-30">
              <h3 className="tx-white tx-center mg-b-30">
                {t('fe.forgotten.title')}
              </h3>
              <div className="form-group">
                <input 
                  type="email" 
                  name="email" 
                  className="form-control form-control-dark" 
                  placeholder={t('fe.signUp.form.email.placeholder')}
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
              <button type="submit" className="btn btn-info btn-block">
                {t('fe.authentication.forgotten.button')}
              </button>
              <div className="mg-t-30 tx-center">
                <Link to={'/security/sign-up'} className="tx-info">
                  {t('fe.authentication.not.yet.a.member.question')}
                </Link>
              </div>
            </div>
          </form>
        </div>
        {/*
        <div className="col-md-6 bg-primary d-flex align-items-center justify-content-center">
          <div className="wd-250 wd-xl-450 mg-y-30">
            <div className="signin-logo tx-28 tx-bold tx-white"><span className="tx-normal">[</span> Cross<span className="tx-white-8">Lud</span> <span className="tx-normal">]</span></div>
            <div className="tx-white-8 mg-b-60">Collections For Perfectionist</div>

            <h5 className="tx-white">Why crosslud?</h5>
            <p className="tx-white-6">When it comes to websites or apps, one of the first impression you consider is the design. It needs to be high quality enough otherwise you will lose potential users due to bad design.</p>
            <p className="tx-white-6 mg-b-60">When your website or app is attractive to use, your users will not simply be using it, they’ll look forward to using it. This means that you should fashion the look and feel of your interface for your users.</p>

            <a href="" className="btn btn-outline-white pd-x-25 tx-uppercase tx-12 tx-spacing-2 tx-medium">Learn more</a>
          </div> 
        </div>
        */}
      </div>
    </div>
  )
}

export default ResetPasswordRequest;