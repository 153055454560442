/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
import React, {useEffect, useState} from 'react';
import {CategoryBreadcrumbText, Loader, ModalLink, Paginator } from '@crosslud/components';
import useActivity from '@crosslud/hooks/useActivity';
import { ItemAPI } from '@crosslud/apis/ItemAPI';
import useCustomSearchParams from '@crosslud/hooks/useCustomSearchParams';
import useActiveLink from '@crosslud/hooks/useActiveLink';

const AdminVerificationItems = () => {
  // SET ACTIVELINK
  useActiveLink('adminLayout', 'verification');

  // HOOK
  const activityHook = useActivity(false);

  const [searchParams] = useCustomSearchParams();
  const [items, setItems] = useState();
  const [totalItems, setTotalItems] = useState();

  const fetchItems = async (filters = [], page = 1) => {
    var activityRef = activityHook.start();

    const response = await ItemAPI.getVerificationItems(filters, page);

    if (response?.status) {
      setItems(response?.payload);
      setTotalItems(response?.totalItems);
    }

    activityHook.stop(activityRef);
  };
  
  // Load users requests
  useEffect(() => {
    searchParams && fetchItems([], searchParams?.get('page') ?? 1);
  }, [searchParams]);

  return (
    <div className='container'>
      <div className='row'>
        <div className='col'>
          <section className='bg-black-1'>
            {activityHook.isLoading ? <Loader centerScreen={false} /> :
              <div className="ht-100p">
                <div className='row'>
                  <div className='col-12 mg-b-10'>
                    <div className="br-pagetitle pd-x-15 bg-black-1">
                      <i className="icon ion-ios-search"></i>
                      <div>
                        <h4>Items</h4>
                        Verification
                        {totalItems > 0 && <span> {`- ${totalItems} item(s)`}</span>}
                      </div>
                    </div>
                  </div>
                </div>
                <div className='row'>
                  <div className='col-12'>
                    <div className='bg-black-1 pd-20'>
                      <div className="row">
                        <div className="col-12">
                        {items?.length > 0 ? 
                          <>
                            <table className="table table-responsive mg-b-0 tx-12">
                              <thead>
                                <tr className="tx-10">
                                  <th className="wd-5p pd-y-5">&nbsp;</th>
                                  <th className="wd-60p pd-y-5">Name</th>
                                  <th className="wd-30p pd-y-5">Category</th>
                                  <th className="pd-y-5 tx-center">Actions</th>
                                </tr>
                              </thead>
                              <tbody>
                                {items.map((item, index) => {
                                  return (
                                    <tr key={index}>
                                      <td className="valign-middle tx-left">
                                        <span className="tx-12"><span className="square-10 mg-r-5 rounded-circle" style={{backgroundColor: item?.status?.color ?? '#ffffff'}}></span></span>
                                      </td>
                                      <td className='valign-middle'>
                                        {item.name}
                                        {item?.number?.reference && (
                                          <span style={{color: '#f49917'}}>
                                            {" | "}{item?.number?.reference} {item?.numberText}
                                          </span>
                                        )}
                                      </td>
                                      <td className='valign-middle'>
                                        <CategoryBreadcrumbText category={item?.xContainer?.category} />
                                      </td>
                                      <td className="valign-middle tx-center">
                                        <ModalLink to={`/item-modal/item/details/${item?.uuid}`} className="tx-gray-600 tx-24">
                                          <i className="icon ion-android-more-horizontal"></i>
                                        </ModalLink>
                                      </td>
                                    </tr>
                                  );
                                })}
                              </tbody>
                            </table>
                            {totalItems && <Paginator totalItems={totalItems}/>}
                          </>
                        :
                          <div className="bd bd-white-1 ht-100 d-flex align-items-center justify-content-center">
                            <h6 className="tx-uppercase mg-b-0 tx-roboto tx-normal tx-spacing-2">No Items found</h6>
                          </div>
                        }
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            }
          </section>
        </div>
      </div>
    </div>
  )
}

export default AdminVerificationItems;