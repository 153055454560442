import React from 'react';
import {Link} from 'react-router-dom';
import Dropdown from 'react-bootstrap/Dropdown';
import useActiveLink from '@crosslud/hooks/useActiveLink';

const NiceDropdown = ({data, scope = 'default'}) => {
  const activeLink = useActiveLink(scope);

  return (
    <div>
      <ul className="d-none d-lg-block nav nav-effect nav-effect-4 flex-column" role="tablist">
        {data.map((menu, index) => {
          return menu.enable && <li key={index} className="nav-item mg-x-0-force"><Link className={`nav-link pd-y-12 wd-100p-force ${activeLink.getIdentifier() === menu?.identifier && 'active'}`} to={menu.pathname}>{menu.label}</Link></li>
        })}
      </ul>
      <div className="d-lg-none">
        <Dropdown className="dropdown">
          <Dropdown.Toggle as={"div"} bsPrefix="p-0">
            <span className="nav nav-effect nav-effect-4 flex-column" role="tablist">
            {data.map((menu, index) => {
              return (
                menu.enable &&
                activeLink.getIdentifier() === menu?.identifier && 
                <span key={index} className="nav-item mg-x-0-force">
                  <Link className={`nav-link pd-y-12 wd-100p-force active`} to={menu.pathname}>{menu.label}</Link>
                  <i className="fa fa-angle-down mg-l-10 pos-absolute t-15 r-20"></i>
                </span>
              )
            })}
            </span>
          </Dropdown.Toggle>
          <Dropdown.Menu className="dropdown-menu dropdown-menu-header wd-100p">
            <ul className="nav nav-effect nav-effect-4 flex-column" role="tablist">
            {data.map((menu, index) => {
              return (
                menu.enable &&
                <li key={index} className="nav-item mg-x-0-force">
                  <Link className={`nav-link pd-y-12 wd-100p-force ${activeLink.getIdentifier() === menu?.identifier && 'active'}`} to={menu.pathname}>
                    {menu.label}
                  </Link>
                </li>
              )
            })}
            </ul>
          </Dropdown.Menu>
        </Dropdown>
      </div>
    </div>
  )
}

export default NiceDropdown;