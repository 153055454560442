/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
import React from 'react';
import { ScrollList } from '@crosslud/components';
import { ScanAPI } from '@crosslud/apis/ScanAPI';
import ScanCard from '@crosslud/components/ScanCard/ScanCard';
import useActiveLink from '@crosslud/hooks/useActiveLink';
import { useTranslation } from 'react-i18next';

const CollectionScans = () => {
  // SET ACTIVELINK
  useActiveLink('collectionLayout', 'collections-scans');

  const {t} = useTranslation();

  const renderItem = ({item}) => <ScanCard key={item?.uuid} data={item} />;

  return (
    <section>
      <div className="ht-100p">
        <div className='row'>
          <div className='col-12 mg-b-10'>
            <div className="br-pagetitle pd-x-15 bg-black-1">
              <i className="icon ion-ios-search"></i>
              <div>
                <h4>{t('fe.collection.scans.section.title')}</h4>
              </div>
            </div>
          </div>
        </div>
        <ScrollList
          urlCaller={{
            url: ScanAPI.getAll,
            params: [[], 1],
            paginationIndex: 1,
          }}
          renderItem={renderItem}
          numColumns={"col-lg-2 col-md-4 col-sm-6 col-6 mb-3"}
          ListEmptyComponent={() => null}
          ListHeaderComponentStyle={[{flexGrow: 1}]}
        />
      </div>
    </section>
  )
}

export default CollectionScans;