/* eslint-disable react-hooks/exhaustive-deps */
import {memo, useEffect, useState} from 'react';
import {Link, useParams} from 'react-router-dom';
import { useSelector } from 'react-redux';
import {ItemCard, ScrollList, ScrollListHeader, ModalLink, View, EmptyContent, CheckWrapper, ProtectedScreen, Loader, ScrollListCheckerHeader} from '@crosslud/components';
import FormData from '@crosslud/helpers/FormData';
import useSearchFilter from '@crosslud/hooks/useSearchFilter';
import useActivity from '@crosslud/hooks/useActivity';
import { useTranslation } from 'react-i18next';
import { LicenceAPI } from '@crosslud/apis/LicenceAPI';
import { UserAPI } from '@crosslud/apis/UserAPI';
import { CollectionAPI } from '@crosslud/apis/CollectionAPI';
import useCollectionAction from '@crosslud/hooks/useCollectionAction';

const CollectionTitleItems = memo(() => {
  // PARAMS
  const {titleId, collectorId} = useParams();
  const checkerControlName = `collection-${collectorId}-${titleId}`;

  // TRANSLATION
  const { t } = useTranslation();

  // SELECTOR
  const collectionItemState = useSelector(state => {
    return state.collectionItem;
  });

  // HOOK
  const searchFilterHook = useSearchFilter(titleId, 'collector-title-items');
  const activityHook = useActivity();
  const collectionAction = useCollectionAction();

  const isCurrentUser = collectionAction.isCurrentUser(collectorId);

  // STATE
  const [title, setTitle] = useState();
  const [collector, setCollector] = useState();
  const [pathnamePrefix, setPathnamePrefix] = useState();

  // ACTION & API
  const fetchTitle = async id => {
    var activityRef = activityHook.start();

    const response = await LicenceAPI.get(id);

    if (response.status) {
      setTitle(response?.payload);
    }

    activityHook.stop(activityRef);
  };

  const fetchCollector = async uuid => {
    var activityRef = activityHook.start();

    const response = await UserAPI.get(uuid);

    if (response?.status) {
      setCollector(response?.payload);
    }

    activityHook.stop(activityRef);
  };

  const PageTitle = () => {
    return (
      <>
        <i className={`icon ${title?.category?.icon ? title?.category.icon : 'ion-earth'}`}></i>
        <div>
          <h4>{title?.category?.alias?.translatedName}</h4>
          <p className="mg-b-0">
            <Link
              className="tx-white-6"
              to={{
                pathname: `${pathnamePrefix}/collections`,
              }}>
              <span className='tx-success'>
                {isCurrentUser ? 
                  t('fe.collections.my.collections'):
                  t('fe.collections.collector.collections', {collector: collector})
                }
              </span>
            </Link>
            <span> - </span>
            <Link
              className="tx-white"
              to={{
                pathname: `${pathnamePrefix}/collections/${title?.category?.alias?.id}/titles`,
              }}>
              <span>{title?.category?.alias?.parent?.translatedName}</span>
              <span> - </span>
              {t('fe.collections.titles')}
            </Link>
          </p>
        </div>
      </>
    );
  }

  const renderItem = ({item}) => {
    return (
      isCurrentUser ? (
        <CheckWrapper
          key={item?.uuid}
          identifier={item?.uuid} 
          checkerControl={checkerControlName}
          multiple={true}
        >
          <ModalLink
            to={{
              pathname: `/item-modal/collection-item/details/${item?.uuid}`,
            }}>
            <ItemCard data={item?.item} collectionData={item} isList={false} />
          </ModalLink>
        </CheckWrapper>
      ) : (
        <ModalLink
          to={{
            pathname: `/item-modal/item/details/${item?.item?.uuid}`,
          }}>
          <ItemCard data={item?.item} isList={false}/>
        </ModalLink>
      )
    );
  }

  // EFFECT
  // Fetch title, collector
  useEffect(() => {
    var activityRef = activityHook.start();

    if (titleId && collectorId) {
      fetchTitle(titleId);
      fetchCollector(collectorId);
    }

    activityHook.stop(activityRef);
  }, [titleId, collectorId]);

  useEffect(() => {
    var activityRef = activityHook.start();

    setPathnamePrefix(isCurrentUser ? '' : '/collectors/' + collector?.uuid);

    activityHook.stop(activityRef);
  }, [isCurrentUser]);

  return (
    activityHook.isLoading ? <Loader /> :
    <ProtectedScreen deniedWhen={!title || !collector}>
      <div className="container">
        <div className='row mg-b-20'>
          <div className='col'>
            <h4 className='tx-uppercase'>{title?.name}</h4>
          </div>
        </div>
        <div className='row'>
          <div className='col'>
            <ScrollListHeader 
              titleComponent={<PageTitle title={title}/>}
              hook={searchFilterHook} 
              category={title?.category?.alias?.id}
              fieldType={1}
            />
            <ScrollListCheckerHeader 
              titleComponent={<PageTitle title={title}/>} 
              checkerControl={checkerControlName}
              style={{top: 112}}
            />
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-12 mg-t-20">
            <ScrollList
              urlCaller={{
                url: CollectionAPI.getCollectorTitleItems,
                params: [collector?.uuid, title?.uuid, FormData.format(searchFilterHook.getFilters(), true), 1],
                paginationIndex: 3,
              }}
              renderItem={renderItem}
              extraData={[searchFilterHook.getFilters(), title?.uuid]}
              refreshListener={[collectionItemState]}
              refreshAction={(datas, setDatas) => (
                isCurrentUser && 
                collectionAction.collectionStateHandler({
                  datas: datas, 
                  setDatas: setDatas,
                  checkerControlName: checkerControlName
                })
              )}
              numColumns={"col-lg-2 col-md-4 col-sm-6 col-6 mb-3"}
              ListEmptyComponent={
                <View className='col-12'>
                  <EmptyContent text={t('fe.no.result.description')} />
                </View>
              }
            />
          </div>
        </div>
      </div>
    </ProtectedScreen>
  )
});

export default CollectionTitleItems;
