/* eslint-disable react-hooks/exhaustive-deps */
import {CText, View} from '@crosslud/components';

const TitleCard = ({data}) => {
  return (
    <View className='cursor-pointer'>
      <View className="card tx-white tx-center bd pd-y-15">
        <CText className="card-text">{data.name}</CText>
      </View>
    </View>
  );
};

export default TitleCard;