//import { Platform } from 'react-native';
var SecureStore = null;

var isWeb = false;
var PlatformType = 'web';

if (PlatformType === 'web') {
  isWeb = true;
}

var AuthToken = (function () {
  var token = null;

  var getToken = async () => {
    return token;
  };

  var setToken = async newToken => {
    token = newToken;
  };

  var getRefreshToken = async () => {
    // get refreshToken
    var refreshToken = null;
    var credentials = isWeb ? localStorage.getItem("refreshToken") : await SecureStore?.getItemAsync('refreshToken');

    if (credentials) {
      refreshToken = credentials;
    }

    return refreshToken;
  };

  var setRefreshToken = async newRefreshToken => {
    // set refreshToken
    isWeb ? localStorage.setItem("refreshToken", newRefreshToken) : await SecureStore?.setItemAsync('refreshToken', newRefreshToken);
  };

  var unsetTokens = async () => {
    token = null;
    isWeb ? localStorage.removeItem("refreshToken") : await SecureStore?.deleteItemAsync('refreshToken');

    return true;
  };

  return {
    getToken: getToken,
    setToken: setToken,
    getRefreshToken: getRefreshToken,
    setRefreshToken: setRefreshToken,
    unsetTokens: unsetTokens,
  };
})();

export default AuthToken;
