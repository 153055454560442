import React from 'react';
import {Route, Routes, useLocation} from 'react-router-dom';
import logo from '@crosslud/crosslud.png'
import {Link, ProtectedRoute} from '@crosslud/components';
import {
  NotFound,
  SignIn, 
  SignUp,
  Verification,
  Password,
  ResetPasswordRequest,
} from '.';

const SecurityLayout = () => {
  const location = useLocation();

  return (
    <div className="wrapper">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className='d-flex justify-content-center mg-t-60'>
              <Link to={"/"}>
                <img src={logo} style={{height: '80px'}} alt="crosslud" />
              </Link>
            </div>
            <main className="br-mainpanel">
              <Routes location={location?.state?.backgroundLocation ?? location}>
                {/* Protected routes dont need authentication */}
                <Route path="" element={<ProtectedRoute secured={false} />}>
                  <Route path="sign-in" element={<SignIn />} />
                  <Route path="sign-up" element={<SignUp />} />
                  <Route path="verification" element={<Verification />} />
                  <Route path="password" element={<Password />} />
                  <Route path="reset-password" element={<ResetPasswordRequest />} />
                </Route>
                <Route path="*" element={<NotFound />} />
              </Routes>
            </main>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SecurityLayout;
