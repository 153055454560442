/* eslint-disable array-callback-return */
import React from 'react';
import useActiveLink from '@crosslud/hooks/useActiveLink';

const AdminDashboard = () => {
  // SET ACTIVELINK
  useActiveLink('adminLayout', 'dashboard');

  return (
    <div className='container'>
      <div className='row'>
        <div className='col'>
          <div className="bg-black-1 pd-30 ht-100p">
            <h3 className="tx-center pd-b-10">Preferences</h3>
            <p className="tx-center mg-b-60">Update your Preferences.</p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AdminDashboard;