/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {userLogin} from '@crosslud/redux/Auth/auth-actions';
import {loaderStart, loaderEnd} from '@crosslud/redux/Loader/loader-actions';
import { UserAPI } from '@crosslud/apis/UserAPI';
import { Controller, useForm } from 'react-hook-form';
import Alert from '@crosslud/helpers/Alert';
import { CommonAPI } from '@crosslud/apis/CommonAPI';
import { FormSelect, Loader } from '@crosslud/components';
import useActivity from '@crosslud/hooks/useActivity';
import useActiveLink from '@crosslud/hooks/useActiveLink';
import { useTranslation } from 'react-i18next';

const AccountProfile = () => {
  // SET ACTIVELINK
  useActiveLink('accountLayout', 'profile');

  const dispatch = useDispatch();
  const activityHook = useActivity();
  const {t} = useTranslation();

  const [civilities, setCivilities] = useState();
  const [countries, setCountries] = useState();
  const [violations, setViolations] = useState([]);

  const auth = useSelector(state => {
    return state.auth;
  });

  // FORM
  const {
    control,
    handleSubmit,
  } = useForm({
    defaultValues: {
      civility: auth?.user?.civility,
      country: auth?.user?.country,
      lastname: auth?.user?.lastname,
      firstname: auth?.user?.firstname,
      description: auth?.user?.description
    }
  });

  const fetchCivilities = async () => {
    var activityRef = activityHook.start();

    const response = await CommonAPI.getCivilities();

    if (response.status) {
      setCivilities(response?.payload);
    }

    activityHook.stop(activityRef);
  };

  const fetchCountries = async () => {
    var activityRef = activityHook.start();

    const response = await CommonAPI.getCountries();

    if (response.status) {
      setCountries(response?.payload);
    }

    activityHook.stop(activityRef);
  };

  const onSubmit = async formData => {
    dispatch(loaderStart());

    const response = await UserAPI.updateInfo(auth?.user?.uuid, formData);

    if (response?.status) {
      dispatch(userLogin({user: response?.payload}));

      Alert.notify(
        t('fe.account.profile.form.onSubmit.alert.success.title'),
        t('fe.account.profile.form.onSubmit.alert.success.description'),
        'success',
      );
    }

    // Violations
    setViolations(response?.violations);

    dispatch(loaderEnd());
  }

  useEffect(() => {
    fetchCivilities();
    fetchCountries();
  }, []);

  return (
    <section className="bg-black-1 pd-30 ht-100p">
      <h3 className="tx-center pd-b-10">{t('fe.account.profile.form.title')}</h3>
      <p className="tx-center">{t('fe.account.profile.form.sub.title')}</p>
      {activityHook.isLoading ? <Loader centerScreen={false} /> :
      <form className="form-layout form-layout-2 mg-t-60">
        <div className="row no-gutters">
          <div className="col-md-4">
            <div className="form-group">
              <label className="form-control-label">
                {t('fe.account.profile.form.gender.label')}: 
                <span className="tx-danger">*</span>
              </label>
              <Controller
                control={control}
                name="civility"
                render={({ field }) => (
                  <FormSelect
                    {...field}
                    items={civilities}
                    placeholder={t('fe.account.profile.form.gender.placeholder')}
                    labelselector={'translatedName'}
                    idselector={'id'}
                  />
                )}
              />
              {/* 
              <select className="form-control" name="gender" >
                <option>Select your gender</option>
                <option>Male</option>
                <option>Female</option>
                <option>Other</option>
              </select>
              */}
            </div>
          </div>
          <div className="col-md-4 mg-t--1 mg-md-t-0">
            <div className="form-group mg-md-l--1">
              <label className="form-control-label">{t('fe.account.profile.form.lastname.label')}: <span className="tx-danger">*</span></label>
              <Controller
                name="lastname"
                control={control}
                render={({ field }) => <input className="form-control" {...field} />}
              />
              {violations?.lastname?.message && <span className='text-danger'>{violations?.lastname?.message}</span>}
            </div>
          </div>
          <div className="col-md-4 mg-t--1 mg-md-t-0">
            <div className="form-group mg-md-l--1">
            <label className="form-control-label">{t('fe.account.profile.form.firstname.label')}: <span className="tx-danger">*</span></label>
              <Controller
                name="firstname"
                control={control}
                render={({ field }) => <input className="form-control" {...field} />}
              />
              {violations?.firstname?.message && <span className='text-danger'>{violations?.firstname?.message}</span>}
            </div>
          </div>
          <div className="col-md-4">
            <div className="form-group bd-t-0-force">
              <label className="form-control-label mg-b-0-force">{t('fe.account.profile.form.country.label')}: <span className="tx-danger">*</span></label>
              <Controller
                control={control}
                name="country"
                render={({ field }) => (
                  <FormSelect
                    {...field}
                    items={countries}
                    placeholder={t('fe.account.profile.form.country.placeholder')}
                    labelselector={'name'}
                    idselector={'abv'}
                  />
                )}
              />
            </div>
          </div>
          <div className="col-md-8">
            <div className="form-group mg-md-l--1 bd-t-0-force">
              <label className="form-control-label">{t('fe.account.profile.form.description.label')}: </label>
              <Controller
                name="description"
                control={control}
                render={({ field }) => <input className="form-control" {...field} placeholder={t('fe.account.profile.form.description.placeholder')} />}
              />
              {violations?.description?.message && <span className='text-danger'>{violations?.description?.message}</span>}
            </div>
          </div>
        </div>
        <div className="form-layout-footer bd pd-20 bd-t-0">
          <button className="btn btn-info" onClick={handleSubmit(onSubmit)}>{t('fe.account.profile.form.submit.button')}</button>
        </div>
      </form>}
    </section>
  )
}

export default AccountProfile;