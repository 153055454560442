/* eslint-disable array-callback-return */
import React, {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {Route, Routes, useLocation} from 'react-router-dom';
import { Loader, AccountMenu } from '@crosslud/components';
import {
  NotFound,
  AccountProfile,
  AccountEmail,
  AccountNickname,
  AccountPassword,
  AccountPreferences,
} from '.';

const AccountLayout = () => {
  const location = useLocation();
  
  const [activity, setActivity] = useState(true);

  const auth = useSelector(state => {
    return state.auth;
  });
  
  useEffect(() => {
    setActivity(true);

    //setDefaultValues();

    setActivity(false);
  }, [auth]);

  return (
    activity ? <Loader /> :
      <section className="mg-t-80">
        <div className="row">
          <div className="col-12 col-lg-3">
            <AccountMenu />
          </div>
          <div className="col-12 col-lg-9">
            <Routes location={location?.state?.backgroundLocation ?? location}>
              <Route index path="profile" element={<AccountProfile />} />
              <Route path="nickname" element={<AccountNickname />} />
              <Route path="email" element={<AccountEmail />} />
              <Route path="password" element={<AccountPassword />} />
              <Route path="preferences" element={<AccountPreferences />} />
              <Route path="*" element={<NotFound />} />
            </Routes>
          </div>
        </div>
      </section>
  )
}

export default AccountLayout;