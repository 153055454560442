/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
import React, {useEffect, useState} from 'react';
import {Link, useParams} from 'react-router-dom';
import { Loader, CategoryBreadcrumbText, FieldGroupManager } from '@crosslud/components';
import { CategoryAPI } from '@crosslud/apis/CategoryAPI';
import useActiveLink from '@crosslud/hooks/useActiveLink';
import useActivity from '@crosslud/hooks/useActivity';

const AdminSortersTypeSettings = () => {
  // SET ACTIVELINK
  useActiveLink('adminLayout', 'sorters');

  // PARAMS
  const {id} = useParams();
  const activityHook = useActivity(true);
  const [category, setCategory] = useState();
  
  // ACTION & API
  const fetchCategory = async (category_id) => {
    var activityRef = activityHook.start();

    const response = await CategoryAPI.get(category_id);

    if (response.status) {
      setCategory(response?.payload);
    }

    activityHook.stop(activityRef);
  };

  // Load categories
  useEffect(() => {
    id && fetchCategory(id);
  }, [id]);

  return (
    <div className='container'>
      <div className='row'>
        <div className='col'>
          <section className='bg-black-1'>
            {activityHook.isLoading ? <Loader centerScreen={false} /> :
            <div className="ht-100p">
              <div className='row'>
                <div className='col-12 mg-b-10'>
                  <div className="br-pagetitle pd-x-15 bg-black-1">
                    <i className="icon ion-ios-search"></i>
                    <div>
                      <h4>Sorters</h4>
                      <p className="mg-b-0">
                        <Link className={`pd-y-12 wd-100p-force active`} to={'/administration/sorters'}>
                          <CategoryBreadcrumbText category={category}/>
                        </Link>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className='row'>
                <div className='col-12'>
                  <div className='bg-black-1 pd-20'>
                    <FieldGroupManager to={`/administration/sorters/${id}/type/%id%/fields/settings`} />
                  </div>
                </div>
              </div>
            </div>
            }
          </section>
        </div>
      </div>
    </div>
  )
}

export default AdminSortersTypeSettings;