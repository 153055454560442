/* eslint-disable react-hooks/exhaustive-deps */
import {memo, useEffect, useState} from 'react';
import {useParams, Link} from 'react-router-dom';
import {useSelector} from 'react-redux';
import {ItemCard, ScrollList, ScrollListHeader, ScrollListCheckerHeader, CheckWrapper, ModalLink, ProtectedScreen, Loader, View, EmptyContent, CText, TitleCard} from '@crosslud/components';
import useSearchFilter from '@crosslud/hooks/useSearchFilter';
import FormData from '@crosslud/helpers/FormData';
import { CategoryAPI } from '@crosslud/apis/CategoryAPI';
import { CollectionAPI } from '@crosslud/apis/CollectionAPI';
import useActivity from '@crosslud/hooks/useActivity';
import useActiveLink from '@crosslud/hooks/useActiveLink';
import { useTranslation } from 'react-i18next';
import useCollectionAction from '@crosslud/hooks/useCollectionAction';

const CollectionItems = memo(({collector, pathnamePrefix, isCurrentUser, titles}) => {
  const {t} = useTranslation();

  // PARAMS
  const {id} = useParams();
  const checkerControlName = `collection-${id}`;
  
  // SET ACTIVELINK
  useActiveLink('collectionLayout', 'collections');

  // SELECTOR
  const collectionItemState = useSelector(state => {
    return state.collectionItem;
  });

  // HOOK
  const searchFilterHook = useSearchFilter(id, titles ? 'collection-titles' : 'collection-items');
  const activityHook = useActivity();
  const collectionAction = useCollectionAction();

  // STATE
  const [category, setCategory] = useState();

  // ACTION & API
  const fetchCategory = async (id) => {
    var activityRef = activityHook.start();

    const response = await CategoryAPI.get(id);

    if (response.status) {
      setCategory(response?.payload);
    }

    activityHook.stop(activityRef);
  };

  // EFFECT
  useEffect(() => {
    if (id && collector?.uuid) {
      fetchCategory(id);
    }
  }, [id, collector, titles]);

  // VIEWS
  const PageTitle = () => {
    return (
      <>
        <i className={`icon ${category?.icon ? category.icon : 'ion-earth'}`}></i>
        <div>
          <h4>{category?.alias?.translatedName}</h4>
          <p className="mg-b-0">
            <Link
              className="tx-white-6"
              to={{
                pathname: `${pathnamePrefix}/collections`,
              }}>
              <span className='tx-success'>
                {isCurrentUser ? 
                  t('fe.collections.my.collections'):
                  t('fe.collections.collector.collections', {collector: collector})
                }
              </span>
            </Link>
            <span> - </span>
            {category?.alias?.parent?.translatedName}
          </p>
        </div>
      </>
    );
  }

  const renderItem = ({item}) => {
    return (
      isCurrentUser ? (
        <CheckWrapper
          key={item?.uuid}
          identifier={item?.uuid} 
          checkerControl={checkerControlName}
          multiple={true}
        >
          <ModalLink
            to={{
              pathname: `/item-modal/collection-item/details/${item?.uuid}`,
            }}>
            <ItemCard data={item?.item} collectionData={item} isList={false} />
          </ModalLink>
        </CheckWrapper>
      ) : (
        <ModalLink
          to={{
            pathname: `/item-modal/item/details/${item?.item?.uuid}`,
          }}>
          <ItemCard data={item?.item} isList={false}/>
        </ModalLink>
      )
    );
  }

  const renderTitle = ({item}) => {
    return (
      <ModalLink
        to={{
          pathname: `/modal/collectors/${collector?.uuid}/titles/${item?.uuid}/items`,
        }}>
        <TitleCard data={item} />
      </ModalLink>
    )
  }

  const SwitchView = () => {
    return (
      <>
        <Link to={{pathname: `${pathnamePrefix}/collections/${category?.alias?.id}/items`}}>
          <button type="button" className={`btn ${titles === false ? 'btn-info': 'btn-outline-light'} rounded-0`}>
            <CText>Par objet</CText>
          </button>
        </Link>
        <Link to={{pathname: `${pathnamePrefix}/collections/${category?.alias?.id}/titles`}}>
          <button type="button" className={`btn ${titles === true ? 'btn-info': 'btn-outline-light'} rounded-0`}>
            <CText>Par titre</CText>
          </button>
        </Link>
        <span className='mg-r-10'></span>
      </>
    )
  }

  return (
    activityHook.isLoading ? <Loader /> :
    <ProtectedScreen deniedWhen={!category || category?.level > 2}>
      <section>
        <div className="row row-sm">
          <div className="col-lg-12">
            {collector?.uuid &&
              titles === true ? (
                <ScrollList
                  urlCaller={{
                    url: CollectionAPI.getCollectionTitles,
                    params: [collector.uuid, id, FormData.format(searchFilterHook.getFilters(), true), 1],
                    paginationIndex: 3,
                  }}
                  renderItem={renderTitle}
                  extraData={[titles, searchFilterHook.getFilters(), id]}
                  refreshListener={[collectionItemState]}
                  refreshAction={(datas, setDatas) => (
                    isCurrentUser && 
                    collectionAction.collectionStateHandler({
                      datas: datas, 
                      setDatas: setDatas,
                      checkerControlName: checkerControlName
                    })
                  )}
                  numColumns={"col-lg-4 col-md-4 col-sm-6 col-6 mb-3"}
                  showItemsNumber={true}
                  ListHeaderComponent={
                    <>
                      <ScrollListHeader 
                        titleComponent={<PageTitle />}
                        extraComponent={category?.level === 2 && <SwitchView />}
                        hook={searchFilterHook} 
                        category={id}
                        fieldType={titles ? 2 : 1}
                      />
                      <ScrollListCheckerHeader 
                        titleComponent={<PageTitle />}
                        checkerControl={checkerControlName}
                        style={{top: 60, paddingTop: 20}}
                      />
                    </>
                  }
                  ListEmptyComponent={
                    <View className='col-12'>
                      <EmptyContent text={t('fe.no.result.description')} />
                    </View>
                  }
                />
              ) : (
                <ScrollList
                  urlCaller={{
                    url: CollectionAPI.getCollectionItems,
                    params: [collector.uuid, id, FormData.format(searchFilterHook.getFilters(), true), 1],
                    paginationIndex: 3,
                  }}
                  renderItem={renderItem}
                  extraData={[titles, searchFilterHook.getFilters(), id]}
                  refreshListener={[collectionItemState]}
                  refreshAction={(datas, setDatas) => (
                    isCurrentUser && 
                    collectionAction.collectionStateHandler({
                      datas: datas, 
                      setDatas: setDatas,
                      checkerControlName: checkerControlName
                    })
                  )}
                  numColumns={'col-lg-3 col-md-4 col-6 mb-3'}
                  showItemsNumber={true}
                  ListHeaderComponent={
                    <>
                      <ScrollListHeader 
                        titleComponent={<PageTitle />}
                        extraComponent={category?.level === 2 && <SwitchView />}
                        hook={searchFilterHook} 
                        category={id}
                        fieldType={titles ? 2 : 1}
                      />
                      <ScrollListCheckerHeader 
                        titleComponent={<PageTitle />}
                        checkerControl={checkerControlName}
                        style={{top: 60, paddingTop: 20}}
                      />
                    </>
                  }
                  ListEmptyComponent={
                    <View className='col-12'>
                      <EmptyContent text={t('fe.no.result.description')} />
                    </View>
                  }
                />
              )
            }
          </div>
        </div>
      </section>
    </ProtectedScreen>
  )
});

export default CollectionItems;