/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import {useSelector} from 'react-redux';
import { EmptyContent, GridList, Loader, ProtectedScreen, CText, View } from '@crosslud/components';
import useActivity from '@crosslud/hooks/useActivity';
import useActiveLink from '@crosslud/hooks/useActiveLink';
import { useTranslation } from 'react-i18next';
import { CategoryAPI } from '@crosslud/apis/CategoryAPI';
import {  } from 'components/index';

const Collections = ({collector, isCurrentUser}) => {
  const {t} = useTranslation();
  const activityHook = useActivity(true);

  // SET ACTIVELINK
  useActiveLink('collectionLayout', 'collections');

  const collectionItemState = useSelector(state => {
    return state.collectionItem;
  });

  const [collections, setCollections] = useState([]);
  const [totalItems, setTotalItems] = useState();

  const fetchCollection = async (userId) => {
    var activityRef = activityHook.start();

    const response = await CategoryAPI.getUserCollections(userId);

    if (response.status) {
      var sections = [];
      var count = 0;

      response?.payload?.map(c => {
        if (c.totalItems > 0) {
          sections.push({title: c.translatedName, data: c.children});
          count = count + c.totalItems
        }
      });

      setTotalItems(count);
      setCollections(sections);
    }

    activityHook.stop(activityRef);
  };

  useEffect(() => {
    if (collector && isCurrentUser) {
      fetchCollection(collector.uuid);
    }
  }, [collectionItemState, collector]);

  useEffect(() => {
    if (collector && !isCurrentUser) {
      fetchCollection(collector.uuid);
    }
  }, [collector]);

  const renderItem = ({item, index}) => {
    return (
      <Link 
        key={item.id}
        to={`${!isCurrentUser ? '/collectors/' + collector.uuid : ''}/collections/${item.id}/items`}>
        <div className="card bd-0 bg-royal">
          <div className="pd-x-15 pd-y-10">
            <div className="d-flex align-items-center">
              <i className={`ion ${item?.icon ? item.icon : 'ion-earth'} tx-50 lh-0 tx-primary`}></i>
              <div className="mg-l-20">
                <div className="tx-12 tx-uppercase">
                  {item?.translatedName}
                </div>
                <div className="tx-20 tx-white tx-lato lh-1">{item?.totalItems}</div>
              </div>
            </div>
          </div>
        </div>
      </Link>
    )
  }

  return (
    <ProtectedScreen deniedWhen={!collector}>
      {activityHook.isLoading ? <Loader centerScreen={false} /> : (
        <View>
          <View className="br-pagetitle mg-b-20-force pd-x-15 bg-black-1">
            <i className="icon ion-ios-search"></i>
            <View>
              <h4>
                {isCurrentUser ? 
                  t('fe.collections.my.collections'):
                  t('fe.collections.collector.collections', {collector: collector})
                }
              </h4>
              {totalItems > 0 && <p className="mg-b-0">{t('fe.collection.collections.total.object', {total: totalItems})}</p>}
            </View>
          </View>
          
          <View className="br-block-wrapper pd-20">
            {collections.length <= 0 ? (
              <EmptyContent text={t('fe.collections.no.items.description')} />
            ) : (
              collections?.map(category => {
                return (
                  <View key={category.id}>
                    <CText className="tx-uppercase tx-15">{category.title}</CText>
                    <GridList
                      data={category.data.filter((subCategory) => subCategory?.totalItems > 0)}
                      numColumns={"col-md-4 col-lg-4 mg-b-20"}
                      renderItem={renderItem}
                    />
                  </View>
                )
              })
            )}
          </View>
        </View>
      )}
    </ProtectedScreen>
  )
}

export default Collections;