import { fetchUtils } from "./configs/fecthUtils";
import { api } from "./configs/fetchConfig";
import { userRequestSchema } from "./configs/schema";

export const UserRequestAPI = {
  getAll: async (filters = [], page = 1) => {
    const newFilters = filters.concat('page=' + page);

    var headers = new Headers();
    headers.set('Accept', 'application/ld+json');
    
    return await api.fetchResource('/api/protected/user-requests.jsonld?' + newFilters.join('&'), {
      method: 'GET',
      headers: headers,
    });
  },
  get: async id => {
    var headers = new Headers();
    headers.set('Accept', 'application/ld+json');

    return await api.fetchResource('/api/protected/user-requests/' + id + '.jsonld', {
      method: 'GET',
      headers: headers,
    });
  },
  post: async (data) => {
    return await api.fetchResource('/api/user-requests', {
      method: 'POST',
      body: JSON.stringify(fetchUtils.bodyFormat(data, userRequestSchema)),
    });
  },
  update: async (id, data) => {
    return await api.fetchResource('/api/protected/user-requests/' + id + '/update', {
      method: 'PUT',
      body: JSON.stringify(fetchUtils.bodyFormat(data, userRequestSchema)),
    });
  }
}
