/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
import React, {useEffect, useState} from 'react';
import {Link, useParams, useSearchParams} from 'react-router-dom';
import {CategoryBreadcrumbText, FormInputNode, Loader, Modal, Paginator, SearchFilter } from '@crosslud/components';
import { CategoryAPI } from '@crosslud/apis/CategoryAPI';
import useActivity from '@crosslud/hooks/useActivity';
import useSearchFilter from '@crosslud/hooks/useSearchFilter';
import FormData from '@crosslud/helpers/FormData';
import { LicenceAPI } from '@crosslud/apis/LicenceAPI';
import useActiveLink from '@crosslud/hooks/useActiveLink';

const AdminLicencesList = () => {
  // SET ACTIVELINK
  useActiveLink('adminLayout', 'licences');

  // PARAMS
  const {category_id} = useParams();

  // HOOK
  const searchFilterHook = useSearchFilter(category_id, 'admin-item-groups');
  const activityHook = useActivity();

  const [searchParams] = useSearchParams();
  const [category, setCategory] = useState();
  const [licences, setLicences] = useState();
  const [totalItems, setTotalItems] = useState();
  
  const fetchCategory = async (id) => {
    var activityRef = activityHook.start();

    const response = await CategoryAPI.get(id);

    if (response.status) {
      setCategory(response?.payload);
    }

    activityHook.stop(activityRef);
  };

  const fetchLicences = async (categoryId, filters = [], page = 1) => {
    var activityRef = activityHook.start();

    const response = await LicenceAPI.getAllProtected(categoryId, filters, page > 1 ? page : 1);

    if (response?.status) {
      setLicences(response?.payload);
      setTotalItems(response?.totalItems);
    }

    activityHook.stop(activityRef);
  };

  useEffect(() => {
    fetchCategory(category_id);
  }, []);
  
  useEffect(() => {
    var filters = FormData.format(searchFilterHook.getFilters(), true);
    fetchLicences(category_id, filters, searchParams.get('page') ?? 1);

  }, [searchParams, searchFilterHook.getFilters()]);

  return (
    <div className='container'>
      <div className='row'>
        <div className='col'>
          <section className='bg-black-1'>
            {activityHook.isLoading ? <Loader centerScreen={false} /> :
              <div className="ht-100p">
                <div className='row'>
                  <div className='col-12 mg-b-10'>
                    <div className="br-pagetitle pd-x-15 bg-black-1">
                      <i className="icon ion-ios-search"></i>
                      <div>
                        <h4>Licences</h4>
                        <Link className={`pd-y-12 wd-100p-force active`} to={'/administration/licences'}>
                          <CategoryBreadcrumbText category={category}/>
                        </Link>
                        {totalItems > 0 && <span> {`- ${totalItems} licence(s)`}</span>}
                      </div>
                      <div className="btn-group pos-absolute r-30">
                        <SearchFilter hook={searchFilterHook} category={category_id} fieldType={2} />
                      </div>
                    </div>
                  </div>
                </div>
                <div className='row'>
                  <div className='col-12'>
                    <div className='bg-black-1 pd-20'>
                      <div className="row">
                        <div className="col-12">
                        {licences?.length > 0 ?
                          <>
                            <table className="table table-responsive mg-b-0 tx-12">
                              <thead>
                                <tr className="tx-10">
                                  <th className="wd-5p pd-y-5">&nbsp;</th>
                                  <th className="wd-90p pd-y-5">Name</th>
                                  <th className="pd-y-5 tx-center">Actions</th>
                                </tr>
                              </thead>
                              <tbody>
                                {licences?.map((licence) => {
                                  return (
                                    <AdminLicenceLine key={licence.uuid} data={licence} />
                                  );
                                })}
                              </tbody>
                            </table>
                            {totalItems && <Paginator totalItems={totalItems}/>}
                          </>
                        :
                          <div className="bd bd-white-1 ht-100 d-flex align-items-center justify-content-center">
                            <h6 className="tx-uppercase mg-b-0 tx-roboto tx-normal tx-spacing-2">No licences found</h6>
                          </div>
                        }
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            }
          </section>
        </div>
      </div>
    </div>
  )
}

export default AdminLicencesList;

const AdminLicenceLine = ({data, onFinish}) => {
  const [modalVisible, setModalVisible] = useState();
  const [licence, setLicence] = useState();

  useEffect(() => {
    data && setLicence(data);
  }, [data])
  return (
    licence &&
    <>
      <tr>
        <td className="valign-middle tx-left">
          <span className="tx-12"><span className="square-8 bg-danger mg-r-5 rounded-circle"></span></span>
        </td>
        <td className='valign-middle'>
          {licence?.name}
        </td>
        <td className="valign-middle tx-center">
          <span className="btn tx-gray-600 tx-24" onClick={() => setModalVisible(true)}><i className="icon ion-android-more-horizontal"></i></span>
        </td>
      </tr>

      {/* Modal */}
      <Modal
        show={modalVisible}
        handleClose={() => setModalVisible(false)}
        contentClassName={'offset-md-2 col-8'}
        fullscreen={true}
        closeButton={true}
        centered={false}
      >
        <FormInputNode
          data={licence}
          itemsFromUrl={{
            url: LicenceAPI.getItemGroups,
            params: [licence?.uuid, [], 1],
            paginationIndex: 2
          }}
          idSelector={'uuid'}
          labelSelector={'name'}
          readOnly={true}
        />
      </Modal>
    </>
  );
};