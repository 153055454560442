/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef } from 'react';
import {default as BootstrapModal} from 'react-bootstrap/Modal';

const Modal = ({ 
  handleClose, 
  show, 
  children, 
  className = '', 
  contentClassName = 'col', 
  centered = true, 
  fullscreen = false,
  closeButton = false
}) => {
  const ref = useRef();

  // MouseListener
  useEffect(() => {
    const checkIfClickedOutside = e => {
      if (show && ref.current && !ref.current.contains(e.target)) {
        handleClose();
      }
    }

    document.addEventListener("mousedown", checkIfClickedOutside)

    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside)
    }
  }, [show])

  return (
    <BootstrapModal
      show={show}
      onHide={() => handleClose()}
      centered={centered}
      fullscreen={fullscreen}
      style={{minHeight: '100%', backgroundColor: fullscreen && '#141d28'}}
      dialogClassName={`${className} darkModal`}>
      <BootstrapModal.Body className={`pd-0 mg-0 ${!fullscreen && 'bd'}`}>
        {closeButton && (
          <div className='container'>
            <div className='row'>
              <div className='col'>
                <i className="icon ion-close float-right" style={{fontSize: '30px', marginTop: '10px', cursor: 'pointer'}} onClick={() => handleClose()} />
              </div>
            </div>
          </div>
        )}
        <div className='container'>
          <div className='row'>
            <div className={`${contentClassName} ${!fullscreen && ''}`}>
              <div className='row'>
                <div className='col pd-0'>{children}</div>
              </div>
            </div>
          </div>
        </div>
      </BootstrapModal.Body>
    </BootstrapModal>
  );
};

export default Modal;