/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from "react";
import ModalLink from "@crosslud/components/ModalLink/ModalLink";
import Modal from "@crosslud/components/Modal/Modal";
import useActivity from "@crosslud/hooks/useActivity";
import { Controller, useForm } from "react-hook-form";
import { LicenceAPI } from "@crosslud/apis/LicenceAPI";
import Alert from "@crosslud/helpers/Alert";
import Loader from "@crosslud/components/Loader/Loader";
import LoadMore from "@crosslud/components/LoadMore/LoadMore";
import ItemCard from "@crosslud/components/ItemCard/ItemCard";
import RandomTester from "@crosslud/components/RandomTester/RandomTester";
import { ItemAPI } from "@crosslud/apis/ItemAPI";

const FormInputNode = React.forwardRef(({
  name,
  data,
  itemsFromUrl,
  height,
  idSelector,
  labelSelector,
  value,
  onChange,
  onBlur,
  onDestroy,
  submitOnSelect,
  readOnly,
}, ref) => {
  const activityHook = useActivity(false);

  const [selections, setSelections] = useState();

  const actionPerform = (type, payload = {}) => {
    // On change save selected
    switch (type) {
      case 'onChange':
        setSelections([payload]);
        break;
      case 'onDestroy':
        onDestroy && onDestroy(payload);
        break;
      default: break;
    }
  }

  // Fire callback
  const handleFormSubmit = () => {
    onChange && onChange(selections[0]);
  }
  
  // On Selection fire event
  useEffect(() => {
    selections && submitOnSelect && handleFormSubmit();
  }, [selections])

  return (
    <div className="container">
      <div className="row">
        <div className="col">
          <div className="categories-wrapper pd-y-20 bg-dark tx-white">
            {activityHook.isLoading && <Loader />}
            <div style={height && {height: height, overflowY: "auto"}}>
              <NodeRow
                data={data}
                itemsFromUrl={itemsFromUrl}
                onBlur={onBlur} 
                name={name}
                idSelector={idSelector} 
                labelSelector={labelSelector} 
                value={value}
                readOnly={readOnly} 
                actionPerform={actionPerform}
              />
            </div>
            {!readOnly && !submitOnSelect &&
              <div className="bg-black-4">
                <div className="container">
                  <div className="row">
                    <div className="col-12 pd-5">
                      <button className="btn btn-primary float-right" onClick={() => handleFormSubmit()}>
                        Submit
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            }
          </div>
        </div>
      </div>
    </div>
  )
})

const NodeRow = ({data, itemsFromUrl, onBlur, name, labelSelector, idSelector, value, readOnly, actionPerform, onDelete}) => {
  const [show, setShow] = useState(false);
  const [item, setItem] = useState(data);
  const [reload, setReload] = useState();

  const [addModalVisible, setAddModalVisible] = useState(false);
  const [editModalVisible, setEditModalVisible] = useState(false);

  return React.useMemo(() => {
    // ACTION & API

    // Action perform DELETE
    const handleDelete = async item => {
      const response = await LicenceAPI.delete(item?.[idSelector]);

      if (response?.status) {
        Alert.notify(
          'Well done! ',
          'licence supprimée.',
          'info',
        );

        onDelete ? onDelete(item) : actionPerform('onDestroy', item);
      }
    }

    return (
      <ul>
        <li>
          <div>
            <span/>
            <div>
              {!readOnly && (
                <input
                  onBlur={onBlur}
                  type="radio"
                  name={name}
                  onClick={() => actionPerform('onChange', item)}
                  defaultChecked={value && parseInt(value[idSelector]) === parseInt(item[idSelector])}
                />
              )}
              <label>
                {labelSelector && (
                  <span className="mg-l-5" onClick={e => setShow(!show)}>
                    {item?.[labelSelector]}
                    {item?.hasChildrenItemGroup && (
                      <span type="button" className="btn btn-icon mg-x-10">
                        <i className={`icon ion-chevron-${show ? 'down' : 'right'} tx-18`}></i>
                      </span>
                    )}
                  </span>
                )}
              </label>
              <div className="btn-group mg-l-10">
                <span type="button" className="btn btn-icon mg-x-10" onClick={() => setAddModalVisible(true)}>
                  <i className="icon ion-ios-plus tx-18"></i>
                </span>
                <span type="button" className="btn btn-icon mg-x-10" onClick={() => setEditModalVisible(true)}>
                  <i className="icon ion-edit tx-18"></i>
                </span>
                {!item?.hasChildrenItemGroup && !item?.hasItemList && (
                  <span type="button" className="btn btn-icon mg-x-10" onClick={() => handleDelete(item)}>
                    <i className="icon ion-close tx-18"></i>
                  </span>
                )}
                {item?.hasItemList && <ItemList licence={item} />}
              </div>
            </div>

            {/* ADD */}
            <Modal show={addModalVisible} handleClose={() => setAddModalVisible(false)}>
              <LicenceEditor licence={item} action={'add'} onFinish={(status, payload) => {
                if (status) {
                  setAddModalVisible(false);
                  setItem(payload.parentItem);
                  setReload(payload);
                }
              }}/>
            </Modal>

            {/* EDIT */}
            <Modal show={editModalVisible} handleClose={() => setEditModalVisible(false)}>
              <LicenceEditor licence={item} action={'edit'} onFinish={(status, payload) => {
                if (status) {
                  setEditModalVisible(false);
                  setItem(payload);
                }
              }}/>
            </Modal>
          </div>

          {/* SUB NODE */}
          {item?.hasChildrenItemGroup && show && (
            <LoadMore
              itemsFromUrl={{
                url: itemsFromUrl?.url,
                params: [item[idSelector], [], 1],
                paginationIndex: itemsFromUrl?.paginationIndex
              }}
              extraData={[reload]}
            >
              {({ items, LoadMoreButtonComponent }) => {
                return (
                  items?.length > 0 &&
                  <>
                    {items?.map((subItem) =>
                      <NodeRow
                        key={subItem[idSelector]}
                        data={subItem}
                        itemsFromUrl={itemsFromUrl}
                        onBlur={onBlur} 
                        name={name}
                        idSelector={idSelector} 
                        labelSelector={labelSelector} 
                        value={value}
                        readOnly={readOnly}
                        actionPerform={actionPerform}
                        onDelete={(deletedItem) => {
                          setReload(Math.random());
                        }}
                      />
                    )}
                    <LoadMoreButtonComponent />
                  </>
                )
              }}
            </LoadMore>
          )}
        </li>
      </ul>
    )
  }, [item, show, addModalVisible, editModalVisible, reload])
}

const ItemList = ({licence}) => {
  const [modalVisible, setModalVisible] = useState(false);

  return (
    <>
      <span type="button" className="btn btn-icon mg-x-10" onClick={() => setModalVisible(true)}>
        <i className="icon ion-eye tx-18"></i>
      </span>
      <Modal 
        show={modalVisible} 
        handleClose={() => setModalVisible(false)}
        contentClassName={'col-12'}
        fullscreen={true}
        closeButton={true}
        centered={false}
      >
        <LoadMore
          itemsFromUrl={{
            url: ItemAPI.getProtectedItemGroupItems,
            params: [licence?.uuid, [], 1],
            paginationIndex: 2
          }}
        >
          {({ items, LoadMoreButtonComponent }) => (
            items?.length > 0 && (
              <>
                <div className="container">
                  <div className="row">
                    {items?.map((item) => {
                      return (
                        <div className="col-6 mg-b-10" key={item.uuid}>
                          <ModalLink
                            to={{
                              pathname: `/item-modal/item/details/${(item.parentUuid || item.uuid)}`,
                            }}>
                            <ItemCard data={item} isList={true} readOnly={true}/>
                          </ModalLink>
                        </div>
                      )
                    })}
                  </div>
                </div>
                <LoadMoreButtonComponent />
              </>
            )
          )}
        </LoadMore>
      </Modal>  
    </>
  )
}

const LicenceEditor = ({licence, action = null, onFinish = () => {}}) => {
  const activityHook = useActivity(false);
  const [violations, setViolations] = useState([]);

  // FORM
  const {
    control,
    handleSubmit,
  } = useForm({
    defaultValues: {
      name: action === 'edit' ? licence?.name : '',
    }
  });

  const onSubmit = async formData => {
    const activityRef = activityHook.start();

    if (action === 'add') {
      const response = await LicenceAPI.postItemGroup(licence?.uuid, formData);

      if (response?.status) {
        const responseParent = await LicenceAPI.get(licence?.uuid);

        if (responseParent?.status) {
          onFinish(true, {item: response?.payload, parentItem: responseParent?.payload});

          Alert.notify(
            'Well done! ',
            'Nouvelle licence créée.',
            'success',
          );
        }
      }
  
      // Violations
      setViolations(response?.violations);
    }

    if (action === 'edit') {
      const response = await LicenceAPI.update(licence?.uuid, formData);

      if (response?.status) {
        onFinish(true, response?.payload);

        Alert.notify(
          'Well done! ',
          'licence mise à jour.',
          'success',
        );
      }
  
      // Violations
      setViolations(response?.violations);
    }
    
    activityHook.stop(activityRef)
  }

  return (
    <div className="container bd-dark-blue">
      <div className="row justify-content-center">
        <div className="col-12">
          <form className="form-layout" method="post">
            <div className="container">
              <div className="row">
                <div className="col text-center mg-t-30 mg-b-20">
                  {action === 'add' && <h4>{`Create a licence for ${licence?.name}`}</h4>}
                  {action === 'edit' && <h4>{`Edit licence ${licence?.name}`}</h4>}
                </div>
              </div>
              {violations?.error?.message && <p className='text-center text-danger'>{violations?.error?.message}</p>}
              <div className="row">
                <div className="col-12">
                  <Controller
                    control={control}
                    name={'name'}
                    render={({ field }) => (
                      <input
                        {...field}
                        className="form-control tx-14"
                        type="text"
                        placeholder="Enter a name"
                      />
                    )}
                  />
                </div>
                <div className="col-12 mg-t-20 mg-b-30">
                  {activityHook.isLoading ? <Loader centerScreen={false} /> : (
                    <button className="col btn btn-info" onClick={handleSubmit(onSubmit)}>Submit</button>
                  )}
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

export default FormInputNode;