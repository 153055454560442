import { api } from "./configs/fetchConfig";

export const FileAPI = {
  create: async data => {
    var headers = new Headers();
    headers.set('Content-Type', '');

    return await api.fetchResource('/api/files', {
      method: 'POST',
      body: data,
      headers: headers,
    });
  },
  get: async path => {
    var headers = new Headers();
    headers.set('Accept', 'application/json');

    return await api.fetchResource('/api/files/' + path + '.json', {
      method: 'GET',
      headers: headers,
    });
  }
}