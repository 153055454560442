/* eslint-disable react-hooks/exhaustive-deps */
import {useEffect, useState} from 'react';
import {ItemThumb, ModalLink, Modal, View, CText, ScrollList} from '..';
import { ItemAPI } from '@crosslud/apis/ItemAPI';
import { useTranslation } from 'react-i18next';

const ComposedItems = ({entity}) => {
  const {t} = useTranslation();
  const [parentItem, setParentItem] = useState();
  const [modal, setModal] = useState(false);

  const renderItem = ({item, index}) =>
    <View style={{margin: 2}}>
      <ModalLink
          to={{
            pathname: `/item-modal/item/details/${(item?.uuid)}`,
          }}>
          <View key={index} className="mg-b-4">
            <CText className="tx-14 tx-bold tx-white-8">{item?.name}</CText>
            {item?.number?.reference && (
              <CText style={{color: '#f49917'}}>
                {" | "}{item?.number?.reference} {item?.numberText}
              </CText>
            )}
          </View>
        </ModalLink>
    </View>

  // Effect
  useEffect(() => {
    entity?.parentUuid ? setParentItem(entity.parent) : setParentItem(entity);
  }, []);

  return (
    <>
      {parentItem?.uuid && (
        <span className="btn btn-outline-info btn-block mg-t-10" onClick={() => setModal(true)}>
          <i className="fa fa-layer-group mg-r-10"></i> 
          {t('fe.itemSheet.composedItems.button')}
        </span>
      )}

      {modal && (
        <Modal
          show={modal} 
          fullscreen={false} 
          centered={true} 
          handleClose={() => setModal(false)}
          className='modal-lg'
          closeButton={true}
        >
          <View className='container mg-y-40'>
            <View className='row'>
              <View className="col-auto">
                <ModalLink
                  to={{
                    pathname: `/item-modal/item/details/${(parentItem.uuid)}`,
                  }}>
                  <ItemThumb data={parentItem} className={'ht-150'}/>
                </ModalLink>
              </View>
              <View className="col-6">
                <ScrollList
                  urlCaller={{
                    url: ItemAPI.getComposedItems,
                    params: [parentItem.uuid, [], 1],
                    paginationIndex: 2,
                  }}
                  renderItem={renderItem}
                  numColumns={'col-12'}
                />
              </View>
            </View>
          </View>
        </Modal>
      )}
    </>
  )
}

export default ComposedItems;
