/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {Modal} from '@crosslud/components';
import NotificationDetails from './NotificationDetails/NotificationDetails';
import { NOTIFICATION_UPDATE } from '@crosslud/redux/Notification/notification-types';

const NotificationLine = ({data, onFinish}) => {
  const [notification, setNotification] = useState(data);

  const notificationState = useSelector(state => {
    return state.notification;
  });

  const [modal, setModal] = useState(false);

  useEffect(() => {
    // Notifiation UPDATE
    if (notificationState.action === NOTIFICATION_UPDATE 
      && notificationState.notification?.uuid === notification.uuid
    ) {
      setNotification(notificationState?.notification);
    }
  }, [notificationState]);

  return (
    <>
      <span className={`media-list-link ${!notification?.opened && 'read'} cursor-pointer`} role='button' onClick={() => setModal(true)}>
        <div className="media">
          <img src="../img/img8.jpg" alt="" />
          <div className="media-body">
            {/*<p className="noti-text"><strong>Suzzeth Bungaos</strong> tagged you and 18 others in a post.</p>*/}
            <p className="noti-text">{notification?.translatedShortDescription}</p>
            <span>{(new Date(notification?.createdAt)).toLocaleDateString()}{/*October 03, 2017 8:45am*/}</span>
          </div>
        </div>
      </span>

      {/* Settings modal */}
      <Modal
        show={modal} 
        fullscreen={false} 
        centered={true} 
        handleClose={() => setModal(false)}
        contentClassName={'col'}
        closeButton={true}
      >
        <NotificationDetails notification={notification} />
      </Modal>
    </>
  );
};

export default NotificationLine;
