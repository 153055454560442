/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
import React, {useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import { View, CText } from '..';
import { CommonAPI } from '@crosslud/apis/CommonAPI';
import Text from '@crosslud/helpers/Text';
import useActivity from '@crosslud/hooks/useActivity';

const FieldGroupManager = ({to}) => {
  // FORM
  const activityHook = useActivity(true);
  const [fieldGroups, setFieldGroups] = useState();
  
  // ACTION & API
  const fetchProtectedfieldGroups = async () => {
    var activityRef = activityHook.start();

    const response = await CommonAPI.getProtectedfieldGroups();

    if (response.status) {
      setFieldGroups(response?.payload);
    }

    activityHook.stop(activityRef);
  };

  useEffect(() => {
    fetchProtectedfieldGroups();
  }, []);

  return (
    <View className='container'>
      <View className="row">
        {fieldGroups?.map((fieldGroup, index) => {
          return (
            <View key={index} className="col-4">
              <View key={fieldGroup.id} className="card bd-0 mb-4">
                <View className="card-header bg-primary text-white">
                  <CText className="mg-l-10 mg-y-0">
                    {fieldGroup.name}
                  </CText>
                </View>
                <View className="card-body bd bd-t-0 rounded-bottom">
                  Some quick example text to build on the card title and make up the bulk of the card's content. Lorem ipsum dolor sit amet consictetur...
                  <Link 
                    to={Text.sprintf(to, fieldGroup)}
                    className="btn btn-primary btn-sm mg-t-10"
                  >
                    <CText>Manage fields</CText>
                    <CText className="icon mg-l-5"><i className="ion-paper-airplane"></i></CText>
                  </Link>
                </View>
              </View>
            </View>
          )
        })}
      </View>
    </View>
  )
}

export default FieldGroupManager;