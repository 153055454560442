/* eslint-disable react-hooks/exhaustive-deps */
import { useTranslation } from 'react-i18next';
import {ItemCard, Modal, ScrollList, ModalLink, View, Button} from '@crosslud/components';
import { ItemAPI } from '@crosslud/apis/ItemAPI';
import { useState } from 'react';

const TabLinkedItemsMore = ({entity}) => {
  const { t } = useTranslation();

  // STATE
  const [showModal, setShowModal] = useState(false);
  
  return (
    <>
      <Button
        className={"btn btn-secondary mb-3"}
        title={t('fe.itemDetails.tabLinkedItemsMore.showMore.button')}
        onPress={() => setShowModal(true)} 
      />

      <Modal
        show={showModal} 
        handleClose={() => setShowModal(false)}
        fullscreen={true}
        centered={true}
        contentClassName={'col-8 m-auto'}
        closeButton={true}
      >
        <View>
          <View className={'mb-3 tx-center pd-40'}>
            <h4 className='tx-info'>
              {t("fe.itemSheet.tabLinkedItems.title").toUpperCase()}
            </h4>
          </View>
          <ScrollList
            urlCaller={{
              url: ItemAPI.getLinkedItems,
              params: [entity.uuid, [], 1],
              paginationIndex: 2,
            }}
            renderItem={({item, index}) =>
              <View style={{margin: 2}}>
                <ModalLink
                  to={{
                    pathname: `/item-modal/item/details/${(item?.uuid)}`,
                  }}>
                  <ItemCard data={item} overlay={true} />
                </ModalLink>
              </View>
            }
            numColumns={3}
          />
        </View> 
      </Modal>
    </>
  )
}

export default TabLinkedItemsMore;