/* eslint-disable react-hooks/exhaustive-deps */
import {memo, useEffect, useState} from 'react';
import {Link, useParams} from 'react-router-dom';
import {ItemCard, ScrollList, ScrollListHeader, ModalLink, View, EmptyContent} from '@crosslud/components';
import FormData from '@crosslud/helpers/FormData';
import useSearchFilter from '@crosslud/hooks/useSearchFilter';
import useActivity from '@crosslud/hooks/useActivity';
import { useTranslation } from 'react-i18next';
import { ItemAPI } from '@crosslud/apis/ItemAPI';
import { LicenceAPI } from '@crosslud/apis/LicenceAPI';

const TitleItems = () => {
  // PARAMS
  const {titleId} = useParams();

  // TRANSLATION
  const { t } = useTranslation();

  // HOOK
  const searchFilterHook = useSearchFilter(titleId, 'category-title-items');
  const activityHook = useActivity();

  // STATE
  const [title, setTitle] = useState();

  // ACTION & API
  const fetchTitle = async id => {
    const activityRef = activityHook.start();

    const response = await LicenceAPI.get(id);

    if (response.status) {
      setTitle(response?.payload);
    }

    activityHook.stop(activityRef);
  };

  const renderItem = ({item}) => {
    return (
      <ModalLink key={item?.uuid} to={{pathname: `/item-modal/item/details/${item?.uuid}`}}>
        <ItemCard data={item} overlay={true}/>
      </ModalLink>
    )
  }

  // EFFECT

  // Endpoint : Id
  useEffect(() => {
    if (titleId) {
      fetchTitle(titleId);
    }
  }, [titleId]);

  return (
    title &&
    <div className="container">
      <div className='row mg-b-20'>
        <div className='col tx-center'>
          <h3 className='tx-uppercase'>{title?.name}</h3>
        </div>
      </div>
      <div className='row'>
        <div className='col'>
          <ScrollListHeader 
            titleComponent={<PageTitle title={title}/>}
            hook={searchFilterHook} 
            category={title?.category?.alias?.id}
            fieldType={1}
          />
        </div>
      </div>
      <div className="row justify-content-center">
        <div className="col-12 mg-t-20">
          <ScrollList
            urlCaller={{
              url: ItemAPI.getTitleItems,
              params: [title?.uuid, FormData.format(searchFilterHook.getFilters(), true), 1],
              paginationIndex: 2,
            }}
            renderItem={renderItem}
            extraData={[searchFilterHook.getFilters(), title?.uuid]}
            numColumns={"col-lg-2 col-md-4 col-sm-6 col-6 mb-3"}
            ListEmptyComponent={
              <View className='col-12'>
                <EmptyContent text={t('fe.no.result.description')} />
              </View>
            }
          />
        </div>
      </div>
    </div>
  )
}

export default memo(TitleItems);

const PageTitle = ({title}) => {
  return (
    <>
      <i className={`icon ${title?.category?.icon ? title?.category.icon : 'ion-earth'}`}></i>
      <div>
        <h4>{title?.category?.alias?.translatedName}</h4>
        <p className="mg-b-0">
          <Link to={{pathname: `/browse/${title?.category?.alias?.parent?.id}`}}>
            <span>{title?.category?.alias?.parent?.translatedName}</span>
          </Link>
        </p>
      </div>
    </>
  );
}