/* eslint-disable no-loop-func */
/* eslint-disable array-callback-return */
/* eslint-disable no-alert */
import i18n from '../../i18n';
import AuthToken from '../../helpers/AuthToken';
import { fetchUtils } from './fecthUtils';

const API_BASE_URL = process.env.EXPO_PUBLIC_API_URL ?? process.env.REACT_APP_API_URL;
const TOKEN_URL = '/api/login';
const REFRESH_TOKEN_URL = '/token/refresh';
let refreshFunc;

export const api = {
  fetchInterceptors: async (resource, options = {}) => {
    var response = null;

    const {timeout = 30000} = options;
    
    // eslint-disable-next-line no-undef
    const abortController = new AbortController();
    
    const t = setTimeout(() => abortController.abort(), timeout);
    
    // Default Headers
    var headers = new Headers();

    headers.set('Accept', 'application/json');

    // i18n language
    headers.set('Accept-Language', i18n.language);
    
    if (options?.method?.toLowerCase() !== 'get') {
      headers.set('Content-Type', 'application/ld+json');
    }
    
    // Merge headers
    if (options?.headers) {
      for (const pair of options.headers.entries()) {
        pair[1] === '' ? headers.delete(pair[0]) : headers.set(pair[0], pair[1]);
      }
    }
    
    // Set Authorization header
    const token = await AuthToken.getToken();
    
    if (token) {
      headers.set('Authorization', 'Bearer ' + token);
    }
    
    // Options
    options.headers = headers;
    options.signal = abortController.signal;
    //options.credentials = 'include';

    try {
      // Fetch
      response = await fetch(API_BASE_URL + resource, options);
      
      clearTimeout(t);
    } catch(e) {
      console.log(e);
    }

    return response;
  },
  // Fetch Resource
  fetchResource: async (resource, options = {}) => {
    // Save original request options.
    const originalOptions = {...options};
    
    let response = await api.fetchInterceptors(resource, options);

    // If we have a 401 response and a refresh token, try a renewal
    const refreshToken = await AuthToken.getRefreshToken();

    if (response?.status === 401 && refreshToken) {
      try {
        // Renew token
        if (!refreshFunc) {
          refreshFunc = api.renewToken();
        }

        const [newToken, newRefreshToken] = await refreshFunc;

        await AuthToken.setToken(newToken);
        await AuthToken.setRefreshToken(newRefreshToken);

        // Retry original request
        response = await api.fetchInterceptors(resource, originalOptions);
      } catch (e) {
        await api.unsetToken();
      } finally {
        refreshFunc = undefined;
      }
    }

    let responseFormatted = {
      status: true,
      code: response?.status,
      payload: null,
      totalItems: null,
      paginator: null,
      violations: [],
    };

    var responseJSON = null;
    var responseStatus = null;

    try {
      const text = await response?.text();
      responseJSON = text && JSON.parse(text);
      responseStatus = response?.status;
    } catch (err) {
      // The response wasn't a JSON object
      // Do your text handling here
      console.log(err);
    }

    // Success
    if ([200, 201, 204].includes(responseStatus)) {
      responseFormatted.payload = responseJSON?.['hydra:member'] ?? responseJSON;
      responseFormatted.totalItems = responseJSON?.['hydra:totalItems'] ?? null;

      responseFormatted.paginator = {
        first: fetchUtils.getParameterByName('page', responseJSON?.['hydra:view']?.['hydra:first'] ?? null),
        last: fetchUtils.getParameterByName('page', responseJSON?.['hydra:view']?.['hydra:last'] ?? null),
        previous: fetchUtils.getParameterByName('page', responseJSON?.['hydra:view']?.['hydra:previous'] ?? null),
        next: fetchUtils.getParameterByName('page', responseJSON?.['hydra:view']?.['hydra:next'] ?? null)
      }
    } else if ([422].includes(responseStatus)) {
      // Unprocessable Exception
      var formattedViolations = {};

      var violationList = responseJSON?.violations?.violations ?? responseJSON?.violations;

      violationList?.map(violation => {
        if (!formattedViolations[violation.propertyPath]) {
          formattedViolations[violation.propertyPath] = {
            message: violation?.message ?? violation.title,
            code: violation?.code,
          };
        }
      });

      responseFormatted.status = false;
      responseFormatted.violations = formattedViolations;
    } else {
      // Exception
      responseFormatted.status = false;
      //responseJSON.payload = responseJSON;
      responseFormatted.violations = {error: {message: responseJSON?.detail}};
    }

    return responseFormatted;
  },
  getToken: async (username, password) => {
    try {
      const response = await api.fetchInterceptors(TOKEN_URL, {
        method: 'POST',
        body: JSON.stringify({
          email: username,
          password: password,
        }),
      });
  
      let responseJSON = {
        status: true,
        code: response?.status,
        payload: null,
        totalItems: null,
        paginator: null,
        violations: [],
      };
  
      if ([200, 201, 204].includes(response?.status)) {
        const authSucessResponseJSON = await response.json();
  
        await AuthToken.setToken(authSucessResponseJSON?.token);
        await AuthToken.setRefreshToken(authSucessResponseJSON?.refresh_token);
  
        responseJSON.payload = true;
      } else if ([401].includes(response?.status)) {
        const authErrorResponseJSON = await response.json();
  
        responseJSON.status = false;
        responseJSON.violations = {
          authentication: {message: authErrorResponseJSON?.detail}
        };
      } else {
        responseJSON.status = false;
      }
  
      return responseJSON;
    } catch (error) {
      console.log(error);
  
      return false;
    }
  },
  renewToken: async () => {
    const refreshToken = await AuthToken.getRefreshToken();
  
    if (!refreshToken) {
      throw new Error('Refresh token does not exist');
    }
  
    const response = await api.fetchInterceptors(REFRESH_TOKEN_URL, {
      method: 'POST',
      body: JSON.stringify({
        refresh_token: refreshToken,
      }),
    });
  
    if (![200, 201, 204].includes(response?.status)) {
      throw new Error('Refresh token request failed');
    }
  
    const responseJSON = await response.json();
  
    return [responseJSON?.token, responseJSON?.refresh_token];
  },
  unsetToken: async () => {
    try {
      await AuthToken.unsetTokens();
  
      return {status: true, code: 200, payload: true};
    } catch (error) {
      console.log(error);
  
      return false;
    }
  }
}
