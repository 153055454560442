/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from 'react';
//import {Rating} from 'react-native-ratings';
import Rating from 'react-rating';
import {Loader} from '..';
import {useDispatch} from 'react-redux';
import {reviewCreate, reviewUpdate} from '@crosslud/redux/Review/review-actions';
import { useTranslation } from 'react-i18next';
import useActivity from '@crosslud/hooks/useActivity';
import { ReviewAPI } from '@crosslud/apis/ReviewAPI';

const Comment = ({item, onFinish}) => {
  const {t} = useTranslation();

  const [review, setReview] = useState();
  const [formRate, setFormRate] = useState(0);
  const [formMessage, setFormMessage] = useState();

  const dispatch = useDispatch();
  const activityHook = useActivity();

  const fetchItemCurrentUserReview = async itemId => {
    var activityRef = activityHook.start();

    const response = await ReviewAPI.getCurrentUserReview(itemId);

    if (response.status) {
      setReview(response?.payload);
    }

    activityHook.stop(activityRef);
  };

  useEffect(() => {
    item?.uuid && fetchItemCurrentUserReview(item?.uuid);
  }, [item]);

  useEffect(() => {
    if (review?.id) {
      setFormRate(review.rate);
      setFormMessage(review.message);
    }
  }, [review]);

  const commentSubmit = () => {
    if (!review) {
      itemReviewCreate(item?.uuid, formRate, formMessage);
    } else {
      itemReviewUpdate(review?.id, formRate, formMessage);
    }
  };

  const itemReviewCreate = async (itemUuid, rate, message) => {
    var activityRef = activityHook.start();
    
    // Data
    var data = {};

    data.item = itemUuid;
    data.rate = rate;
    data.message = message;

    const response = await ReviewAPI.createReview(data);

    if (response?.status) {
      var notification = {
        title: t('fe.comment.add.alert.success.title'),
        body: t('fe.comment.add.alert.success.description'),
      };

      dispatch(reviewCreate(response?.payload));
      onFinish && onFinish(response?.payload);
    }

    activityHook.stop(activityRef);
  };

  const itemReviewUpdate = async (reviewId, rate, message) => {
    var activityRef = activityHook.start();

    // Data
    var data = {};

    data.rate = rate;
    data.message = message;

    const response = await ReviewAPI.updateReview(reviewId, data);

    if (response.status) {
      var notification = {
        title: t('fe.comment.update.alert.info.title'),
        body: t('fe.comment.update.alert.info.description'),
      };

      dispatch(reviewUpdate(response?.payload));
      onFinish && onFinish(response?.payload);
    }

    activityHook.stop(activityRef);
  };

  return (
    <div className="container">
      <div className="row">
        <div className="col-12 pd-x-40 pd-b-40">
          <div className="row">
            <div className="col-12 mg-b-20">
              <h4 className="text-center">{t('fe.comment.form.title')}</h4>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
            {activityHook.isLoading ? <Loader centerScreen={true} /> :
              <div>
                <div className="text-center">
                  <span className="tx-warning tx-30">{formRate}</span>
                  <span className="tx-20">/10</span>
                </div>
                <div className="mg-t-20"></div>
                <div className="nk-form">
                  <div className="text-center">
                    <Rating
                      initialRating={formRate}
                      fractions={2}
                      stop={10}
                      onClick={(v) => setFormRate(v)}
                      emptySymbol={<i className="icon ion-star tx-gray-500 tx-40"></i>}
                      fullSymbol={<i className="icon ion-star tx-warning tx-40"></i>}
                    />
                  </div>
                  <div className="mg-t-20">
                    <textarea
                      className="form-control"
                      type="textarea" 
                      rows={10}
                      value={formMessage}
                      onChange={e => setFormMessage(e.target.value)}
                      placeholder={t('fe.comment.form.message.placeholder')}
                    />
                  </div>
                  <div className="mg-t-20"></div>
                  <button className="btn btn-primary col" onClick={() => commentSubmit()}>
                    <span>{t('fe.comment.form.submit.button')}</span>
                  </button>
                </div>
              </div>
            }
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Comment;
