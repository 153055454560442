/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
import React, {useEffect, useState} from 'react';
import {Link, useParams} from 'react-router-dom';
import {loaderStart, loaderEnd} from '@crosslud/redux/Loader/loader-actions';
import { MovableContainer, MovableContext, Loader, CategoryBreadcrumbText } from '@crosslud/components';
import { useDispatch } from 'react-redux';
import {Controller, useForm} from 'react-hook-form';
import { CategoryAPI } from '@crosslud/apis/CategoryAPI';
import Alert from '@crosslud/helpers/Alert';
import useActiveLink from '@crosslud/hooks/useActiveLink';
import { CommonAPI } from '@crosslud/apis/CommonAPI';

const AdminFiltersFieldsSettings = () => {
  // SET ACTIVELINK
  useActiveLink('adminLayout', 'filters');

  const dispatch = useDispatch();

  // PARAMS
  const {id, fieldGroupId} = useParams();

  // FORM
  const {
    control,
    handleSubmit,
    setValue,
  } = useForm();

  const [activity, setActivity] = useState(true);
  const [category, setCategory] = useState();
  
  // ACTION & API
  const fetchCategory = async (category_id) => {
    const response = await CategoryAPI.get(category_id);

    if (response.status) {
      setCategory(response?.payload);
    }
  };

  const fetchCategoryProtectedSearchFormFields = async (category_id, field_group_id) => {
    setActivity(true);

    const response = await CommonAPI.getProtectedSearchFormFields(category_id, field_group_id);

    if (response?.status) {
      var sortables = [];

      response?.payload?.map((xField) => {
        sortables.push({
          id: parseInt(xField.id), 
          droppable: xField.scope, 
          data: xField
        })
      });

      setValue('filtersFields', sortables);
    }

    setActivity(false);
  };

  // Save settings
  const onSubmit = async formData => {
    dispatch(loaderStart());

    var data = [];

    formData.filtersFields.map((item, index) => {
      data.push({
        id: item.data.id,
        label: item.data.label,
        scope: item.droppable,
        position: index
      });
    });

    const response = await CommonAPI.bachUpdateProtectedSearchFormFields(data);

    if (response?.status) {
      Alert.notify(
        'Well done! ',
        'Vos modifications ont été enregistrées.',
        'success',
      );
    }

    dispatch(loaderEnd());
  }

  // Load categories
  useEffect(() => {
    fetchCategory(id);
    fetchCategoryProtectedSearchFormFields(id, fieldGroupId);
  }, []);

  return (
    <div className='container'>
      <div className='row'>
        <div className='col'>
          <section className='bg-black-1'>
            {activity ? <Loader centerScreen={false} /> :
              <div className="ht-100p">
                <div className='row'>
                  <div className='col-12 mg-b-10'>
                    <div className="br-pagetitle pd-x-15 bg-black-1">
                      <i className="icon ion-ios-search"></i>
                      <div>
                        <h4>Filters</h4>
                        <p className="mg-b-0">    
                          <Link className={`pd-y-12 wd-100p-force active`} to={`/administration/filters/${category?.id}/type/settings`}>
                            <CategoryBreadcrumbText category={category}/>
                          </Link>
                        </p>
                      </div>
                      <div className="btn-group pos-absolute r-30">
                        <button type="button" className="btn btn-outline-light rounded-0" onClick={handleSubmit(onSubmit)}><i className="fa fa-save"></i></button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='row'>
                  <div className='col-12'>
                    <div className='bg-black-1 pd-20'>
                      <div className='row'>
                        <div className="col-12">
                          <Controller
                            control={control}
                            name="filtersFields"
                            render={({ field }) => (
                              <MovableContainer
                                {...field}
                                render={({ attributes }) => (
                                  <div className="row">
                                    <div className="col-6">
                                      <div className="row">
                                        <div className="col-12 mg-b-10"><h4>Cemetery</h4></div>
                                      </div>
                                      <MovableContext
                                        {...attributes}
                                        droppable="cemetery"
                                        component={(item, onChange) =>
                                          <MovableItem item={item} onChange={onChange}/>
                                        }
                                      />
                                    </div>
                                    <div className="col-6">
                                      <div className="row">
                                        <div className="col-12 mg-b-10"><h4>Mandatory</h4></div>
                                      </div>
                                      <MovableContext
                                        {...attributes}
                                        droppable="main"
                                        component={(item, onChange) =>
                                          <MovableItem item={item} onChange={onChange}/>
                                        }/>
                                    </div>
                                  </div>
                                )}
                              />
                            )}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            }
          </section>
        </div>
      </div>
    </div>
  )
}

const MovableItem = ({item, onChange}) => {
  const handleFormChange = ({name, value}) => {
    item.data[name] = value;

    // Container callback for updated element
    onChange(item);
  }

  return (
    <div className="card bd-0 mb-2">
      <div className="card-header bg-primary text-white">
        <label className="mg-y-0">{item.data?.formField?.reference}</label>
        <input
          className="float-right mg-r-10"
          type="text"
          name="label"
          value={item.data?.label ?? ''}
          onChange={(e) => handleFormChange({name: e.target.name, value: e.target.value})}
        />
      </div>
    </div>
  );
}

export default AdminFiltersFieldsSettings;