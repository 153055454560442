import React from 'react';
import {NiceDropdown} from '@crosslud/components';
import {Route, Routes, useLocation} from 'react-router';
import {NotificationHistory, NotFound} from '.';

const HistoryLayout = () => {
  const location = useLocation();

  const menus = [
    {identifier: 'notifications', pathname: '/history/notifications', label: 'Notifications', enable: true},
  ];

  return (
    <section className="mg-t-80">
      <div className="row">
        <div className="col-12 col-lg-3">
          <NiceDropdown data={menus}  scope='historyLayout'/>
        </div>
        <div className="col-12 col-lg-9">
          <Routes location={location?.state?.backgroundLocation ?? location}>
            <Route path="notifications" element={<NotificationHistory />} />
            <Route path="messengers" element={<NotificationHistory />} />
            <Route path="requests" element={<NotificationHistory />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </div>
      </div>
    </section>
  );
}

export default HistoryLayout;
