/* eslint-disable react-hooks/exhaustive-deps */
import {createSearchParams} from 'react-router-dom';
import { EmptyContent, ItemCard, ModalLink, ScrollList, View } from '@crosslud/components';
import { ItemAPI } from '@crosslud/apis/ItemAPI';
import useCustomSearchParams from '@crosslud/hooks/useCustomSearchParams';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

const Search = () => {
  const {t} = useTranslation();
  const [searchParams, setSearchParams] = useCustomSearchParams();
  const [searchQuery, setSeachQuery] = useState(searchParams?.get('q'));

  const renderItem = ({item}) => {
    return (
      <ModalLink key={item?.uuid} to={{pathname: `/item-modal/item/details/${item?.uuid}`}}>
        <ItemCard data={item} overlay={true}/>
      </ModalLink>
    )
  }

  return (
    <section className="align-self-start">
      <div className="mg-b-20-force bg-black-1">
        <div className="row justify-content-center">
          <div className="col-12">
            <div className="row no-gutters">
              <div className="col-12">
                <div className="widget-18">
                  <div className="wt-content">
                    <div className="wt-content-item">
                      <h1 className="wt-title">
                        {t('fe.search.form.title')}
                      </h1>
                      <p className="mg-b-30">
                        {t('fe.search.form.description')}
                      </p>
                      <div className="d-sm-flex justify-content-center">
                        <div className="input-group">
                          <div className="input-group-prepend">
                            <span className="input-group-text"><i className="fa fa-search"></i></span>
                          </div>
                          <input 
                            className="form-control" 
                            type="text" 
                            name="q" 
                            placeholder={t('fe.search.form.q.placeholder')}
                            value={searchParams?.get('q') || ''}
                            onChange={(e) => setSearchParams(createSearchParams({q: e.target.value}))}
                            autoComplete="off"
                          />
                          <div className="input-group-prepend">
                            <button
                              className="btn btn-info"
                              style={{padding: 0, paddingRight: 20, paddingLeft: 20}}
                              onClick={() => setSeachQuery(searchParams?.get('q'))}
                            >
                              {t('fe.search.form.button.submit')}
                            </button>
                          </div>
                        </div>
                      </div>
                      {/*
                      <nav className="nav">
                        <a href="/" className="nav-link">
                          <span className="iconwrap icon-32"><i className="icon ion-android-restaurant"></i></span>
                          <p>Eat</p>
                        </a>
                        <a href="/" className="nav-link">
                          <span className="iconwrap icon-32"><i className="icon ion-android-bar"></i></span>
                          <p>Drink</p>
                        </a>
                        <a href="/" className="nav-link">
                          <span className="iconwrap icon-32"><i className="icon ion-android-film"></i></span>
                          <p>Fun</p>
                        </a>
                        <a href="/" className="nav-link">
                          <span className="iconwrap icon-32"><i className="icon ion-android-cart"></i></span>
                          <p>Shop</p>
                        </a>
                      </nav>
                      */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="br-pagebody">
        <div className="row row-sm">
          <div className="col-lg-12">
          {searchQuery &&
            <ScrollList
              urlCaller={{
                url: ItemAPI.getByCriteria,
                params: [searchQuery, 1],
                paginationIndex: 1,
              }}
              renderItem={renderItem}
              extraData={[searchQuery]}
              numColumns={"col-lg-2 col-md-4 col-sm-6 col-6 mb-3"}
              ListEmptyComponent={
                <View className='col-12'>
                  <EmptyContent text={t('fe.no.result.description')} />
                </View>
              }
            />
          }
          </div>
        </div>
      </div>
    </section>
  )
}

export default Search;