/* eslint-disable react-hooks/exhaustive-deps */
import React, { memo, useState } from 'react';
import {Link} from 'react-router-dom';
import {useSelector} from 'react-redux';

import {CText, Media, View} from '@crosslud/components';
import Text from '@crosslud/helpers/Text';
import { useTranslation } from 'react-i18next';
import useItemAction from '@crosslud/hooks/useItemAction';

const ItemCard = ({data, collectionData, isList = false, readOnly = false}) => {
  // STATE
  const [entity, setEntity] = useState(data);
  const [collectionEntity, setCollectionEntity] = useState(collectionData);

  const itemAction = useItemAction(entity, setEntity, collectionEntity, setCollectionEntity);

  // SELECTOR
  const auth = useSelector(state => {
    return state.auth;
  });

  // MEMOIZE
  const renderCardMemoize = React.useMemo(() => {
    return (
      <CardRender
        auth={auth}
        entity={entity}
        collectionEntity={collectionEntity}
        readOnly={readOnly}
        goToAuhentication={itemAction.goToAuhentication}
        collectionAddItemHandler={itemAction.collectionAddItemHandler}
        collectionDeleteItemHandler={itemAction.collectionDeleteItemHandler}
      />
    )
  }, [data, collectionData]);

  const renderListMemoize = React.useMemo(() => {
    return (
    <ListRender
        auth={auth}
        entity={entity}
        collectionEntity={collectionEntity}
        readOnly={readOnly}
        goToAuhentication={itemAction.goToAuhentication}
        collectionAddItemHandler={itemAction.collectionAddItemHandler}
        collectionDeleteItemHandler={itemAction.collectionDeleteItemHandler}
      />
    )
  }, [data, collectionData]);

  // RENDER
  return !isList ? renderCardMemoize : renderListMemoize;
}

export default memo(ItemCard);

const CardRender = memo(({
  auth = null,
  entity,
  collectionEntity,
  readOnly,
  goToAuhentication,
  collectionAddItemHandler,
  collectionDeleteItemHandler
}) => {
  const {t} = useTranslation();
  const [showOverlay, setShowOverlay] = useState(false);

  return (
    <View>
      <View
        className="card bd-0 cursor-pointer" 
        onMouseEnter={() => setShowOverlay(true)}
        onMouseLeave={() => setShowOverlay(false)}
      >
        <figure className="ht-250">
          <Media mediaUri={entity?.cover?.contentUrl} mode="cover" alt={Text.truncate((entity?.name ?? ''), 60)}/>
        </figure>
        {showOverlay && (
          <View className="pos-absolute z-index-50 l-0 r-0 b-0 t-0 bg-black-9">
            <View className="tx-9 pos-absolute t-5 r-5">
              <View className="square-10 rounded-circle" style={{backgroundColor: entity?.status?.color ?? '#ffffff'}}></View>
            </View>
            <View className="pd-10">
              <View className='ht-200 mg-t-10'>
                <View className="tx-13 tx-normal">
                  <CText className="tx-white hover-info">
                    {Text.truncate((entity?.name ?? ''), 120)}
                  </CText>
                </View>
                {entity?.numberReferenceText && (
                  <View className="tx-13 tx-warning">
                    {entity?.numberReferenceText}
                  </View>
                )}

                {entity?.subName && <CText className="tx-10">{entity.subName}</CText>}
                <CText className="d-flex tx-11 mg-t-5 tx-white">
                  {entity?.xContainer?.container?.translatedName}
                </CText>

                {entity?.fullBoxName && (
                  <CText className="d-flex tx-11" style={{color: '#347127'}}>
                    {entity?.fullBoxName}
                  </CText>
                )}

                {entity?.zones?.length > 0 && (
                  <View className="mg-t-10">
                    {entity?.zones?.map((zone, index) => {
                      return <CText key={index} className="tx-10 tx-info">{index > 0 && ", "}{zone?.name}</CText>
                    })}
                  </View>
                )}

                {collectionEntity?.note && (
                  <View className="mg-t-5 tx-11">
                    {Text.truncate(collectionEntity.note, 40)}
                  </View>
                )}

                {entity?.supportCodeText && 
                  <View className="tx-9 tx-pink mg-t-5">
                    {entity.supportCodeText}
                  </View>
                }
                
                {/* licence */}
                {entity?.itemGroup?.aliasName && (
                  <View className="tx-italic tx-secondary tx-9">
                    {t('fe.itemCard.license.label')}: 
                    <CText className="tx-danger">
                      {entity?.itemGroup?.aliasName}
                    </CText>
                  </View>
                )}
              </View>
              <View className="pos-absolute mg-b-10 mg-r-30 b-0">
                {!collectionEntity &&
                  entity?.quantityInUserCollection > 0 && (
                  <View className="tx-11 mg-b-2 tx-italic tx-warning">
                    {t('fe.itemCard.quantity.in.collection.text', {quantity: entity?.quantityInUserCollection})}
                  </View>
                )}
                {entity?.xContainer?.category &&
                  <Link
                    to={{pathname: `/browse/${entity?.xContainer?.category?.alias?.id}`}}>
                    <View className="tx-white tx-hover-info tx-9 lh-1">
                      {entity?.xContainer?.category?.alias?.parent?.translatedName}
                      {' - '}
                      {entity?.xContainer?.category?.alias?.translatedName}
                    </View>
                  </Link>
                }
              </View>
              {!readOnly &&
              <CText 
                className="pos-absolute r-0 b-0 pd-x-10 tx-white cursor-pointer"
                style={{position: 'absolute'}}
                onClick={(e) => {e.stopPropagation(); !auth.isLoggedIn
                  ? goToAuhentication()
                  : !collectionEntity ? collectionAddItemHandler(entity?.uuid) : collectionDeleteItemHandler(collectionEntity?.uuid)
                }}>
                  {!collectionEntity ?
                    <i className="fa fa-plus tx-20 tx-warning mg-b-10"></i>:
                    <i className="fa fa-trash tx-20 tx-danger mg-b-10"></i>
                  }
              </CText>}
            </View>
          </View>
        )}
      </View>
    </View>
  )
});

const ListRender = memo(({
  auth = null,
  entity,
  collectionEntity,
  readOnly,
  goToAuhentication,
  collectionAddItemHandler,
  collectionDeleteItemHandler
}) => {
  const {t} = useTranslation();

  return (
    <View className="card bd-0 shadow-base widget-16">
      <View className="tx-9 pos-absolute t-5 r-5">
        <View className="square-10 rounded-circle" style={{backgroundColor: entity?.status?.color ?? '#ffffff'}}></View>
      </View>
      <View className="row no-gutters">
        <View className="col-md-4 ht-300">
          <Media mediaUri={entity?.cover?.contentUrl} mode="cover" />
        </View>
        <View className="col-md-8 d-flex align-items-start flex-column pd-15-force">
          {/*
          <View>
            <CText className="tx-warning d-inline-block">
              <i className="icon ion-star"></i>
              <i className="icon ion-star"></i>
              <i className="icon ion-star"></i>
              <i className="icon ion-star"></i>
              <i className="icon ion-star tx-gray-500"></i>
            </CText>
            <CText className="tx-12"> &nbsp; <CText className="tx-rubik">4 </CText> Stars</CText>
          </View>
          */}
          <h5 className="mg-y-2">{entity?.name}</h5>
          {entity?.number && (
            <h5 style={{color: '#f49917', fontSize: 12}}>
              {entity.number.reference} {entity?.numberText}
            </h5>
          )}
          {entity?.subName && <CText className="tx-12">{entity.subName}</CText>}
          <CText className="d-flex tx-11 mg-t-5 tx-white">
            {entity?.xContainer?.container?.translatedName}
          </CText>

          {entity?.fullBoxName && (
            <CText className="d-flex tx-11" style={{color: '#347127'}}>
              {entity?.fullBoxName}
            </CText>
          )}

          {entity?.zones?.length > 0 && (
            <View className="mg-t-10">
              {entity?.zones?.map((zone, index) => {
                return <CText key={index} className="tx-10 tx-info">{index > 0 && ", "}{zone?.name}</CText>
              })}
            </View>
          )}

          {entity?.supportCodeText && 
            <View className="tx-9 tx-pink mg-t-5">
              {entity.supportCodeText}
            </View>
          }

          <View className="mg-y-5 tx-13 lh-4 mg-b-30 mg-md-b-auto">
            {entity?.metaDescriptions?.map((meta, index) => {
              return (
                meta?.scope === 'main' && (
                  <View key={index} className="mg-b-1">
                    <CText className="tx-normal tx-white-8">{meta?.label}:</CText>
                    <CText className="tx-normal tx-dark-8 mg-l-10">{meta?.content}</CText>
                  </View>
                )
              );
            })}
          </View>
          
          {collectionEntity?.note && (
            <View className="mg-t-5 tx-11">
              {Text.truncate(collectionEntity.note, 40)}
            </View>
          )}

          <View className="mg-t-5">
            {!collectionEntity &&
              entity?.quantityInUserCollection > 0 && (
              <View className="tx-11 mg-b-2 tx-italic tx-warning">
                {t('fe.itemCard.quantity.in.collection.text', {quantity: entity?.quantityInUserCollection})}
              </View>
            )}

            {/* licence */}
            {entity?.itemGroup && (
              <View className="tx-italic tx-secondary tx-11 mg-b-0">{t('fe.itemDetails.license.label')}: <CText className="tx-danger">{entity?.itemGroup?.name}</CText></View>
            )}
            <CText className="tx-12 tx-gray-600">
              {entity?.xContainer?.category?.alias?.parent?.translatedName}
              {' - '}
              {entity?.xContainer?.category?.alias?.translatedName}
            </CText>
          </View>
          <View className="pos-absolute r-0 b-0 pd-x-15">
            {!readOnly &&
              <CText onClick={() => !auth.isLoggedIn
                ? goToAuhentication()
                : !collectionEntity ? collectionAddItemHandler(entity?.uuid) : collectionDeleteItemHandler(collectionEntity?.uuid)
              }>
                <i className="icon ion-android-more-vertical tx-34"></i>
              </CText>
            }
          </View>
        </View>
      </View>
    </View>
  )
});