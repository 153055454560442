/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState, useRef, memo} from 'react';
import { FileAPI } from '@crosslud/apis/FileAPI';
import CText from '@crosslud/components/CText/CText';
import View from '@crosslud/components/View/View';
import Modal from '@crosslud/components/Modal/Modal';
import Viewer from './Viewer';

function useIsMountedRef() {
  const isMountedRef = useRef(null);

  useEffect(() => {
    isMountedRef.current = true;
    
    return () => (isMountedRef.current = false);
  });

  return isMountedRef;
}

const Media = ({
  mediaId,
  mediaUri = null,
  mode = 'contain',
  avatar = false,
  size = 30,
  cropper = true,
  viewer = false,
  alt = ""
}) => {
  const isMountedRef = useIsMountedRef();
  const [media, setMedia] = useState();
  const [showViewer, setShowViewer] = useState();

  const fetchMedia = async path => {
    const response = await FileAPI.get(path);

    if (
      response?.status && 
      isMountedRef.current && 
      response?.payload?.contentUrl
    ) {
      setMedia(response?.payload?.contentUrl);
    }
  };

  const viewerHandler = () => {
    viewer && setShowViewer(true);
  }

  useEffect(() => {
    if (isMountedRef.current) {
      if (mediaUri) {
        setMedia(mediaUri);
      } else {
        mediaId && fetchMedia(mediaId);
      }
    }
  }, [mediaId, mediaUri]);

  return (
    <>
      {avatar ? (
        media ?
          <View style={{width: size, height: size}} className={`bd rounded-circle d-flex align-items-center justify-content-center`}>
            <img style={{width: '100%', margin: 0}} src={media ? `${media}` : null} className={"rounded-circle img-fit-contain"} alt={alt} />
          </View>:
          <View style={{width: size, height: size}} className={`bd rounded-circle d-flex align-items-center justify-content-center`}>
            {alt ? <CText className="tx-10 tx-center">{alt}</CText> : <i className="fa fa-user"></i>}
          </View>
          ) : (
        <View 
          className={`bd ht-100p ${viewer ? 'cursor-pointer' : ''}`}
          onClick={viewerHandler}
        >
          {media ?
            <View className="ht-100p pos-relative" style={{backgroundImage: media && `url("${media}")`, backgroundSize: 'auto auto'}}>
              <View className="pos-absolute t-0 b-0 l-0 r-0" style={{backdropFilter: 'blur(90px)'}} />
              <View className="ht-100p pos-relative d-flex align-items-center justify-content-center">
                {/*<img src={media ? `${media}${cropper ? '?tr=w-356,h-558,c-at_max' : ''}` : null} className={"img-fit-contain"} alt={alt} />*/}
                <img style={{width: '100%'}} src={media ? `${media}` : null} className={"img-fit-contain"} alt={alt} />
              </View>
            </View> :
            <View className="ht-100p d-flex align-items-center justify-content-center pd-10">
              {alt ? <CText className="tx-12 tx-center">{alt}</CText> : <i className="fa fa-layer-group"></i>}
            </View>
          }
        </View>
      )}
      {/* Note modal */}
      <Modal
        show={showViewer} 
        fullscreen={false} 
        centered={true} 
        handleClose={() => setShowViewer(false)}
        contentClassName={'col'}
        className='modal-lg'
        closeButton={true}
      >
        <Viewer media={media} />
      </Modal>
    </>
  );
};



export default memo(Media);
