import { api } from "./configs/fetchConfig";
import { fetchUtils } from "./configs/fecthUtils";
import { itemLicenceSchema, itemSchema } from "./configs/schema";

export const ItemAPI = {
  /**
   * Create new item
   * @param {*} data 
   * @returns 
   */
  create: async data => {
    return await api.fetchResource('/api/items', {
      method: 'POST',
      body: JSON.stringify(fetchUtils.bodyFormat(data, itemLicenceSchema)),
    });
  },
  /**
   * Update item
   * @param {*} id 
   * @param {*} data 
   * @returns 
   */
  update: async (id, data) => {
    return await api.fetchResource('/api/items/' + id, {
      method: 'PUT',
      body: JSON.stringify(fetchUtils.bodyFormat(data, itemSchema)),
    });
  },
  /**
   * Update item header
   * @param {*} id 
   * @param {*} data 
   * @returns 
   */
  updateHeader: async (id, data) => {
    return await api.fetchResource('/api/items/' + id + '/header', {
      method: 'PUT',
      body: JSON.stringify(fetchUtils.bodyFormat(data, itemLicenceSchema)),
    });
  },
  /**
   * Search Item
   * @param {*} q
   * @param {*} page 
   * @returns 
   */
  getByCriteria: async (q = '', page = 1) => {
    var headers = new Headers();
    headers.set('Accept', 'application/ld+json');

    return await api.fetchResource('/api/search/' + q + '/items.jsonld?page=' + page, {
      method: 'GET',
      headers: headers
    });
  },
  /**
   * Get barcode info
   * @param {*} barcode 
   * @param {*} page 
   * @returns 
   */
  getBarcodeInfo: async (barcode, page = 1) => {
    var headers = new Headers();
    headers.set('Accept', 'application/json');

    return await api.fetchResource('/api/barcode/' + barcode + '/info.json', {
      method: 'GET',
      headers: headers,
    });
  },
  /**
   * Get item
   * @param {*} id 
   * @returns 
   */
  get: async id => {
    var headers = new Headers();
    headers.set('Accept', 'application/ld+json');

    return await api.fetchResource('/api/items/' + id + '.jsonld', {
      method: 'GET',
      headers: headers,
    });
  },
  /**
   * Get protected item
   * @param {*} id 
   * @returns 
   */
  getProtected: async id => {
    var headers = new Headers();
    headers.set('Accept', 'application/ld+json');

    return await api.fetchResource('/api/protected/items/' + id + '.jsonld', {
      method: 'GET',
      headers: headers,
    });
  },
  /**
   * Get linked items
   * @param {*} itemId 
   * @param {*} filters 
   * @param {*} page 
   * @returns 
   */
  getLinkedItems: async (itemId, filters = [], page = 1) => {
    const newFilters = filters.concat('page=' + page);

    var headers = new Headers();
    headers.set('Accept', 'application/ld+json');

    return await api.fetchResource('/api/items/' + itemId + '/linked-items.jsonld?' + newFilters.join('&'), {
      method: 'GET',
      headers: headers,
    });
  },
  /**
   * Get composed items
   * @param {*} itemId 
   * @param {*} filters 
   * @param {*} page 
   * @returns 
   */
  getComposedItems: async (itemId, filters = [], page = 1) => {
    const newFilters = filters.concat('page=' + page);

    var headers = new Headers();
    headers.set('Accept', 'application/ld+json');

    return await api.fetchResource('/api/items/' + itemId + '/composed-items.jsonld?' + newFilters.join('&'), {
      method: 'GET',
      headers: headers,
    });
  },
  /**
   * Get itemGroup level items
   * @param {*} uuid 
   * @param {*} level 
   * @param {*} filters 
   * @param {*} page 
   * @returns 
   */
  getGroupItems: async (uuid, level = 1, filters = [], page = 1) => {
    const newFilters = filters.concat('page=' + page);

    var headers = new Headers();
    headers.set('Accept', 'application/ld+json');
    
    return await api.fetchResource('/api/items/' + uuid + '/item-groups/' + level + '/items.jsonld?' + newFilters.join('&'), {
      method: 'GET',
      headers: headers
    })
  },
  /**
   * Get Items to verify
   * @param {*} filters 
   * @param {*} page 
   * @returns 
   */
  getVerificationItems: async (filters = [], page = 1) => {
    const newFilters = filters.concat('page=' + page);

    var headers = new Headers();
    headers.set('Accept', 'application/ld+json');

    return await api.fetchResource('/api/protected/verification/items.jsonld?' + newFilters.join('&'), {
      method: 'GET',
      headers: headers
    })
  },
  /**
   * Get Title items
   * @param {*} title 
   * @param {*} filters 
   * @param {*} page 
   * @returns 
   */
  getTitleItems: async (title, filters = {}, page = 1) => {
    let query = fetchUtils
      .customQueryFilterFormat(filters, itemSchema)
      .join('&');

    let q = '?page=' + page + (query && '&' + query);

    var headers = new Headers();
        headers.set('Accept', 'application/ld+json');

    return await api.fetchResource(
      '/api/titles/' + title + '/items.jsonld' + q,
      {
        method: 'GET',
        headers: headers
      },
    );
  },
  /**
   * Get Category item sample (retrieve 6 items)
   * @param {*} id 
   * @returns 
   */
  getCategoryItemSample: async id => {
    return await api.fetchResource('/api/categories/' + id + '/items-samples.json');
  },
  /**
   * Get Category items
   * @param {*} category 
   * @param {*} filters 
   * @param {*} page 
   * @returns 
   */
  getCategoryItems: async (category, filters = {}, page = 1) => {
    let query = fetchUtils
      .customQueryFilterFormat(filters, itemSchema)
      .join('&');

    let q = '?page=' + page + (query && '&' + query);

    var headers = new Headers();
        headers.set('Accept', 'application/ld+json');

    return await api.fetchResource(
      '/api/categories/' + category + '/items.jsonld' + q,
      {
        method: 'GET',
        headers: headers
      },
    );
  },
  /**
   * Get protected item-group items
   * @param {*} id 
   * @param {*} filters 
   * @param {*} page 
   * @returns 
   */
  getProtectedItemGroupItems: async (id, filters = [], page = 1) => {
    const newFilters = filters.concat('page=' + page);

    var headers = new Headers();
    headers.set('Accept', 'application/ld+json');

    return await api.fetchResource('/api/protected/item-groups/' + id + '/items.jsonld?' + newFilters.join('&'), {
      method: 'GET',
      headers: headers,
    });
  },
}