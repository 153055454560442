/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from 'react';
import {FlatList, Loader, CText, View} from '@crosslud/components';
import useActivity from '@crosslud/hooks/useActivity';

const ScrollList = ({
  urlCaller,
  extraData = [], // Use extraData to RESTART (init) the scrollList
  refreshListener = [], // Use extraRefreshData to REFRESH the scrollList
  refreshAction, // Use onRefreshData(datas, setDatas) callBack to extra operation
  onRefresh,
  renderItem,
  numColumns,
  inverted = false,
  showItemsNumber = false,
  ListHeaderComponent,
  ListHeaderComponentStyle,
  ListEmptyComponent,
  loaderCenterScreen = true,
  stickyHeaderIndices = 0
}) => {
  const [scrollTop, setScrollTop] = useState(0);
  const [scrollDirection, setScrollDirection] = useState({
    drt: 'top',
    scrollTop: 0,
  });

  const [responseData, setResponseData] = useState();
  const activityHook = useActivity();
  const [isFetching, setIsFetching] = useState(false);
  const [forceLoading, setForceLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [pages, setPages] = useState([]);
  const [lastPage, setLastPage] = useState(false);

  const [totalItems, setTotalItems] = useState();
  const [paginator, setPaginator] = useState();

  // ACTION & API
  // Call URL
  const callDatasUrl = async () => {
    var activityRef = activityHook.start();
    
    if (urlCaller?.url && urlCaller?.params) {
      var noPagination = false;

      if (urlCaller?.paginationIndex) {
        urlCaller.params[urlCaller.paginationIndex] = page;
      } else {
        noPagination = true;
        setLastPage(true);
      }

      const response = await urlCaller.url.apply(this, urlCaller.params);

      if (response.status) {
        setTotalItems(response?.totalItems);
        setPaginator(response?.paginator);

        var newResponseData = urlCaller?.transformData
          ? urlCaller?.transformData(response?.payload)
          : response?.payload;

        if (noPagination || page === 1) {
          setResponseData(newResponseData);
        } else {
          setResponseData([...responseData].concat(newResponseData));
        }

        // Last page reached
        if (response?.paginator?.next) {
          setPage(page + 1);
        } else {
          setLastPage(true);
        }
      }
    }

    setIsFetching(false);
    setForceLoading(false);

    activityHook.stop(activityRef);
  };

  // Reinitialize
  const fetchInitItems = () => {
    setScrollTop(0);
    setPage(1);
    setPages([]);
    setResponseData();
    setLastPage(false);
    setForceLoading(Math.random());
  };

  // Refresh handler
  const refreshHandler = () => {
    setIsFetching(true);
    fetchInitItems();
    onRefresh && onRefresh();
  };

  // Fetch more
  const fetchMoreItems = () => {
    if (!pages.includes(page) && !lastPage) {
      setPages([...pages].concat(page));
      setForceLoading(Math.random());
    }
  };

  // EFFECT

  // EndPoint
  useEffect(() => {
    if (forceLoading === false) {
      fetchInitItems();
    }
  }, extraData);

  // Refresh data from outside
  useEffect(() => {
    responseData && 
      refreshAction && 
      refreshAction(responseData, setResponseData);
  }, refreshListener);

  // End Point
  useEffect(() => {
    if (lastPage === false && forceLoading !== false) {
      callDatasUrl();
    }
  }, [forceLoading]);

  // Item render
  const renderItemFormat = ({item, index}) => {
    return (
      <div key={index} className={numColumns}>
        {renderItem({item, index})}
      </div>
    );
  };
  return (
    activityHook.isLoading && page === 1 ? 
      <Loader centerScreen={loaderCenterScreen} /> : (
      <View>
        {ListHeaderComponent &&
          <View style={ListHeaderComponentStyle}>
            {ListHeaderComponent}
          </View>
        }
        <FlatList
          data={responseData}
          renderItem={renderItemFormat}
          className={'row'}
          ListHeaderComponent={
            showItemsNumber &&
            <View style={{alignItems: 'center', paddingVertical: 15}}>
              <CText>{totalItems} élement(s) trouvé(s)</CText>
            </View>
          }
          //ListHeaderComponentStyle={ListHeaderComponentStyle}
          ListEmptyComponent={responseData && ListEmptyComponent}
          onComponentScroll={(e) => setScrollTop(e)}
          ListFooterComponent={() => (
            activityHook.isLoading && (
              <div className="col-12 tx-center">
                <Loader centerScreen={false} />
              </div>
            )
          )}
          onEndReached={() => fetchMoreItems()}
          showsVerticalScrollIndicator={false}
          stickyHeaderIndices={stickyHeaderIndices}
          legacyImplementation={true}
          //disableVirtualization={true}
          removeClippedSubviews={true}
          windowSize={30}
        />
      </View>
    )
  )
}

export default ScrollList;