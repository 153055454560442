/* eslint-disable array-callback-return */
import React, {useState} from 'react';
import {useSelector} from 'react-redux';
import {useDispatch} from 'react-redux';
import {userLogin} from '@crosslud/redux/Auth/auth-actions';
import {loaderStart, loaderEnd} from '@crosslud/redux/Loader/loader-actions';
import { Controller, useForm } from 'react-hook-form';
import { UserAPI } from '@crosslud/apis/UserAPI';
import Alert from '@crosslud/helpers/Alert';
import useActiveLink from '@crosslud/hooks/useActiveLink';
import { useTranslation } from 'react-i18next';
import useSecurityCheck from '@crosslud/hooks/useSecurityCheck';

const AccountEmail = () => {
  // SET ACTIVELINK
  useActiveLink('accountLayout', 'email');

  const securityCheckHook = useSecurityCheck();

  const {t} = useTranslation();

  const [violations, setViolations] = useState([]);

  const dispatch = useDispatch();

  const auth = useSelector(state => {
    return state.auth;
  });

  // FORM
  const {
    control, 
    resetField,
    handleSubmit,
  } = useForm({
    defaultValues: {
      email: auth?.user?.email
    }
  });

  const onSubmit = async formData => {
    dispatch(loaderStart());

    var response = await UserAPI.updateEmail(auth?.user?.uuid, formData);

    if (response.status) {
      dispatch(userLogin({user: response?.payload}));

      Alert.notify(
        t('fe.account.email.form.onSubmit.alert.success.title'),
        t('fe.account.email.form.onSubmit.alert.success.description'),
        'success',
      );
    }

    // Violations
    setViolations(response?.violations);

    dispatch(loaderEnd());
  }

  return (
    <section className="bg-black-1 pd-30 ht-100p">
      <h3 className="tx-center pd-b-10">{t('fe.account.email.form.title')}</h3>
      <p className="tx-center mg-b-60">{t('fe.account.email.form.sub.title')}</p>
      {violations?.error?.message && <p className='tx-center text-danger'>{violations?.error?.message}</p>}
      {violations?.currentPassword?.message && <p className='tx-center text-danger'>{violations?.currentPassword?.message}</p>}
      <form className="form-layout form-layout-6">
        <div className="row no-gutters">
          <div className="col-5">
            <label className="form-control-label">{t('fe.account.email.form.email.label')}: <span className="tx-danger">*</span></label>
          </div>
          <div className="col-7">
            <Controller
              name="email"
              control={control}
              render={({ field }) => <input className="form-control" {...field} />}
            />
            {violations?.email?.message && <span className='text-danger'>{violations?.email?.message}</span>}
          </div>
        </div>
      </form>
      <div className="form-layout-footer bd pd-20 bd-t-0">
        <button className="btn btn-info" onClick={() => 
          securityCheckHook.formAutorize(
            'currentPassword', 
            control, 
            handleSubmit(onSubmit), 
            resetField
          )
        }>
          {t('fe.account.email.form.submit.button')}
        </button>
      </div>
    </section>
  )
}

export default AccountEmail;