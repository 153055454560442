import {Navigate} from "react-router-dom";
import { NotFound } from "@crosslud/screens";

const ProtectedScreen = ({
  children, 
  deniedWhen = false, 
  deniedComponent = <NotFound />, 
  redirect = false
}) => {
  return (
    !deniedWhen ? 
      children : 
      (!redirect ? deniedComponent : <Navigate to={'/'} replace />)
  );
};

export default ProtectedScreen;
