/* eslint-disable no-loop-func */
/* eslint-disable array-callback-return */
import Utils from '../../helpers/Utils';

export const fetchUtils = {
    // IRI Normalizer
    // {
    //   "files": [
    //     {"@id": "/api/files/3ba1a9c83d0d6693daa7d2f0600f933e.jpg", "main": true},
    //     "/api/files/08f3783fce9e5d4a92f5a092242ceaf7-63e1d67a75489616839987.jpg",
    //     "/api/files/3ba1a9c83d0d6693daa7d2f0600f933e-63e1d6893a0dd719084989.jpg",
    //     "/api/files/crosslud-logo-63efe98dc4c46179347809.png",
    //     "/api/files/screenshot-from-2021-11-17-05-28-11-63efed52e9d8c110684681.png"
    //   ],
    //   "linkedItems": [
    //     "/api/items/77fd6a75-2454-11ed-b6d2-fa1ed719464b"
    //   ],
    //   "cover": "/api/files/crosslud-logo-63efe98dc4c46179347809.png"
    // }
    dataToIriFormat: (format, data, hasBodyContent) => {
        const types = ['string', 'float', 'int'];
    
        var iri = !types.includes(format) ? format + '/' : '';
    
        var requestData = null;
    
        if (Utils.isObject(data) && !Utils.isDate(data)) {
            // Object and not Date
            // If iri allows body content, then use JSON LD syntax
            // and send others properties
            if (hasBodyContent) {
                var jsonLDObject = {};
        
                for (var property in data) {
                    if (property === 'id') {
                        jsonLDObject['@id'] = iri + data[property];
                    } else {
                        jsonLDObject[property] = data[property];
                    }
                }
        
                requestData = jsonLDObject;
            } else {
                // Otherwise, just send iri without body content.
                // Others properties are ignored
                requestData = iri + data.id;
            }
        } else if (Utils.isDate(data)) {
            // Date
            requestData = data;
        } else {
            // Others
            if (format === 'int') {
                data = parseInt(data, 2);
            }
        
            if (format === 'float') {
                data = parseFloat(data);
            }
        
            requestData = iri && data ? iri + data : (data ? data : null);
        }

        return requestData;
    },
    bodyFormat: (datas, schema) => {
        var body = {};
    
        for (const key in datas) {
            var data = datas[key];
        
            // Set format default value
            var format = schema[key] ? schema[key].iri : null;
        
            if (!format) {
                continue;
            }
        
            let hasBodyContent = schema[key] ? schema[key].bodyContent : false;
        
            // If format is an array and data not array, cast data to array
            if (Utils.isArray(format) && !Utils.isArray(data)) {
                data = [data];
            }
        
            // If format is not an array and data is array, cast data to object
            if (!Utils.isArray(format) && Utils.isArray(data)) {
                data = data[0];
            }
        
            if (Utils.isArray(data)) {
                format = format[0];
                body[key] = [];
        
                data.map(dataContent => {
                    body[key].push(fetchUtils.dataToIriFormat(format, dataContent, hasBodyContent));
                });
            } else {
                body[key] = fetchUtils.dataToIriFormat(format, data, hasBodyContent);
            }
        }
    
        return body;
    },
  
    // Query Filter Normalizer
    queryFilterFormat: (datas, schema) => {
        var query = [];
    
        for (var k in datas) {
            var value = datas[k];
            const format = schema[k] ? schema[k].query : null;
        
            if (format && value) {
                if (!Utils.isArray(value)) {
                    value = [value];
                }
        
                value.map(v => {
                    query.push(format + '[]=' + v);
                });
            }
        }
  
        return query;
    },
  
    // Query Filter Normalizer
    customQueryFilterFormat: (datas, schema) => {
        var query = [];
    
        for (var k in datas) {
            var value = datas[k];
            const format = schema[k] ? schema[k].customQuery : null;
        
            if (format && value) {
                if (!Utils.isArray(value)) {
                    value = [value];
                }
        
                value.map(v => {
                    query.push(format + '[]=' + v);
                });
            }
        }
    
        return query;
    },

    // Query Filter Normalizer
    getParameterByName: (name, url = window.location.href) => {
        name = name.replace(/[[\]]/g, '\\$&');
        var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
            results = regex.exec(url);
        if (!results) return null;
        if (!results[2]) return '';
    
        return decodeURIComponent(results[2].replace(/\+/g, ' '));
    },
}