/* eslint-disable react-hooks/exhaustive-deps */
import {memo, useEffect, useState} from 'react';
import {Link, useParams} from 'react-router-dom';
import {Loader, ItemCard, ScrollList, ScrollListHeader, ModalLink, GridList, ProtectedScreen, View, EmptyContent, CText, TitleCard} from '@crosslud/components';
import FormData from '@crosslud/helpers/FormData';
import useSearchFilter from '@crosslud/hooks/useSearchFilter';
import { CategoryAPI } from '@crosslud/apis/CategoryAPI';
import useActivity from '@crosslud/hooks/useActivity';
import { useTranslation } from 'react-i18next';
import { ItemAPI } from '@crosslud/apis/ItemAPI';
import { LicenceAPI } from '@crosslud/apis/LicenceAPI';

const Items = ({titles}) => {
  const {t} = useTranslation();

  // PARAMS
  const {id} = useParams();

  // HOOK
  const searchFilterHook = useSearchFilter(id, titles ? 'category-titles' : 'category-items');
  const activityHook = useActivity();

  // STATE
  const [category, setCategory] = useState();

  // ACTION & API
  const fetchCategory = async (id) => {
    var activityRef = activityHook.start();

    const response = await CategoryAPI.get(id);

    if (response?.status) {
      setCategory(response?.payload);
    }

    activityHook.stop(activityRef);
  };

  const PageTitle = () => {
    return (
      <>
        <i className={`icon ${category?.icon ? category.icon : 'ion-earth'}`}></i>
        <div>
          <h4>{category?.alias?.translatedName}</h4>
          <p className="mg-b-0">
            <Link to={{pathname: `/browse/${category?.alias?.parent?.id}`}}><span>{category?.alias?.parent?.translatedName}</span></Link>
          </p>
        </div>
      </>
    );
  }

  const renderItem = ({item}) => {
    return (
      <ModalLink key={item?.uuid} to={{pathname: `/item-modal/item/details/${item?.uuid}`}}>
        <ItemCard data={item} overlay={true}/>
      </ModalLink>
    )
  }

  const renderTitle = ({item}) => {
    return (
      <ModalLink key={item?.uuid} to={{pathname: `/modal/titles/${item?.uuid}/items`}}>
        <TitleCard data={item}/>
      </ModalLink>
    )
  }

  const SwitchView = () => {
    return (
      <>
        <Link to={{pathname: `/browse/${category?.alias?.id}`}}>
          <button type="button" className={`btn ${titles === false ? 'btn-info': 'btn-outline-light'} rounded-0`}>
            <CText>Par objet</CText>
          </button>
        </Link>
        <Link to={{pathname: `/browse/${category?.alias?.id}/titles`}}>
          <button type="button" className={`btn ${titles === true ? 'btn-info': 'btn-outline-light'} rounded-0`}>
            <CText>Par titre</CText>
          </button>
        </Link>
        <span className='mg-r-10'></span>
      </>
    )
  }

  // EFFECT

  // Endpoint : Id
  useEffect(() => {
    if (id) {
      fetchCategory(id);
    }
  }, [id, titles]);

  return (
    activityHook.isLoading ? <Loader /> :
    <ProtectedScreen deniedWhen={!category || category?.level > 2 || (titles && category.level === 1)}>
      <section>
        {category?.level === 1 && (
          <>
            <ScrollListHeader 
              titleComponent={<PageTitle />}
              extraComponent={category?.level === 2 && <SwitchView />}
              hook={searchFilterHook} 
              category={id}
              fieldType={titles ? 2 : 1}
            />
            {category?.children?.map((subCategory) => {
              return (
                <section key={subCategory?.id} className='mg-t-20'>
                  <div className='row'>
                    <div className='col-8'>
                      <h4 className="tx-info tx-light">{subCategory?.translatedName}</h4>
                    </div>
                    <div className='col-4 tx-right'>
                      <Link className='tx-white' to={{pathname: `/browse/${subCategory?.alias?.id}`}}>
                        {t('fe.items.show.more')}
                      </Link>
                    </div>
                  </div>
                  <ItemsSample category={subCategory} />
                </section>
              )
            })}
          </>
        )}

        {category?.level === 2 && (
          <div className="row">
            <div className="col-lg-12">
              {titles === true ? (
                <ScrollList
                  urlCaller={{
                    url: LicenceAPI.getTitles,
                    params: [id, FormData.format(searchFilterHook.getFilters(), true), 1],
                    paginationIndex: 2,
                  }}
                  renderItem={renderTitle}
                  extraData={[titles, searchFilterHook.getFilters(), id]}
                  ListHeaderComponent={
                    <ScrollListHeader 
                      titleComponent={<PageTitle />}
                      extraComponent={category?.level === 2 && <SwitchView />}
                      hook={searchFilterHook} 
                      category={id}
                      fieldType={2}
                    />
                  }
                  numColumns={"col-lg-4 col-md-4 col-sm-6 col-6 mb-3"}
                  ListEmptyComponent={
                    <View className='col-12'>
                      <EmptyContent text={t('fe.no.result.description')} />
                    </View>
                  }
                />
              ) : (
                <ScrollList
                  urlCaller={{
                    url: ItemAPI.getCategoryItems,
                    params: [id, FormData.format(searchFilterHook.getFilters(), true), 1],
                    paginationIndex: 2,
                  }}
                  renderItem={renderItem}
                  extraData={[titles, searchFilterHook.getFilters(), id]}
                  ListHeaderComponent={
                    <>
                      <ScrollListHeader 
                        titleComponent={<PageTitle />}
                        extraComponent={category?.level === 2 && <SwitchView />}
                        hook={searchFilterHook} 
                        category={id}
                        fieldType={1}
                      />
                    </>
                  }
                  numColumns={"col-lg-2 col-md-4 col-sm-6 col-6 mb-3"}
                  ListEmptyComponent={
                    <View className='col-12'>
                      <EmptyContent text={t('fe.no.result.description')} />
                    </View>
                  }
                />
              )}
            </div>
          </div>
        )}
      </section>
    </ProtectedScreen>
  )
}

// Items samples
const ItemsSample = ({category}) => {
  const [items, setItems] = useState();

  const activityHook = useActivity();

  const fetchItemsSample = async categoryId => {
    var activityRef = activityHook.start();

    const response = await ItemAPI.getCategoryItemSample(categoryId);

    if (response?.status) {
      setItems(response?.payload);
    }

    activityHook.stop(activityRef);
  };

  useEffect(() => {
    category?.id && fetchItemsSample(category?.id);
  }, [category]);

  return (
    activityHook.isLoading ? <Loader centerScreen={false} /> :
    items?.length > 0 && 
    <GridList
      data={items}
      numColumns={'col-lg-2 col-md-4 col-6 mb-3'}
      renderItem={({item, index}) =>
        <ModalLink
          key={index}
          to={{
            pathname: `/item-modal/item/details/${(item.uuid)}`,
          }}>
          <ItemCard data={item} overlay={true} />
        </ModalLink>
      }
    />
  )
}

export default memo(Items);