/* eslint-disable radix */
/* eslint-disable react-hooks/exhaustive-deps */
/**
 * @see https://stackoverflow.com/questions/48048957/add-a-long-press-event-in-react
 */
import React, { memo, useEffect } from 'react';
import useItemChecker from '@crosslud/hooks/useItemChecker';
import useLongPress from '@crosslud/hooks/useLongPress';

const CheckWrapper = memo(({
  children,
  identifier,
  checkerControl,
  checked = false,
  multiple = false
}) => {
  // ITEM CHECKER
  const itemChecker = useItemChecker(checkerControl, multiple);

  // Checker Handler
  const checkerHandler = () => {
    itemChecker.check(identifier);
  };

  useEffect(() => {
    checked && !itemChecker.hasItem(identifier) && checkerHandler();
  }, []);

  /**
   * LongPress for web
   */
  const onLongPress = () => {
    checkerHandler()
  };

  const onClick = () => {
    itemChecker.count() > 0 && checkerHandler();
  }

  const defaultOptions = {
    shouldPreventDefault: true,
    delay: 500,
  };

  const longPress = useLongPress(onLongPress, onClick, defaultOptions);

  return (
    <div style={{position: 'relative'}} {...longPress}>
      {children}
      {itemChecker.count() > 0 &&
        <div className="pos-absolute z-index-50 t-0 r-0 b-0 l-0 cursor-pointer">
          <div className="pos-absolute r-5 b-5 pd-x-10 bg-dark">
            <span>
              <i className={`icon ${itemChecker.hasItem(identifier) ? 'ion-android-checkmark-circle' : 'ion-android-radio-button-off'} tx-34`}></i>
            </span>
          </div>
        </div>
      }
    </div>
  );
});

export default CheckWrapper;