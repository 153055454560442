import {fetchUtils} from './configs/fecthUtils';
import {api} from './configs/fetchConfig';
import {scanSchema} from './configs/schema';

export const ScanAPI = {
  /**
   * Get current-user scans
   * @param {*} filters 
   * @param {*} page 
   * @returns 
   */
  getAll: async (filters = [], page = 1) => {
    const newFilters = filters.concat('page=' + page);

    var headers = new Headers();
    headers.set('Accept', 'application/ld+json');

    return await api.fetchResource('/api/scans.jsonld?' + newFilters.join('&'), {
      method: 'GET',
      headers: headers,
    });
  },
  /**
   * Get all protected missing
   * @param {*} filters 
   * @param {*} page 
   * @returns 
   */
  getAllProtectedMissing: async (filters = [], page = 1) => {
    const newFilters = filters.concat('page=' + page);

    var headers = new Headers();
    headers.set('Accept', 'application/ld+json');

    return await api.fetchResource(
      '/api/protected/missing.jsonld?' + newFilters.join('&'),
      {
        method: 'GET',
        headers: headers,
      },
    );
  },
  /**
   * Get protected missing
   * @param {*} id 
   * @returns 
   */
  getProtectedMissing: async id => {
    var headers = new Headers();
    headers.set('Accept', 'application/ld+json');

    return await api.fetchResource('/api/protected/missing/' + id + '.jsonld', {
      method: 'GET',

      headers: headers,
    });
  },
  /**
   * Create scan
   * @param {*} barcode 
   * @returns 
   */
  post: async (barcode) => {
    return await api.fetchResource('/api/scans', {
      method: 'POST',
      body: JSON.stringify(fetchUtils.bodyFormat([{'barcode': barcode}], scanSchema)),
    });
  },
  /**
   * Choose scan item
   * @param {*} id 
   * @param {*} data 
   * @returns 
   */
  chooseItem: async (id, data) => {
    return await api.fetchResource('/api/scan/' + id + '/choose', {
      method: 'PUT',
      body: JSON.stringify(fetchUtils.bodyFormat(data, scanSchema)),
    });
  },
  /**
   * Update protected missing
   * @param {*} id 
   * @param {*} data 
   * @returns 
   */
  updateProtectedMissing: async (id, data) => {
    return await api.fetchResource('/api/protected/missing/' + id + '/update', {
      method: 'PUT',
      body: JSON.stringify(fetchUtils.bodyFormat(data, scanSchema)),
    });
  },
  /**
   * Get barcode missings
   * @param {*} barcode 
   * @param {*} page 
   * @returns 
   */
  getProtectedBarcodeMissings: async (barcode, page = 1) => {
    var headers = new Headers();
    headers.set('Accept', 'application/ld+json');

    return await api.fetchResource(
      '/api/protected/barcode/' + barcode + '/missings.jsonld?page=' + page,
      {
        method: 'GET',
        headers: headers,
      },
    );
  }
};
