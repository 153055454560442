/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import Utils from '@crosslud/helpers/Utils';

const Tabs = ({children}) => {
  const [activeTab, setActiveTab] = useState(0);
  const [tabs, setTabs] = useState();

  const init = () => {
    var newChildren = !Utils.isArray(children) ? [children] : children;
    var tabList = newChildren.map((child, key) => {
      if (key === 0) {
        setActiveTab(key)
      }

      return {
        key: key, 
        label: child?.props?.tabLabel, 
        child: child?.props?.children, 
        enable: child?.props?.tabDisplay ?? false
      };
    });

    setTabs(tabList);
  }

  const displayHandler = (key, display) => {
    setTabs((oldStates) => {
      var index = oldStates.findIndex((oldState) => {
        return oldState.key === key
      });

      if (index !== -1) {
        oldStates[index].enable = display;
      }

      return [...oldStates];
    });
  }

  useEffect(() => {
    init();
  }, [])
  
  return (
    <div className="pd-0 mg-t-20">
      <ul className={`nav nav-tabs nav-tabs-style-1 bg-gray-900 tx-13`} role="tablist">
        {tabs?.map((tab) => {
          return (
            <TabHeader key={tab.key} tab={tab} active={activeTab === tab.key ? true : false} setActiveTab={setActiveTab}/>
          )
        })}
      </ul>
      <div className="tab-content" style={{minHeight: '150px'}}>
        {tabs?.map((tab) => {
          return (
            <TabContent key={tab.key} tab={tab} displayTab={displayHandler} active={activeTab === tab.key ? true : false} />
          )
        })}
      </div>
    </div>
  )
}

const TabHeader = ({tab, active = false, setActiveTab}) => {
  return (
    tab.enable && tab.label &&
    <li key={tab.key} className="nav-item bd-r">
      <span className={`nav-link btn pd-x-30 pd-y-10${active ? ' active' : ''}`} onClick={() => setActiveTab(tab.key)}>
        {tab?.label}
      </span>
    </li>
  )
}

const TabContent = ({tab, active = false, displayTab}) => {
  const display = (isDisplayed) => {
    displayTab(tab.key, isDisplayed);
  }

  return (
    <div className={`tab-pane${active ? ' active' : ''}`} role="tabpanel">
      {typeof tab.child === 'function' ? tab.child({display}) : tab.child}
    </div>
  )
}

export default Tabs;