import React from 'react';
import {NiceDropdown} from '@crosslud/components';

const AdminMenu = () => {
  const menus = [
    {identifier: 'dashboard', pathname: '/administration/dashboard', label: 'Dashboard', enable: true},
    {identifier: 'forms', pathname: '/administration/forms', label: 'Forms', enable: true},
    {identifier: 'filters', pathname: '/administration/filters', label: 'Filters', enable: true},
    {identifier: 'sorters', pathname: '/administration/sorters', label: 'Sorters', enable: true},
    {identifier: 'requests', pathname: '/administration/assignation/requests', label: 'Requests', enable: true},
    {identifier: 'missing', pathname: '/administration/assignation/missing', label: 'Missing', enable: true},
    {identifier: 'verification', pathname: '/administration/verification/items', label: 'Verification', enable: true},
    {identifier: 'licences', pathname: '/administration/licences', label: 'licences', enable: true},
    {identifier: 'commands', pathname: '/administration/commands', label: 'commands', enable: true},
  ];


  return <NiceDropdown data={menus} scope='adminLayout'/>;
}

export default AdminMenu;