/* eslint-disable react-hooks/exhaustive-deps */
import {useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import useActivity from '@crosslud/hooks/useActivity';
import Loader from '@crosslud/components/Loader/Loader';
import Media from '@crosslud/components/Media/Media';
import { useTranslation } from 'react-i18next';
import View from '@crosslud/components/View/View';
import { UserAPI } from '@crosslud/apis/UserAPI';

const TabCollectors = ({entity, onDataFetch}) => {
  const {t} = useTranslation();
  const activityHook = useActivity();

  // Collectors
  const [collectors, setCollectors] = useState();

  // Api
  const fetchItemCollectors = async id => {
    var activityRef = activityHook.start();

    const response = await UserAPI.getItemCollectors(id);

    if (response.status) {
      setCollectors(response.payload);
    }

    activityHook.stop(activityRef);
  };

  // Effect
  // Anyway retrieve LinkedItems and Reviews and others
  useEffect(() => {
    fetchItemCollectors(entity.uuid);
  }, []);

  useEffect(() => {
    collectors?.length > 0 && onDataFetch();
  }, [collectors])

  return (
    activityHook.isLoading ? <Loader centerScreen={false} /> :
    <div className='container pd-0'>
      <div className='row'>
        <div className="col mg-t-40 tx-center">
          <h6>
            {t("fe.itemSheet.tabCollectors.title").toUpperCase()}
          </h6>
        </div>
      </div>
      <div className='row'>
        <div className="col mg-t-20">
          <div className="card shadow-base widget-5">
            <div className='row no-gutters'>
              {collectors?.map(collector => {
                return (
                  <View key={collector?.uuid} className='col-auto'>
                    <Link
                      key={collector?.uuid}
                      className="col-md-4 col-lg-4 mg-b-20" 
                      to={`/collectors/${collector?.uuid}/collections`}>
                      <View className="mg-10">
                        <Media mediaUri={collector?.avatar?.contentUrl} avatar={true} size={80} alt={collector?.nickname} />
                      </View>
                    </Link>
                  </View>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default TabCollectors;